import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_dock_appointment_activity_listComponent } from './FootPrintManager.dock_appointment_activity_list.component';
import { DockAppointments_dock_appointment_types_dd_singleComponent } from './DockAppointments.dock_appointment_types_dd_single.component'
import { DockAppointments_dock_appointment_statuses_singleComponent } from './DockAppointments.dock_appointment_statuses_single.component'
import { DockAppointments_dock_doors_dd_singleComponent } from './DockAppointments.dock_doors_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { DockAppointments_orders_dd_multiComponent } from './DockAppointments.orders_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'

type EntityType = { 
    Id?: number, AssignedOn?: string, CancelledOn?: string, CheckedInOn?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, EmptyTrailerTemp?: number, EstimatedPallets?: number, EstimatedQuantity?: number, FullTrailerTemp?: number, InProcessOn?: string, linkedDockAppointmentId?: number, LoadConfiguration?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledDeparture?: string, SealNumber?: string, TMS_BOLCalled?: boolean, TrailerNumber?: string, TypeId?: number, VehicleLicense?: string, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string, WarehouseId?: number }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, Name?: string }, ScheduledProject?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Items?: { Id?: number, ItemEntityId?: number, ItemEntityType?: string }[], AssignedLocation?: { Id?: number, Name?: string, WarehouseId?: number }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, convertedCancelledOn?: { id?: number, convertedDate?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_dock_appointment_activity_listComponent),
    forwardRef(() => DockAppointments_dock_appointment_types_dd_singleComponent),
    forwardRef(() => DockAppointments_dock_appointment_statuses_singleComponent),
    forwardRef(() => DockAppointments_dock_doors_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => DockAppointments_orders_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
  ],
  selector: 'app-custom_dock_appointment_editor',
  templateUrl: './app.custom_dock_appointment_editor.component.html'
})
export class app_custom_dock_appointment_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { dockAppointmentId: number, orderId?: number, loadContainerId?: number, shipmentId?: number } = { dockAppointmentId: null, orderId: null, loadContainerId: null, shipmentId: null };
  //#region Inputs
  @Input('dockAppointmentId') set $inParams_dockAppointmentId(v: number) {
    this.inParams.dockAppointmentId = v;
  }
  get $inParams_dockAppointmentId(): number {
    return this.inParams.dockAppointmentId;
  }
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { orders?: number[], appointmentType?: string, doorType?: string } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dockDoor: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scheduled_arrival: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    scheduled_departure: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    estimated_pallets: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    estimated_quantity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    load_configuration: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    full_trailer_temp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    empty_trailer_temp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    transfer_appointment: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    unlink_appointment: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, false, 'Update', 'ms-Icon ms-Icon--AcceptMedium', null)
    , false),
      check_in: new ToolModel(new ButtonModel('check_in', new ButtonStyles(null, null), false, false, false, 'Check In', 'ms-Icon ms-Icon--BufferTimeBoth', null)
    , false),
      on_assign_door: new ToolModel(new ButtonModel('on_assign_door', new ButtonStyles(null, null), false, false, false, 'Assign Door', 'ms-Icon ms-Icon--FullWidthEdit', null)
    , false),
      on_mark_in_process: new ToolModel(new ButtonModel('on_mark_in_process', new ButtonStyles(null, null), false, false, false, 'Mark In Process', 'ms-Icon ms-Icon--FullHistory', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'ms-Icon ms-Icon--WorkFlow', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel Appt', 'ms-Icon ms-Icon--Blocked', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    , false),
      link_appointment: new ToolModel(new ButtonModel('link_appointment', new ButtonStyles(null, null), false, false, false, 'Link', 'ms-Icon ms-Icon--CloudLink', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      submit_to_tms: new ToolModel(new ButtonModel('submit_to_tms', new ButtonStyles(null, null), false, false, false, 'Submit to TMS', 'icon-ic_fluent_document_table_truck_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'ms-Icon ms-Icon--Attach', null)
    , false),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, false, false, ' ', 'ms-Icon ms-Icon--Questionnaire', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Lookupcode', true, false),
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', true, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    dockDoor: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dockDoor'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Dock Door', false, false),
    scheduled_arrival: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_arrival'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Scheduled Arrival', true, false),
    scheduled_departure: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_departure'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Scheduled Departure', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    reference_number: new FieldModel(new TextBoxModel(this.formGroup.controls['reference_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Reference Number', false, false),
    order: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    estimated_pallets: new FieldModel(new NumberBoxModel(this.formGroup.controls['estimated_pallets'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Estimated Pallets', false, false),
    estimated_quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['estimated_quantity'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Estimated Quantity', false, false),
    load_configuration: new FieldModel(new TextBoxModel(this.formGroup.controls['load_configuration'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Load Configuration', false, false),
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer Number', false, false),
    full_trailer_temp: new FieldModel(new NumberBoxModel(this.formGroup.controls['full_trailer_temp'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Full Trailer Temp', false, false),
    empty_trailer_temp: new FieldModel(new NumberBoxModel(this.formGroup.controls['empty_trailer_temp'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Empty Trailer Temp', false, false),
    seal_number: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Seal Number', false, false),
    linked_appointment: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    transfer_appointment: new FieldModel(new CheckBoxModel(this.formGroup.controls['transfer_appointment'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Transfer', false, false),
    unlink_appointment: new FieldModel(new CheckBoxModel(this.formGroup.controls['unlink_appointment'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Unlink', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
  newGroup2: new FieldsetModel('Linked Appointment', false, true, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      activity: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_activity_dock_appointment_activity_list_inParams_dockAppointmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.dockAppointmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_activity', { read: FootPrintManager_dock_appointment_activity_listComponent }) $tabs_activity: FootPrintManager_dock_appointment_activity_listComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_type_selector_inParams_option(): string {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.doorType;
    
    return expr;
  }

  cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds: number[];
  get $fields_dockDoor_selector_inParams_warehouseIds(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = [$editor.entity.WarehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds;
  }

  get $fields_dockDoor_selector_inParams_appointmentType(): string {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.appointmentType;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_order_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.WarehouseId;
    
    return expr;
  }

  get $fields_order_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.type.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    type: this.fields.type.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    dockDoor: this.fields.dockDoor.control.valueChanges
    ,
    scheduled_arrival: this.fields.scheduled_arrival.control.valueChanges
    ,
    scheduled_departure: this.fields.scheduled_departure.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    reference_number: this.fields.reference_number.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    estimated_pallets: this.fields.estimated_pallets.control.valueChanges
    ,
    estimated_quantity: this.fields.estimated_quantity.control.valueChanges
    ,
    load_configuration: this.fields.load_configuration.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    full_trailer_temp: this.fields.full_trailer_temp.control.valueChanges
    ,
    empty_trailer_temp: this.fields.empty_trailer_temp.control.valueChanges
    ,
    seal_number: this.fields.seal_number.control.valueChanges
    ,
    transfer_appointment: this.fields.transfer_appointment.control.valueChanges
    ,
    unlink_appointment: this.fields.unlink_appointment.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.activity,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointmentId)) {
        this.$missingRequiredInParams.push('dockAppointmentId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Dock Appointment Editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      dockAppointmentId:  $editor.inParams.dockAppointmentId 
    };

    const data = await this.datasources.app.custom_ds_dock_appointment_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.type.control as SelectBoxModel).reset($editor.entity.TypeId);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.Status?.Id);
    (this.fields.dockDoor.control as SelectBoxModel).reset($utils.isDefined($editor.entity.AssignedLocation?.Id) ? $editor.entity.AssignedLocation?.Id : $editor.entity.ScheduledLocation?.Id);
    (this.fields.scheduled_arrival.control as DateBoxModel).reset($editor.entity.convertedScheduledArrival?.convertedDate);
    (this.fields.scheduled_departure.control as DateBoxModel).reset($editor.entity.convertedScheduledDeparture?.convertedDate);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ScheduledProject?.Id);
    (this.fields.reference_number.control as TextBoxModel).reset($editor.entity.ReferenceNumber);
    (this.fields.order.control as SelectBoxModel).reset($utils.isDefined($editor.vars.orders) ? $editor.vars.orders :  null );
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.ScheduledCarrier?.Id);
    (this.fields.estimated_pallets.control as NumberBoxModel).reset($editor.entity.EstimatedPallets);
    (this.fields.estimated_quantity.control as NumberBoxModel).reset($editor.entity.EstimatedQuantity);
    (this.fields.load_configuration.control as TextBoxModel).reset($editor.entity.LoadConfiguration);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.TrailerNumber);
    (this.fields.full_trailer_temp.control as NumberBoxModel).reset($editor.entity.FullTrailerTemp);
    (this.fields.empty_trailer_temp.control as NumberBoxModel).reset($editor.entity.EmptyTrailerTemp);
    (this.fields.seal_number.control as TextBoxModel).reset($editor.entity.SealNumber);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_activity') {
        if (!isNil(this.$tabs_activity) && !this.tabs.activity.hidden && !this.tabs.activity.removed) {
          this.$tabs_activity.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_type_change();
      });
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dockDoor
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_door_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_arrival
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scheduled_arrival_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_departure
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .reference_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.check_can_submit_to_tms();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .estimated_pallets
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .estimated_quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .load_configuration
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .full_trailer_temp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .empty_trailer_temp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .seal_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .transfer_appointment
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_transfer_appointment_clicked();
      });
    this.$formGroupFieldValidationObservables
      .unlink_appointment
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_unlink_appointment_clicked();
      });
  }

  //#region private flows
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_update_clicked');
  
  
  $editor.toolbar.update.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.type.control.value,
      $editor.fields.scheduled_arrival.control.value,
      $editor.fields.scheduled_departure.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $editor.toolbar.update.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Edit Error', 'Please enter data in all required fields.');
      return;
  }
  
  // Project conditional check when the type is not outbound
  if (!$utils.isDefined($editor.fields.project.control.value) && $editor.fields.type.control.value !== 2) {
      $editor.toolbar.update.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Edit Error', 'Project selection is required.');
      return;
  }
  
  
  // Validate Fields
  // Enforce 15 minute window between scheduled arrival and scheduled departure
  if ($editor.entity.Status.Id == 0) {
      if ($editor.fields.scheduled_departure.control.value < $utils.date.add(15, 'minute', $editor.fields.scheduled_arrival.control.value)) {
          $editor.toolbar.update.control.readOnly = false;
          $shell.DockAppointments.openErrorDialog('Appointment Edit Error', 'The scheduled departure must be at least 15 minutes after the scheduled arrival.');
          return;
      }
  }
  
  // Update dock appointment
  
  let warehouse = (await $datasources.DockAppointments.ds_get_warehouse_by_warehouseId.get({ warehouseId: $editor.entity.WarehouseId })).result;
  if ($utils.isDefined(warehouse)) {
  
      var timezone = warehouse.TimeZoneId;
  }
  
  let payload: any = {};
  
  
  if ($editor.fields.dockDoor.control.isChanged) {
  
      if ($editor.entity.Status.Id == 0 || $editor.entity.Status.Id == 1) { // Open or In-Yard
  
          payload.ScheduledLocationContainerId = $editor.fields.dockDoor.control.value;
  
      }
      else {
  
          if ($utils.isDefined($editor.fields.dockDoor.control.value)) {
  
              payload.AssignedlLocationContainerId = $editor.fields.dockDoor.control.value;
              payload.ScheduledLocationContainerId = $editor.fields.dockDoor.control.value;
  
          }
          else {
  
              // Update dock appointment back to In-Yard
  
              payload.StatusId = 1,
                  payload.AssignedlLocationContainerId = null
  
  
  
          }
  
  
      }
  }
  
  if ($editor.fields.scheduled_arrival.control.isChanged) {
  
      let resultArrival = await $flows.DockAppointments.get_utc_date_by_timezone({
          dateTime: $editor.fields.scheduled_arrival.control.value,
          timezone: timezone
      });
  
      if ($utils.isDefined(resultArrival)) {
          var scheduledArrivalUtc = resultArrival.utcDate;
  
      }
  
      payload.ScheduledArrival = scheduledArrivalUtc;
  }
  
  
  if ($editor.fields.scheduled_departure.control.isChanged) {
  
      let resultDeparture = await $flows.DockAppointments.get_utc_date_by_timezone({
          dateTime: $editor.fields.scheduled_departure.control.value,
          timezone: timezone
      });
  
      if ($utils.isDefined(resultDeparture)) {
          var scheduledDeparturelUtc = resultDeparture.utcDate;
  
      }
  
      payload.ScheduledDeparture = scheduledDeparturelUtc;
  }
  
  
  if ($editor.fields.project.control.isChanged) {
      payload.ScheduledProjectId = $editor.fields.project.control.value;
  }
  
  if ($editor.fields.carrier.control.isChanged) {
      payload.ScheduledCarrierId = $editor.fields.carrier.control.value;
  }
  if ($editor.fields.reference_number.control.isChanged) {
      payload.ReferenceNumber = $editor.fields.reference_number.control.value;
  }
  if ($editor.fields.type.control.isChanged) {
      payload.TypeId = $editor.fields.type.control.value;
  }
  // Custom UDFs
  if ($editor.fields.estimated_pallets.control.isChanged) {
      payload.EstimatedPallets = $editor.fields.estimated_pallets.control.value;
  }
  if ($editor.fields.estimated_quantity.control.isChanged) {
      payload.EstimatedQuantity = $editor.fields.estimated_quantity.control.value;
  }
  if ($editor.fields.load_configuration.control.isChanged) {
      payload.LoadConfiguration = $editor.fields.load_configuration.control.value;
  }
  if ($editor.fields.trailer_number.control.isChanged) {
      payload.TrailerNumber = $editor.fields.trailer_number.control.value;
  }
  if ($editor.fields.full_trailer_temp.control.isChanged) {
      payload.FullTrailerTemp = $editor.fields.full_trailer_temp.control.value;
  }
  if ($editor.fields.empty_trailer_temp.control.isChanged) {
      payload.EmptyTrailerTemp = $editor.fields.empty_trailer_temp.control.value;
  } 
  if ($editor.fields.seal_number.control.isChanged) {
      payload.SealNumber = $editor.fields.seal_number.control.value;
  } 
  try {
      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  }
  catch (error) {
      $editor.toolbar.update.control.readOnly = false;
      $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  // Update dock appointment items
  let payloadItems: any = {};
  
  if ($editor.fields.order.control.isChanged || $utils.isDefined($editor.inParams.orderId)) {
  
      var selectedOrders = $editor.fields.order.control.value;
  
      // If there is already an order associated to the appointment
      if ($utils.isDefined($editor.entity.Items[0]?.Id)) {
  
  
          try {
  
  
              // Remove all orders asscociated to the appointment
              const orders = $editor.entity.Items;
              for (const order of orders) {
  
                  if ($utils.isDefined(order.ItemEntityId)) {
  
                      const dissociateOrderRequest = (await $flows.DockAppointments.diassociate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: order.ItemEntityId
                      }));
                  }
  
              }
  
  
              if ($utils.isDefined($editor.fields.order.control.value)) {
                   // Associate the new order to the existing appointment
                  for (const selectedOrder of selectedOrders) {
                      const dockAppointmentItemRequest = (await $flows.DockAppointments.associate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: selectedOrder
                      }));
                  }
                  const order = (await $datasources.DockAppointments.ds_get_order_by_orderId.get({ orderId: $editor.fields.order.control.value })).result;
                  if ($utils.isDefined(order)) {
                      const ownerId = order.Project.OwnerId;
                      const projectId = order.ProjectId;
  
                      // Update dock appointment
                      let payload: any = {};
                      payload.ScheduledOwnerId = ownerId;
                      payload.ScheduledProjectId = projectId;
                      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  
                  }
              }
          }
  
          catch (error) {
              $editor.toolbar.update.control.readOnly = false;
              $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
  
          }
  
          // No existing order associated to the appointment
      } else {
  
          try {
  
              if ($utils.isDefined($editor.fields.order.control.value)) {
                  
                  // Associate the new order to the existing appointment
                  for (const selectedOrder of selectedOrders) {
                      const dockAppointmentItemRequest = (await $flows.DockAppointments.associate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: selectedOrder
                      }));
                  }
                  const order = (await $datasources.DockAppointments.ds_get_order_by_orderId.get({ orderId: $editor.fields.order.control.value })).result;
                  if ($utils.isDefined(order)) {
                      const ownerId = order.Project.OwnerId;
                      const projectId = order.ProjectId;
  
                      // Update dock appointment
                      let payload: any = {};
                      payload.ScheduledOwnerId = ownerId;
                      payload.ScheduledProjectId = projectId;
                      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  
                  }
              }
  
  
          } catch (error) {
              $editor.toolbar.update.control.readOnly = false;
              $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
  
      }
  }
  
  // Set confirmation outParam and close editor
  $editor.outParams.confirm = true;
  $editor.close();
  
  
  }
  on_type_change(event = null) {
    return this.on_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_type_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_type_change');
  
  
  
  if ($utils.isDefined($editor.fields.type.control.value)) {
  
  
      const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
          typeId: $editor.fields.type.control.value
      })).result;
  
      if ($utils.isDefined(appointmentType)) {
          $editor.vars.appointmentType = appointmentType.Options;
      }
  
  
  
      if ($editor.fields.type.control.value == 1) {
  
          // Clear out the order selection
          $editor.fields.order.hidden = true;
          $editor.fields.order.control.value = null;
          $editor.fields.order.control.displayText = null;
  
          // Make the project  required
          $editor.fields.project.required = true;
  
          // If the project field contains a value then open up the order selection
          if ($utils.isDefined($editor.fields.project.control.value)) {
  
              $editor.fields.order.hidden = false;
              $editor.fields.order.control.readOnly = false;
          }
  
      }
      else if ($editor.fields.type.control.value == 2) {
  
          // Make the order selector visible
          $editor.fields.order.hidden = false;
           $editor.fields.order.control.readOnly = false;
          $editor.fields.order.control.value = null;
          $editor.fields.order.control.displayText = null;
  
  
          // Make the project not required
          $editor.fields.project.required = false;
  
  
      } else if (appointmentType.Name === 'Return' && !$utils.isDefined($editor.fields.project.control.value)){
          let projectId = (await $datasources.Owners.ds_get_projects_by_lookupcode.get({lookupcode: 'Eversana Returns'})).result
          $editor.fields.project.control.value = projectId[0].Id
      } else {
  
          // Clear out the order selection
          $editor.fields.order.hidden = true;
          $editor.fields.order.control.value = null;
          $editor.fields.order.control.displayText = null;
  
          // Make the project  required
          $editor.fields.project.required = true;
  
      };
  
  }
  
  else {
  
  
      // Clear out the order selection
      $editor.fields.order.hidden = true;
      $editor.fields.order.control.value = null;
      $editor.fields.order.control.displayText = null;
  
      // Make the project  required
      $editor.fields.project.required = true;
  
  }
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_project_change');
  
  if ($utils.isDefined($editor.fields.project.control.value)) {
  
      // Clear out the order selection only if the type is inbound 
      if ($utils.isDefined($editor.fields.type.control.value)) {
  
          if ($editor.fields.type.control.value == 1) {
  
              $editor.fields.order.control.value = null;
              $editor.fields.order.control.displayText = null;
              $editor.fields.order.control.readOnly = false;
              $editor.fields.order.hidden = false;
  
              $editor.fields.project.required = true;
          }
          else if ($editor.fields.type.control.value == 2) {
  
              $editor.fields.order.control.readOnly = false;
              $editor.fields.order.hidden = false;
  
              $editor.fields.project.required = false;
  
          }
          else {
              $editor.fields.order.control.value = null;
              $editor.fields.order.control.displayText = null;
              $editor.fields.order.control.readOnly = true;
              $editor.fields.order.hidden = true;
  
              $editor.fields.project.required = true;
  
          }
  
      }
      else {
          $editor.fields.order.control.value = null;
          $editor.fields.order.control.displayText = null;
          $editor.fields.order.control.readOnly = true;
          $editor.fields.order.hidden = true;
  
          $editor.fields.project.required = true;
  
      }
  
  }
  
  else {
      // Clear out the order selection only if the type is inbound 
      if ($utils.isDefined($editor.fields.type.control.value)) {
  
          if ($editor.fields.type.control.value == 1) {
  
              $editor.fields.order.control.value = null;
              $editor.fields.order.control.displayText = null;
              $editor.fields.order.control.readOnly = true;
              $editor.fields.order.hidden = true;
  
              $editor.fields.project.required = true;
          }
          else if ($editor.fields.type.control.value == 2) {
  
              $editor.fields.order.control.readOnly = false;
              $editor.fields.order.hidden = false;
  
              $editor.fields.project.required = false;
  
          }
          else {
              $editor.fields.order.control.value = null;
              $editor.fields.order.control.displayText = null;
              $editor.fields.order.control.readOnly = true;
              $editor.fields.order.hidden = true;
  
              $editor.fields.project.required = true;
  
          }
  
      }
      else {
          $editor.fields.order.control.value = null;
          $editor.fields.order.control.displayText = null;
          $editor.fields.order.control.readOnly = true;
          $editor.fields.order.hidden = true;
  
          $editor.fields.project.required = true;
  
      }
  
  }
  
  
  if ($editor.entity.WarehouseId == null){
  
      $editor.fields.order.hidden = true;
  }
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_init');
  
  // Set Date/Time Formats
  $editor.fields.scheduled_arrival.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $editor.fields.scheduled_departure.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  
  
  }
  on_scheduled_arrival_change(event = null) {
    return this.on_scheduled_arrival_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scheduled_arrival_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_scheduled_arrival_change');
  
  
  
  if ($editor.fields.scheduled_arrival.control.isChanged) {
       $editor.fields.scheduled_departure.control.value = $utils.date.add(1, 'hour', $editor.fields.scheduled_arrival.control.value);
  }
  
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_delete_clicked');
  //O.Arias - 02/24/2023 - Initial Development
  
  // Set some default values to some variables
  let is_deletable = 'No';
  let assignment_exists = 'No';
  let message_box_context = 'Are you sure you want to delete appointment ' + $editor.entity.LookupCode + '?';
  
  // Gather dock appointment status
  const dockAppt = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
      appointmentId: $editor.entity.Id
  })).result;
  
  // Check for valid statuses
  if ($utils.isDefined(dockAppt)) {
      const dockStatus = dockAppt.StatusId
      if (dockStatus == 0) { is_deletable = 'Yes' }; //Created
      if (dockStatus == 5) { is_deletable = 'Yes' }; //Cancelled
      if (dockStatus == 1) { is_deletable = 'In-Yard' };
      if (dockStatus == 2) { is_deletable = 'Door Assigned' };
      if (dockStatus == 3) { is_deletable = 'In-Process' };
      if (dockStatus == 4) { is_deletable = 'Completed' };
  }
  
  if (is_deletable == 'Yes') {
  
      //Check for existing items
      const dockAppointmentItems = (await $datasources.DockAppointments.ds_dock_appointment_items.get({ dockAppointmentId: $editor.entity.Id })).result;
  
      //Populate the additional context to the message box
      if ($utils.isDefined(dockAppointmentItems)) {
          assignment_exists = 'True';
          message_box_context = 'The appointment is already assigned! ' + message_box_context + ' All current assignments will be deleted!';
      };
  
      //Making sure the message box has data
      if ($utils.isDefined(message_box_context)) {
  
          const confirm = await $shell.DockAppointments.openConfirmationDialog('Delete Dock Appointment ' + $editor.entity.LookupCode, message_box_context, 'Yes', 'No');
          if (confirm) {
              try {
  
                  //Delete the dock appointment items
                  if ($utils.isDefined(dockAppointmentItems)) {
                      for (let item of dockAppointmentItems) {
                          let itemId = item.Id;
                          //Delete dock appointment item record
                          await $flows.Utilities.crud_delete_flow({ entitySet: 'DockAppointmentItems', id: itemId });
                      }
                  }
  
                  //Clear the dock appointment UDFs
                  let payload: any = {};
                  const dockAppointment = (await $datasources.custom_ds_get_dock_appointment_udfs_by_dockaAppointmentId.get({ 
                      dockAppointmentId: $editor.entity.Id })).result;
                  if ($utils.isDefined(dockAppointment.EstimatedPallets)) {
                      payload.EstimatedPallets = null;
                  }
                  if ($utils.isDefined(dockAppointment.EstimatedQuantity)) {
                      payload.EstimatedQuantity = null;
                  }
                  if ($utils.isDefined(dockAppointment.LoadConfiguration)) {
                      payload.LoadConfiguration = null;
                  }
                  if ($utils.isDefined(dockAppointment.SealNumber)) {
                      payload.SealNumber = null;
                  }
                  if ($utils.isDefined(dockAppointment.linkedDockAppointmentId)) {
                      payload.linkedDockAppointmentId = null;
                  }
                  if ($utils.isDefined(dockAppointment.TrailerNumber)) {
                      payload.TrailerNumber = null;
                  }
  
                  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id , entity: payload });
  
                  //Delete the dock appointment
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id });
  
                  //Set the confirm output to true so it refreshes the caller
                  $editor.outParams.confirm = true;
                  //Close the editor
                  $editor.close();
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Dock Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
                  await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', 'An error occurred during the deletion of the dock appointment: ', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
          }
      } else {
          await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', 'Unhandled Exception!');
          return;
      };
  
  }
  else {
      if (is_deletable !== 'Yes') {
          message_box_context = 'Dock appointment ' + $editor.entity.LookupCode + ' is in [' + is_deletable + '] status and cannot be deleted!';
      } else {
          message_box_context = 'Unhandled Exception!'
      };
      await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', message_box_context);
      return;
  };
  
  
  }
  on_check_in_clicked(event = null) {
    return this.on_check_in_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_check_in_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_check_in_clicked');
  
  $editor.toolbar.check_in.control.readOnly = true;
  
  
  if ($utils.isDefined($editor.entity.WarehouseId)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_check_in_options_formDialog({
          dockAppointmentId: $editor.inParams.dockAppointmentId,
          warehouseId: $editor.entity.WarehouseId,
          scheduledDockDoorId: $editor.fields.dockDoor.control.value
      });
      var userConfirmed = dialogResult.confirm;
  
      var scheduledDoorId = dialogResult.scheduledDoorId;
      var assignedDoorId = dialogResult.assignedDoorId;
      var driverName = dialogResult.driverName;
      var driverLicense = dialogResult.driverLicense;
      var vehicleLicense = dialogResult.vehicleLicense;
  
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          $editor.toolbar.check_in.control.readOnly = false;
          return;
      }
  
      if (userConfirmed) {
          try {
  
  
  
             const user = (await $flows.Utilities.get_username_flow({})).userName;
              // Cannot use native actions as timezone is conflicting
  
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CheckInDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     DriverName: driverName,
              //     DriverLicenseNo: driverLicense,
              //     EquipmentLicenseNo: equipmentLicense
              // });
  
              // Update dock appointment
              let payload: any = {};
  
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
              var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
              payload.CheckedInBy = user,
                  payload.DriverName = driverName,
                  payload.DriverLicense = driverLicense,
                  payload.VehicleLicense = vehicleLicense,
                  payload.StatusId = 1,
                  payload.CheckedInOn = resultUtc
              payload.ScheduledDeparture = newScheduledDeparture
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
              if ($utils.isDefined(scheduledDoorId)) {
                  // Update dock appointment
                  let payload: any = {};
                  payload.ScheduledLocationContainerId = scheduledDoorId;
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
              }
  
              if ($utils.isDefined(assignedDoorId)) {
                  // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.AssignDoorToDockAppointment({
                  //     DockAppointmentId: $editor.entity.Id,
                  //     UserName: user,
                  //     DockDoorId: assignedDoorId
                  // });
  
                  // Update dock appointment
                  let payload: any = {};
                  payload.AssignedBy = user,
                      payload.StatusId = 2,
                      payload.AssignedOn = resultUtc,
                      payload.ScheduledDeparture = newScheduledDeparture,
                      payload.AssignedlLocationContainerId = assignedDoorId
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
              }
  
              // Set confirmation outParam and close editor
              $editor.outParams.confirm = true;
              $editor.close();
  
  
          } catch (error) {
              $editor.toolbar.check_in.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.DockAppointments.openErrorDialog('Check in appointment error', 'An error occurred during checking in of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
  
          }
      }
  }
  else {
      throw new Error('Unable to check in appointment, please first select a dock door.')
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_data_loaded');
  if ($editor.entity.TMS_BOLCalled) {
      $editor.toolbar.submit_to_tms.control.label = 'Submitted to TMS';
      $editor.toolbar.submit_to_tms.control.icon = 'icon-ic_fluent_checkmark_circle_20_regular';
      $editor.toolbar.submit_to_tms.control.readOnly = true;
      $editor.toolbar.submit_to_tms.control.styles.setStyle('color', 'rgba(0, 255, 0, 1)');
  }
  
  // Pass the appointment type into the variable which is used as a filter on the dock door
  const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
      typeId: $editor.fields.type.control.value
  })).result;
  
  if ($utils.isDefined(appointmentType)) {
      $editor.vars.appointmentType = appointmentType.Options;
  }
  
  
  $editor.vars.orders = [];
  
  // Set orders variable to all assigned orders
  if ($utils.isDefined($editor.entity.Items)) {
  
      const orders = $editor.entity.Items;
      for (const order of orders) {
  
          if ($utils.isDefined(order.ItemEntityId)) {
              $editor.vars.orders.push(order.ItemEntityId);
          }
      }
  
  }
  if ($utils.isDefined($editor.inParams.orderId)) {
      const numberArray: number[] = [$editor.inParams.orderId];
      const orderId: number = parseInt(numberArray.join(''));
  
      $editor.vars.orders.push(orderId)
  }
  
  $editor.fields.order.control.value = $editor.vars.orders;
  
  // Handle link transfer and transfer appointment button
  var linkedDockAppointment = $editor.entity.linkedDockAppointmentId;
  if ($utils.isDefined(linkedDockAppointment)) {
  
      $editor.toolbar.link_appointment.hidden = true;
  
      $editor.fields.unlink_appointment.hidden = false;
      $editor.fields.linked_appointment.hidden = false;
  
      // If the current appointment is in a Door Assigned or In-Process status then show and allow the transfer button
      if ($editor.entity.Status.Id == 2 || $editor.entity.Status.Id == 3) {
          $editor.fields.transfer_appointment.hidden = false;
      }
      else {
          $editor.fields.transfer_appointment.hidden = true;
      }
  
      $editor.fields.linked_appointment.control.text = `Appointment: ${linkedDockAppointment}`
  }
  else {
      $editor.toolbar.link_appointment.hidden = false;
      $editor.fields.unlink_appointment.hidden = true;
      $editor.fields.transfer_appointment.hidden = true;
      $editor.fields.linked_appointment.hidden = true;
  }
  
  // Change label of dock door to scheduled vs assigned based on state of the appointment
  
  if ($editor.entity.Status.Id == 0) // Open
  {
      $editor.fields.dockDoor.label = 'Scheduled Dock Door'
  
      // Hidden
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
  
      // Visible
      $editor.toolbar.check_in.hidden = false;
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.on_delete.hidden = false;
  
  }
  
  
  
  else if ($editor.entity.Status.Id == 1) {  // In-Yard
  
      $editor.fields.dockDoor.label = 'Scheduled Dock Door';
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
  
      // Visible
      $editor.toolbar.on_assign_door.hidden = false
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.on_delete.hidden = false;
  
  
      $editor.fields.scheduled_arrival.control.readOnly = true;
  
  
  }
  
  else if ($editor.entity.Status.Id == 2) {  // Door Assigned
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
      // Visible
      $editor.toolbar.on_mark_in_process.hidden = false;
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
  
  
  
      // Dates
  
      $editor.fields.scheduled_arrival.control.readOnly = true;
  
  
  
  }
  
  else if ($editor.entity.Status.Id == 3) {  // In process
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
      // Visible
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
  
  
      // Dates
  
      $editor.fields.scheduled_arrival.control.readOnly = true;
  
  
  
  
  }
  
  else if ($editor.entity.Status.Id == 4) {  // Completed
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
  
  
      // Readonly
      $editor.fields.dockDoor.control.readOnly = true;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.order.control.readOnly = true;
      $editor.fields.carrier.control.readOnly = true;
  
      // Dates
      $editor.fields.scheduled_departure.label = 'Completed On';
      $editor.fields.scheduled_departure.control.value = $editor.entity.convertedCompletedOn?.convertedDate;
  
      var arivalDate = $utils.isDefined($editor.entity.convertedInProcessOn?.convertedDate) ? $editor.entity.convertedInProcessOn?.convertedDate :
          ($utils.isDefined($editor.entity.convertedAssignedOn?.convertedDate) ? $editor.entity.convertedAssignedOn?.convertedDate :
              ($utils.isDefined($editor.entity.convertedCheckInOn?.convertedDate) ? $editor.entity.convertedCheckInOn?.convertedDate : null))
  
      var arrivalLabel = $utils.isDefined($editor.entity.InProcessOn) ? 'In Process On' :
          ($utils.isDefined($editor.entity.AssignedOn) ? 'Assigned On' :
              ($utils.isDefined($editor.entity.CheckedInOn) ? 'Checked In On' : ''))
  
      $editor.fields.scheduled_arrival.label = $utils.isDefined(arrivalLabel) ? arrivalLabel : 'Scheduled Arrival';
      $editor.fields.scheduled_arrival.control.value = $utils.isDefined(arivalDate) ? arivalDate : $editor.entity.convertedScheduledArrival.convertedDate;
  
  
  }
  
  else if ($editor.entity.Status.Id == 5) {  // Cancelled
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
  
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
  
  
      // Readonly
      $editor.fields.dockDoor.control.readOnly = true;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.order.control.readOnly = true;
      $editor.fields.carrier.control.readOnly = true;
  
      // Dates
      $editor.fields.scheduled_departure.label = 'Cancelled On';
      $editor.fields.scheduled_departure.control.value = $editor.entity.convertedCancelledOn?.convertedDate;
  
      $editor.fields.scheduled_arrival.hidden = true;
  
  
  }
  
  
  // If the dock door has a value call the door_change flow to set the doorType variable
  if ($utils.isDefined($editor.entity.ScheduledLocation) || $utils.isDefined($editor.entity.AssignedLocation)) {
  
      $editor.on_door_change();
  }
  
  
  // Lock down the delete button if one of the orders is of type FDA Order class
  const orders = (await $datasources.custom_ds_get_order_by_orderIds.get({ orderIds: $editor.vars.orders })).result;
  if ($utils.isDefined(orders)) {
      for (const order of orders) {
  
          let orderClass = order.OrderClass?.LookupCode;
          if ($utils.isDefined(orderClass)) {
  
              if (orderClass == 'FDA') {
                  $editor.toolbar.on_delete.hidden = true;
                  break; // Exit once the first FDA order is found
              }
  
          }
      }
  }
  
  // Hide 'submit_to_tms' if no outbound orders
  const tmsSubmitFailureReasons = await $editor.check_can_submit_to_tms();
  if (tmsSubmitFailureReasons.length > 0) {
      $editor.toolbar.submit_to_tms.hidden = true;
      $editor.toolbar.separator2.hidden = true;
  } else {
      $editor.toolbar.submit_to_tms.hidden = false;
      $editor.toolbar.separator2.hidden = false;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_cancel_clicked');
  
  
  $editor.toolbar.cancel.control.readOnly = true;
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_cancel_options_formDialog({});
  var userConfirmed = dialogResult.confirm;
  var reasonCodeId = dialogResult.reasonCodeId;
  
  
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      $editor.toolbar.cancel.control.readOnly = false;
      return;
  }
  
  if (userConfirmed) {
      try {
  
          // Cannot use native actions as timezone is conflicting
        const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          if ($utils.isDefined(reasonCodeId)) {
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CancelDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     ReasonCodeId: reasonCodeId
              // });
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
  
  
              let payload: any = {};
  
  
              payload.CancelledBy = user,
                  payload.StatusId = 5,
                  payload.CancelledOn = resultUtc,
                  payload.CancelledReasonCodeId = reasonCodeId
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
          }
  
          // Remove all orders asscociated to the appointment
          const orders = $editor.entity.Items;
          for (const order of orders) {
  
              if ($utils.isDefined(order.ItemEntityId)) {
  
                  const dissociateOrderRequest = (await $flows.DockAppointments.diassociate_orders_from_dock_appointment_flow({
                      dockAppointmentId: $editor.inParams.dockAppointmentId,
                      orderId: order.ItemEntityId
                  }));
              }
  
          }
  
  
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
      } catch (error) {
          $editor.toolbar.cancel.control.readOnly = false;
  
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Assigning door on appointment error', 'An error occurred during the door assignment of the appointment.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_complete_clicked');
  
  
  $editor.toolbar.complete.control.readOnly = true;
  
  const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Complete Appointment', `Are you sure you want to complete appointment  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  
  
  if (userConfirmed) {
      try {
  
  
          // Cannot use native actions as timezone is conflicting
         const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CompleteDockAppointment({
          //     DockAppointmentId: $editor.entity.Id,
          //     UserName: user
          // });
          let payload: any = {};
  
          var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
       
  
          payload.CompletedBy = user,
              payload.StatusId = 4,
              payload.CompletedOn = resultUtc
              payload.FullTrailerTemp = $editor.fields.full_trailer_temp.control.value
              payload.EmptyTrailerTemp = $editor.fields.empty_trailer_temp.control.value
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
  
      } catch (error) {
  
          $editor.toolbar.complete.control.readOnly = false;
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Complete appointment error', 'An error occurred during completion of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  
  else {
      $editor.toolbar.complete.control.readOnly = false;
  }
  
  
  }
  on_mark_in_process_clicked(event = null) {
    return this.on_mark_in_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_mark_in_process_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_mark_in_process_clicked');
  
  $editor.toolbar.on_mark_in_process.control.readOnly = true;
  
  
  const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Mark In Process Appointment', `Are you sure you want to mark appointment ${$editor.entity.LookupCode} in process?`, 'Yes', 'No');
  
  
  if (userConfirmed) {
      try {
  
          // Cannot use native actions as timezone is conflicting
         const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          //     const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.MarkInProcessDockAppointment({
          //     DockAppointmentId: $editor.entity.Id,
          //     UserName: user
          // });
  
          let payload: any = {};
  
          var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
          var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
          payload.InProcesesBy = user,
              payload.StatusId = 3,
              payload.InProcessOn = resultUtc,
              payload.ScheduledDeparture = newScheduledDeparture
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
      } catch (error) {
  
          $editor.toolbar.on_mark_in_process.control.readOnly = false;
  
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Mark in process appointment error', 'An error occurred during setting the appointment to in process.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  
  else {
      $editor.toolbar.on_mark_in_process.control.readOnly = false;
  }
  
  
  
  }
  on_assign_door_clicked(event = null) {
    return this.on_assign_door_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_door_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_assign_door_clicked');
  
  
  
  $editor.toolbar.on_assign_door.control.readOnly = true;
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_assign_door_options_formDialog({
      dockAppointmentId: $editor.inParams.dockAppointmentId,
      warehouseId: $editor.entity.WarehouseId,
      scheduledDockDoorId: $editor.fields.dockDoor.control.value
  });
  var userConfirmed = dialogResult.confirm;
  var assignedDoorId = dialogResult.assignedDoorId;
  
  
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      $editor.toolbar.on_assign_door.control.readOnly = false;
      return;
  }
  
  if (userConfirmed) {
      try {
  
          // Cannot use native actions as timezone is conflicting
               const user = (await $flows.Utilities.get_username_flow({})).userName;
          if ($utils.isDefined(assignedDoorId)) {
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.AssignDoorToDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     DockDoorId: assignedDoorId
              // });
              // Update dock appointment
              
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
              var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
              let payload: any = {};
  
              payload.AssignedBy = user,
                  payload.StatusId = 2,
                  payload.AssignedOn = resultUtc,
                  payload.ScheduledDeparture = newScheduledDeparture,
                  payload.AssignedlLocationContainerId = assignedDoorId
  
  
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
          }
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
      } catch (error) {
          $editor.toolbar.on_assign_door.control.readOnly = false;
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Assigning door on appointment error', 'An error occurred during the door assignment of the appointment.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  
  
  }
  
  }
  on_link_appointment_clicked(event = null) {
    return this.on_link_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_link_appointment_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_link_appointment_clicked');
  var excludedDockAppointmentIds: any[] = [];
  excludedDockAppointmentIds.push($editor.entity.Id);
  
  // Check if the current appointment is linked to another appointment, if so exclude that linked appointment
  const parentLink = (await $datasources.custom_ds_get_parent_appointment_by_linkedDockAppointmentId.get({ linkedDockAppointmentId: $editor.entity.Id })).result;
  if ($utils.isDefined(parentLink)) {
      const parentDockAppointmentId = parentLink[0].Id;
      if ($utils.isDefined(parentDockAppointmentId)) {
          excludedDockAppointmentIds.push(parentDockAppointmentId)
      }
  }
  
  // Get a list of appointments that are linked as children and exclude them
  const childLinks = (await $datasources.custom_ds_get_dock_appointments_with_associated_linked_appointments.get({})).result;
  if ($utils.isDefined(childLinks)){
  
      for (let childLink of childLinks){
          const childAppointmentId = childLink.linkedDockAppointmentId;
          excludedDockAppointmentIds.push(childAppointmentId);
  
      }
  
  }
  
  var selectionResult = await $shell.FootPrintManager.opendock_appointment_selection_wizardDialog({
      excludedDockAppointmentIds: excludedDockAppointmentIds
  });
  if ($utils.isDefined(selectionResult)) {
  
      var userConfirm = selectionResult.confirm;
      if (userConfirm) {
  
          var dockappointmentId = selectionResult.dockAppointmentId;
  
          if ($utils.isDefined(dockappointmentId)) {
  
              // Make sure that the selected appointment to link has a expected arrival date that is greater than the expected departure date of the orginal appointment
              const originalDockAppt = (await $datasources.custom_ds_get_dock_appointment_by_appointmentId.get({ appointmentId: $editor.entity.Id })).result;
              const linkedDockAppt = (await $datasources.custom_ds_get_dock_appointment_by_appointmentId.get({ appointmentId: dockappointmentId })).result;
  
              if ($utils.isAllDefined(originalDockAppt, linkedDockAppt)) {
  
                  const expectedArrival = linkedDockAppt.ScheduledArrival;
                  const expectedDeparture = originalDockAppt.ScheduledDeparture;
                  if ($utils.isAllDefined(expectedArrival, expectedDeparture)) {
  
                      if (expectedArrival < expectedDeparture) {
                          $shell.FootPrintManager.openInfoDialog('Appointment link', 'Unable to update appointment with the requested link as the expected arrival is less than the expected departure of the current appointment.')
                      } else {
                          // Update dock appointment
                          let payload: any = {};
  
                          payload.linkedDockAppointmentId = dockappointmentId
  
                          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
                          $editor.outParams.confirm = true;
                          $editor.refresh();
                      }
  
                  } else {
                      $shell.FootPrintManager.openInfoDialog('Appointment link', 'Unable to update appointment with requested link.')
                  }
              }
              else {
                  $shell.FootPrintManager.openInfoDialog('Appointment link', 'Unable to update appointment with requested link.')
              }
  
  
  
          }
          else {
              $shell.FootPrintManager.openInfoDialog('Appointment link', 'Unable to update appointment with requested link.')
          }
  
      } else {
          $shell.FootPrintManager.openInfoDialog('Appointment link', 'Appointment link terminated.')
      }
  
  } else {
      $shell.FootPrintManager.openInfoDialog('Appointment link', 'Appointment link terminated.')
  }
  
  
  
  }
  on_transfer_appointment_clicked(event = null) {
    return this.on_transfer_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transfer_appointment_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_transfer_appointment_clicked');
  var currentAppointmentId = $editor.inParams.dockAppointmentId;
  var linkedAppointmentId = $editor.entity.linkedDockAppointmentId;
  
  if ($utils.isAllDefined(currentAppointmentId, linkedAppointmentId)) {
  
      if ($editor.fields.transfer_appointment.control.value == true) {
          const linkedAppointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
              appointmentId: linkedAppointmentId
          })).result;
  
          if ($utils.isDefined(linkedAppointment)) {
  
              var scheduledDoor = linkedAppointment.ScheduledLocation?.Id;
              if ($utils.isDefined(scheduledDoor)) {
  
  
                  const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Transfer Appointment', `Are you sure you want to complete appointment ${$editor.entity.LookupCode} and transfer to appointment ${linkedAppointmentId}?`, 'Yes', 'No');
  
  
                  if (userConfirmed) {
                      try {
  
  
                          var user = (await $flows.Utilities.get_username_flow({})).userName;
                          var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
                          var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc);
  
                          // Update the linked appointment
                          let linkedpayload: any = {};
  
                          linkedpayload.AssignedBy = user,
                              linkedpayload.StatusId = 2, // Door Assigned
                              linkedpayload.AssignedOn = resultUtc,
                              linkedpayload.ScheduledDeparture = newScheduledDeparture,
                              linkedpayload.AssignedlLocationContainerId = scheduledDoor,
  
                              linkedpayload.CheckedInBy = user,
                              linkedpayload.CheckedInOn = resultUtc
  
                          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: linkedAppointmentId, entity: linkedpayload });
  
  
                          // Complete current appointment
                          let payload: any = {};
                          payload.CompletedBy = user,
                              payload.StatusId = 4,
                              payload.CompletedOn = resultUtc
  
                          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: currentAppointmentId, entity: payload });
  
  
                          $editor.outParams.confirm = true;
                          $editor.refresh();
  
  
  
  
  
                      } catch (error) {
                          let targetError = error;
                          while ($utils.isDefined(targetError.error)) {
                              targetError = targetError.error;
                          }
  
                          const errorMessage = targetError.message;
                          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                          let errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
  
                          let errorSplit = errorDescription.split('  ');
                          if (errorSplit.length >= 3) { errorDescription = errorSplit[1]; }
  
                          await $shell.DockAppointments.openErrorDialog('Transfer appointment error', 'An error occurred during completion and transfer of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
                          $editor.fields.transfer_appointment.control.value = false;
  
  
                      }
                  }
                  else {
                      $editor.fields.transfer_appointment.control.value = false;
                  }
  
              }
              else {
                  $shell.openErrorDialog('Transfer Appointment', 'Linked appointment does not contain a scheduled dock door and cannot be transferred.')
                  $editor.fields.transfer_appointment.control.value = false;
  
              }
  
          }
  
          else {
  
              $shell.openErrorDialog('Transfer Appointment', 'Unable to gather details around the linked appointment.')
              $editor.fields.transfer_appointment.control.value = false;
  
          }
  
      }
  }
  else {
  
      $shell.openErrorDialog('Transfer Appointment', 'Unable to determine linked appointment.')
      $editor.fields.transfer_appointment.control.value = false;
  
  
  }
  
  
  
  
  
  
  
  }
  on_unlink_appointment_clicked(event = null) {
    return this.on_unlink_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unlink_appointment_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_unlink_appointment_clicked');
  var currentAppointmentId = $editor.inParams.dockAppointmentId;
  var linkedAppointmentId = $editor.entity.linkedDockAppointmentId;
  if ($utils.isAllDefined(currentAppointmentId, linkedAppointmentId)) {
  
  
      if ($editor.fields.unlink_appointment.control.value == true) {
          const linkedAppointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
              appointmentId: linkedAppointmentId
          })).result;
  
          if ($utils.isDefined(linkedAppointment)) {
  
  
              const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Unlink Appointment', `Are you sure you want to unlink appointment ${linkedAppointmentId} from ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  
  
              if (userConfirmed) {
                  try {
  
                      // Unlink current appointment
                      let payload: any = {};
  
                      payload.linkedDockAppointmentId = null
  
                      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: currentAppointmentId, entity: payload });
  
                      // Refresh editor for current appointment
                      $editor.outParams.confirm = true;
                      $editor.refresh();
  
  
                  } catch (error) {
  
        
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
                      await $shell.DockAppointments.openErrorDialog('Unlink appointment error', 'An error occurred during unlinking of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
                      $editor.refresh();
  
  
                  }
              }
              else {
                  $editor.fields.unlink_appointment.control.value = false;
              }
  
  
  
          }
  
          else {
  
              $shell.openErrorDialog('Unlink Appointment', 'Unable to gather details around the linked appointment.')
                 $editor.fields.unlink_appointment.control.value = false;
  
          }
      }
      else {
  
          $shell.openErrorDialog('Unlink Appointment', 'Unable to determine linked appointment.')
            $editor.fields.unlink_appointment.control.value = false;
  
      }
  
  
  }
  
  
  
  
  
  
  }
  on_door_change(event = null) {
    return this.on_door_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_door_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_door_change');
  const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({
      locationId: $editor.fields.dockDoor.control.value
      })).result;
  
  if($utils.isDefined(dockDoor)){
      const allowInbound = dockDoor.EligibleForAllocation;
      const allowOutbound = dockDoor.IsPrimaryPick;
  
      if (allowInbound){
          $editor.vars.doorType = 'Inbound'
      }
      else if (allowOutbound){
          $editor.vars.doorType = 'Outbound'
      }
      else {
          $editor.vars.doorType = 'Both'
      }
  }
  
  
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_attachments_clicked');
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'DockAppointment', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_surveys_clicked');
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'Appointment', ids: [$editor.entity.Id] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  
  
  }
  on_submit_to_tms_clicked(event = null) {
    return this.on_submit_to_tms_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_submit_to_tms_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_submit_to_tms_clicked');
  const tmsSubmitFailureReasons = await $editor.check_can_submit_to_tms();
  if (tmsSubmitFailureReasons.length > 0) {
      $shell.FootPrintManager.openToaster(`Not ready for TMS Submission`, tmsSubmitFailureReasons.join(', '), EToasterType.Error, { timeOut: 10000, tapToDismiss: false, progressBar: true, positionClass: EToasterPosition.bottomRight });
      return;
  }
  
  const results = await $flows.custom_submit_dock_appointment_to_tms({ dock_appointment_id: $editor.entity.Id });
  
  if ($utils.isDefined(results.reasons)) {
      $shell.FootPrintManager.openToaster(`Error submitting to TMS`, results.reasons.join(', '), EToasterType.Error, { timeOut: 10000, tapToDismiss: false, progressBar: true, positionClass: EToasterPosition.topRight });
  } else {
      $shell.FootPrintManager.openToaster(`Submitted to TMS`, null, EToasterType.Success, { timeOut: 5000, tapToDismiss: true, progressBar: false, positionClass: EToasterPosition.bottomRight });
      $editor.refresh();
  }
  }
  check_can_submit_to_tms(event = null) {
    return this.check_can_submit_to_tmsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async check_can_submit_to_tmsInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.check_can_submit_to_tms');
  let failReasons: string[] = [];
  if (!$utils.isDefined($editor.fields.order.control.value)) {
      failReasons.push(`No orders assigned.`);
  } else {
      const orders = (await $datasources.custom_ds_get_order_by_orderIds.get({ orderIds: $editor.fields.order.control.value })).result;
      // Hide 'submit_to_tms' if no outbound orders
      if (!orders.some(r => r.OrderClass.OrderTypeId === 2)) {
          failReasons.push(`No outbound orders assigned.`);
      }
  
      for (const order of orders) {
          // Check if any open pick tasks
          if (order.ShipmentOrderLookups
              .some(sol => sol.Shipment.Tasks
                  .some(t => [8,23].includes(t.OperationCodeId) && [1,4].includes(t.StatusId))
              )
          ) {
              failReasons.push(`Order ${order.LookupCode}: Picking is not completed.`);
          }
  
          // Check if any license plates are not loaded
          const loadedLps = order.ShipmentOrderLookups.reduce((output: number[], sol) => 
              output.concat(sol.Shipment.Tasks.filter(t => t.OperationCodeId === 24 && t.StatusId === 2).map(t => t.ActualSourceLicensePlateId))
          , []);
          
          if (order.ShipmentOrderLookups
              .some(sol => sol.Shipment.LicensePlates
                  .some(lp => !loadedLps.includes(lp.Id)))
          ) {
              failReasons.push(`Order ${order.LookupCode}: Loading is not completed.`);
          }
  
          // Check if any are not TL
          for (const sol of order.ShipmentOrderLookups) {
              const service = sol.Shipment.CarrierServiceType?.Services?.find(s => s.CarrierId === sol.Shipment.CarrierId);
  
              if (!$utils.isDefinedTrimmed(service?.ServiceMode) || service.ServiceMode.trim().toLowerCase() === 'small parcel') {
                  failReasons.push(`Order ${order.LookupCode}, shipment ${sol.Shipment.LookupCode} has an unsupported carrier service type.`);
              }
          }
      }
  }
  
  return failReasons;
  }
  //#endregion private flows
  
}
