import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Locations_location_statuses_dd_singleComponent } from './Locations.location_statuses_dd_single.component'
import { Locations_storage_categories_dd_singleComponent } from './Locations.storage_categories_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'
import { Locations_mixing_rule_dd_singleComponent } from './Locations.mixing_rule_dd_single.component'
import { PrintNode_scales_dd_singleComponent } from './PrintNode.scales_dd_single.component'

type EntityType = { 
    Id?: number, AllowInventoryIfNotActive?: boolean, AttachedPrinter?: string, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, FDA_ReleasedLocation?: boolean, HazardousMaterial?: boolean, Height?: number, HospitalLocation?: string, IsDynamicPrimaryPick?: boolean, IsLoose?: boolean, IsPackageVerification?: boolean, IsPrimaryPick?: boolean, IsThisHospital?: boolean, IsUsedForReturns?: boolean, Length?: number, Name?: string, PurgeAddedInventory?: boolean, SkipAfterPickingCountBack?: boolean, SkipCycleCount?: boolean, SkipRecommendation?: boolean, SkipValidationRules?: boolean, StagingLocation?: boolean, StatusId?: number, StorageCategoryId?: number, VolumeCapacity?: number, VolumeUomId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string }, WeightUom?: { Short_name?: string }, DimensionsUom?: { Short_name?: string }, MixingRule?: { Id?: number, Name?: string }, VolumeUom?: { Short_name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_location_statuses_dd_singleComponent),
    forwardRef(() => Locations_storage_categories_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
    forwardRef(() => Locations_mixing_rule_dd_singleComponent),
    forwardRef(() => PrintNode_scales_dd_singleComponent),
  ],
  selector: 'app-custom_location_editor',
  templateUrl: './app.custom_location_editor.component.html'
})
export class app_custom_location_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { locationId: number } = { locationId: null };
  //#region Inputs
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    storage_category: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    eligible_for_allocation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_loose: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_primary: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_dynamic_primary_pick: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pack_verification: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    skip_cycle_count: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    purge_added_inventory: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allow_inactive: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    skip_count_back: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    skip_validation_rules: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_used_for_returns: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    hazardous_material: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_hospital_location: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_FDA_release_location: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    skip_recomendation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    staging_location: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_capacity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_capacity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    child_footprint: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    child_stack_height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    mixing_restrictions: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    printer_alias: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scale: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    hospital_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    storage_category: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['storage_category'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Storage category', false, false),
    eligible_for_allocation: new FieldModel(new CheckBoxModel(this.formGroup.controls['eligible_for_allocation'] as DatexFormControl, null, false, false, 'Eligible for allocation', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_loose: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_loose'] as DatexFormControl, null, false, false, 'Loose', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_primary: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_primary'] as DatexFormControl, null, false, false, 'Primary pick', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_dynamic_primary_pick: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_dynamic_primary_pick'] as DatexFormControl, null, false, false, 'Dynamic primary pick', null)
, new ControlContainerStyles(null, null), '', false, false),
    pack_verification: new FieldModel(new CheckBoxModel(this.formGroup.controls['pack_verification'] as DatexFormControl, null, false, false, 'Pack verification', null)
, new ControlContainerStyles(null, null), '', false, false),
    skip_cycle_count: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_cycle_count'] as DatexFormControl, null, false, false, 'Skip cycle count', null)
, new ControlContainerStyles(null, null), '', false, false),
    purge_added_inventory: new FieldModel(new CheckBoxModel(this.formGroup.controls['purge_added_inventory'] as DatexFormControl, null, false, false, 'Purge added inventory', null)
, new ControlContainerStyles(null, null), '', false, false),
    allow_inactive: new FieldModel(new CheckBoxModel(this.formGroup.controls['allow_inactive'] as DatexFormControl, null, false, false, 'Allow inactive', null)
, new ControlContainerStyles(null, null), '', false, false),
    skip_count_back: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_count_back'] as DatexFormControl, null, false, false, 'Skip count back', null)
, new ControlContainerStyles(null, null), '', false, false),
    skip_validation_rules: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_validation_rules'] as DatexFormControl, null, false, false, 'Skip validation rules', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_used_for_returns: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_used_for_returns'] as DatexFormControl, null, false, false, 'Return location', null)
, new ControlContainerStyles(null, null), '', false, false),
    hazardous_material: new FieldModel(new CheckBoxModel(this.formGroup.controls['hazardous_material'] as DatexFormControl, null, false, false, 'Hazardous material', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_hospital_location: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_hospital_location'] as DatexFormControl, null, false, false, 'Is hospital location', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_FDA_release_location: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_FDA_release_location'] as DatexFormControl, null, false, false, 'FDA released default', null)
, new ControlContainerStyles(null, null), '', false, false),
    skip_recomendation: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_recomendation'] as DatexFormControl, null, false, false, 'Skip for recommendation', null)
, new ControlContainerStyles(null, null), '', false, false),
    staging_location: new FieldModel(new CheckBoxModel(this.formGroup.controls['staging_location'] as DatexFormControl, null, false, false, 'Staging location', null)
, new ControlContainerStyles(null, null), '', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Length', false, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Width', false, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Height', false, false),
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Dimension uom', false, false),
    weight_capacity: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight_capacity'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Weight capacity', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Weight uom', false, false),
    volume_capacity: new FieldModel(new NumberBoxModel(this.formGroup.controls['volume_capacity'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Volume capacity', false, false),
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Volume uom', false, false),
    child_footprint: new FieldModel(new NumberBoxModel(this.formGroup.controls['child_footprint'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Pallet high', false, false),
    child_stack_height: new FieldModel(new NumberBoxModel(this.formGroup.controls['child_stack_height'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Pallet tie', false, false),
    mixing_restrictions: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['mixing_restrictions'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Mixing restrictions', false, false),
    printer_alias: new FieldModel(new TextBoxModel(this.formGroup.controls['printer_alias'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Printer alias', false, false),
    scale: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['scale'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Scale', false, false),
    hospital_location: new FieldModel(new TextBoxModel(this.formGroup.controls['hospital_location'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Hospital location', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
  newGroup2: new FieldsetModel('Settings', false, true, false, false),
  newGroup3: new FieldsetModel('Details', false, true, false, false),
};


  //#region fields inParams
  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_volume_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    storage_category: this.fields.storage_category.control.valueChanges
    ,
    eligible_for_allocation: this.fields.eligible_for_allocation.control.valueChanges
    ,
    is_loose: this.fields.is_loose.control.valueChanges
    ,
    is_primary: this.fields.is_primary.control.valueChanges
    ,
    is_dynamic_primary_pick: this.fields.is_dynamic_primary_pick.control.valueChanges
    ,
    pack_verification: this.fields.pack_verification.control.valueChanges
    ,
    skip_cycle_count: this.fields.skip_cycle_count.control.valueChanges
    ,
    purge_added_inventory: this.fields.purge_added_inventory.control.valueChanges
    ,
    allow_inactive: this.fields.allow_inactive.control.valueChanges
    ,
    skip_count_back: this.fields.skip_count_back.control.valueChanges
    ,
    skip_validation_rules: this.fields.skip_validation_rules.control.valueChanges
    ,
    is_used_for_returns: this.fields.is_used_for_returns.control.valueChanges
    ,
    hazardous_material: this.fields.hazardous_material.control.valueChanges
    ,
    is_hospital_location: this.fields.is_hospital_location.control.valueChanges
    ,
    is_FDA_release_location: this.fields.is_FDA_release_location.control.valueChanges
    ,
    skip_recomendation: this.fields.skip_recomendation.control.valueChanges
    ,
    staging_location: this.fields.staging_location.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    weight_capacity: this.fields.weight_capacity.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    volume_capacity: this.fields.volume_capacity.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
    child_footprint: this.fields.child_footprint.control.valueChanges
    ,
    child_stack_height: this.fields.child_stack_height.control.valueChanges
    ,
    mixing_restrictions: this.fields.mixing_restrictions.control.valueChanges
    ,
    printer_alias: this.fields.printer_alias.control.valueChanges
    ,
    scale: this.fields.scale.control.valueChanges
    ,
    hospital_location: this.fields.hospital_location.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Location';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      locationId:  $editor.inParams.locationId 
    };

    const data = await this.datasources.app.custom_ds_location_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.storage_category.control as SelectBoxModel).reset($editor.entity.StorageCategoryId);
    (this.fields.eligible_for_allocation.control as CheckBoxModel).reset($editor.entity.EligibleForAllocation);
    (this.fields.is_loose.control as CheckBoxModel).reset($editor.entity.IsLoose);
    (this.fields.is_primary.control as CheckBoxModel).reset($editor.entity.IsPrimaryPick);
    (this.fields.is_dynamic_primary_pick.control as CheckBoxModel).reset($editor.entity.IsDynamicPrimaryPick);
    (this.fields.pack_verification.control as CheckBoxModel).reset($editor.entity.IsPackageVerification);
    (this.fields.skip_cycle_count.control as CheckBoxModel).reset($editor.entity.SkipCycleCount);
    (this.fields.purge_added_inventory.control as CheckBoxModel).reset($editor.entity.PurgeAddedInventory);
    (this.fields.allow_inactive.control as CheckBoxModel).reset($editor.entity.AllowInventoryIfNotActive);
    (this.fields.skip_count_back.control as CheckBoxModel).reset($editor.entity.SkipAfterPickingCountBack);
    (this.fields.skip_validation_rules.control as CheckBoxModel).reset($editor.entity.SkipValidationRules);
    (this.fields.is_used_for_returns.control as CheckBoxModel).reset($editor.entity.IsUsedForReturns);
    (this.fields.hazardous_material.control as CheckBoxModel).reset($editor.entity.HazardousMaterial);
    (this.fields.is_hospital_location.control as CheckBoxModel).reset($editor.entity.IsThisHospital);
    (this.fields.is_FDA_release_location.control as CheckBoxModel).reset($editor.entity.FDA_ReleasedLocation);
    (this.fields.skip_recomendation.control as CheckBoxModel).reset($editor.entity.SkipRecommendation);
    (this.fields.staging_location.control as CheckBoxModel).reset($editor.entity.StagingLocation);
    (this.fields.length.control as NumberBoxModel).reset($editor.entity.Length);
    (this.fields.width.control as NumberBoxModel).reset($editor.entity.Width);
    (this.fields.height.control as NumberBoxModel).reset($editor.entity.Height);
    (this.fields.dimension_uom.control as SelectBoxModel).reset($editor.entity.DimensionUomId);
    (this.fields.weight_capacity.control as NumberBoxModel).reset($editor.entity.WeightCapacity);
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUomId);
    (this.fields.volume_capacity.control as NumberBoxModel).reset($editor.entity.VolumeCapacity);
    (this.fields.volume_uom.control as SelectBoxModel).reset($editor.entity.VolumeUomId);
    (this.fields.child_footprint.control as NumberBoxModel).reset($editor.entity.ChildFootprint);
    (this.fields.child_stack_height.control as NumberBoxModel).reset($editor.entity.ChildStackHeight);
    (this.fields.mixing_restrictions.control as SelectBoxModel).reset(($editor.entity?.MixingRule?.Id ?? 0));
    (this.fields.printer_alias.control as TextBoxModel).reset($editor.entity.AttachedPrinter);
    (this.fields.hospital_location.control as TextBoxModel).reset($editor.entity.HospitalLocation);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .storage_category
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .eligible_for_allocation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_loose
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_primary
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_dynamic_primary_pick
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pack_verification
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_cycle_count
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .purge_added_inventory
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allow_inactive
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_count_back
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_validation_rules
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_used_for_returns
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .hazardous_material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_hospital_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_FDA_release_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_recomendation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .staging_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_capacity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_capacity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .child_footprint
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .child_stack_height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .mixing_restrictions
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .printer_alias
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .scale
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .hospital_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_confirm_clicked');
  
  if ($editor.fields.name.control.isChanged) {
      const locations = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
          warehouseId: $editor.entity.WarehouseId,
          name: $editor.fields.name.control.value
      })).result;
      if ($utils.isDefined(locations)) {
          throw new Error('Duplicate location found');
      }
  }
  
  // If the location is loose return back the loose licenseplate
  var licenseplateId;
  
  const looseLicenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({ locationId: $editor.inParams.locationId })).result;
  if ($utils.isDefined(looseLicenseplate)) {
      licenseplateId = looseLicenseplate[0].Id
  }
  
  // Construct payload with any values that have changed
  const payload: any = {};
  const licenseplatePayload: any = {};
  
  // General Properties
  if ($editor.fields.name.control.isChanged) {
      payload.Name = $editor.fields.name.control.value;
  
      if ($editor.entity.IsLoose == true) {
          licenseplatePayload.Name = $editor.fields.name.control.value
      }
  }
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
  
      if ($editor.entity.IsLoose == true) {
          licenseplatePayload.StatusId = $editor.fields.status.control.value
      }
  
      await createStatusChangeTask();
  }
  if ($editor.fields.eligible_for_allocation.control.isChanged) {
      payload.EligibleForAllocation = $editor.fields.eligible_for_allocation.control.value;
  }
  
  // Dimensions
  if ($editor.fields.length.control.isChanged) {
      payload.Length = $editor.fields.length.control.value;
  }
  if ($editor.fields.width.control.isChanged) {
      payload.Width = $editor.fields.width.control.value;
  }
  if ($editor.fields.height.control.isChanged) {
      payload.Height = $editor.fields.height.control.value;
  }
  if ($editor.fields.dimension_uom.control.isChanged) {
      payload.DimensionUomId = $editor.fields.dimension_uom.control.value;
  }
  // Weight
  if ($editor.fields.weight_capacity.control.isChanged) {
      payload.WeightCapacity = $editor.fields.weight_capacity.control.value;
  }
  if ($editor.fields.weight_uom.control.isChanged) {
      payload.WeightUomId = $editor.fields.weight_uom.control.value;
  }
  // Volume
  if ($editor.fields.volume_capacity.control.isChanged) {
      payload.VolumeCapacity = $editor.fields.volume_capacity.control.value;
  }
  if ($editor.fields.volume_uom.control.isChanged) {
      payload.VolumeUomId = $editor.fields.volume_uom.control.value;
  }
  //Pallet Dimensions
  if ($editor.fields.child_footprint.control.isChanged) {
      payload.ChildFootprint = $editor.fields.child_footprint.control.value;
  }
  if ($editor.fields.child_stack_height.control.isChanged) {
      payload.ChildStackHeight = $editor.fields.child_stack_height.control.value;
  }
  if ($editor.fields.mixing_restrictions.control.isChanged){
      payload.MixingRuleId = $editor.fields.mixing_restrictions.control.value;
  }
  if ($editor.fields.is_primary.control.isChanged){
      payload.IsPrimaryPick = $editor.fields.is_primary.control.value;
  }
  if ($editor.fields.skip_cycle_count.control.isChanged){
      payload.SkipCycleCount = $editor.fields.skip_cycle_count.control.value;
  }
  if ($editor.fields.pack_verification.control.isChanged){
      payload.IsPackageVerification = $editor.fields.pack_verification.control.value;
  }
  if ($editor.fields.printer_alias.control.isChanged){
      payload.AttachedPrinter = $editor.fields.printer_alias.control.value;
  }
  if ($editor.fields.purge_added_inventory.control.isChanged){
      payload.PurgeAddedInventory = $editor.fields.purge_added_inventory.control.value;
  }
  
  // Rules
  if ($editor.fields.allow_inactive.control.isChanged){
      payload.AllowInventoryIfNotActive = $editor.fields.allow_inactive.control.value;
  }
  if ($editor.fields.skip_count_back.control.isChanged){
      payload.SkipAfterPickingCountBack = $editor.fields.skip_count_back.control.value;
  }
  if ($editor.fields.skip_validation_rules.control.isChanged){
      payload.SkipValidationRules = $editor.fields.skip_validation_rules.control.value;
  }
  if ($editor.fields.storage_category.control.isChanged){
      payload.StorageCategoryId = $editor.fields.storage_category.control.value;
  }
  if ($editor.fields.is_used_for_returns.control.isChanged){
      payload.IsUsedForReturns = $editor.fields.is_used_for_returns.control.value;
  }
  if ($editor.fields.is_dynamic_primary_pick.control.isChanged){
      payload.IsDynamicPrimaryPick = $editor.fields.is_dynamic_primary_pick.control.value;
  }
  
  // UDFs
  if ($editor.fields.hazardous_material.control.isChanged){
      payload.HazardousMaterial = $editor.fields.hazardous_material.control.value;
  }
  if ($editor.fields.is_hospital_location.control.isChanged){
      payload.IsThisHospital = $editor.fields.is_hospital_location.control.value;
  }
  if ($editor.fields.is_FDA_release_location.control.isChanged){
      payload.FDA_ReleasedLocation = $editor.fields.is_FDA_release_location.control.value;
  }
  if ($editor.fields.skip_recomendation.control.isChanged){
      payload.SkipRecommendation = $editor.fields.skip_recomendation.control.value;
  }
  if ($editor.fields.staging_location.control.isChanged){
      payload.StagingLocation = $editor.fields.staging_location.control.value;
  }
  if ($editor.fields.hospital_location.control.isChanged){
      payload.HospitalLocation = $editor.fields.hospital_location.control.value;
  }
  
  if ($editor.fields.scale.control.isChanged) {
      $flows.PrintNode.set_device_associations_by_type_flow({ 
          entity_id: $editor.entity.Id.toString(), 
          entity_type: $types.PrintNode.EEntityTypes.Location, 
          device_type: $types.PrintNode.EDeviceTypes.Scale,
          device_ids: $utils.isDefined($editor.fields.scale.control.value) ? [$editor.fields.scale.control.value] : null
      });
  }
  
  // Make API call
  try {
      (await $flows.Utilities.crud_update_flow({
          entitySet: 'LocationContainers',
          id: $editor.entity.Id,
          entity: payload
      }));
  
      if ($utils.isAllDefined(licenseplatePayload, licenseplateId)) {
          (await $flows.Utilities.crud_update_flow({
              entitySet: 'LicensePlates',
              id: licenseplateId,
              entity: licenseplatePayload
          }));
      }
  
      // If the loose location flag is not changed then close the editor
      if (!$editor.fields.is_loose.control.isChanged) {
          $editor.outParams.confirm = true;
  
          $editor.close();
  
      }
  }
  catch (error) {
      $shell.Locations.showErrorDetails('Save', 'Error on save.', error);
      throw error; // To prevent display mode
  }
  
  
  //checkbox isLoose
  if ($editor.fields.is_loose.control.isChanged && $editor.fields.is_loose.control.value == false) //change to non-loose (inventory) location 
  {
      let isConfirmedNonLoose: boolean;
      isConfirmedNonLoose = (await $shell.Locations.openConfirmationDialog('Change location to store licenseplates.', 'Are you sure you want to change the location to non loose? Existing loose licenseplate will be converted to a standard licenseplate.', 'Confirm', 'Cancel'));
  
      if (isConfirmedNonLoose) {
          const payload: any = {};
          const licenseplatePayload: any = {};
  
          payload.IsLoose = false;
          licenseplatePayload.TypeId = 1; //LP type = inventory (1)
  
  
  
  
          try {
              (await $flows.Utilities.crud_update_flow({
                  entitySet: 'LocationContainers',
                  id: $editor.entity.Id,
                  entity: payload
              }));
  
              if ($utils.isDefined(licenseplateId)) {
                  (await $flows.Utilities.crud_update_flow({
                      entitySet: 'LicensePlates',
                      id: licenseplateId,
                      entity: licenseplatePayload
                  }));
              }
  
  
              $editor.outParams.confirm = true;
              $editor.close();
          }
          catch (error) {
              $shell.Locations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // To prevent display mode
          }
      }
      else {
  
          $editor.refresh();
      }
  }
  else if ($editor.fields.is_loose.control.isChanged && $editor.fields.is_loose.control.value == true) //change to loose location
  {
      let isConfirmedLoose: boolean;
      isConfirmedLoose = (await $shell.Locations.openConfirmationDialog('Change location to loose.', 'Are you sure you want to change the location to loose?', 'Confirm', 'Cancel'));
  
      if (isConfirmedLoose) {
  
  
          // Return the current count of licenseplates in the given location
          let lpCountInLocation = (await $datasources.Locations.ds_get_licenseplates_count_by_locationId.get({ locationId: $editor.inParams.locationId })).result;
  
          //only 1 active licenseplate in location
          if ($utils.isDefined(lpCountInLocation) && lpCountInLocation.licensePlateCount == 1) {
              const payload: any = {};
              const licenseplatePayload: any = {};
  
              payload.IsLoose = true;
  
              licenseplatePayload.TypeId = 3; //LP type = loose (3) 
              licenseplatePayload.LookupCode = $editor.entity.Name;
              licenseplatePayload.Archived = false;
  
  
              (await $flows.Utilities.crud_update_flow({
                  entitySet: 'LocationContainers',
                  id: $editor.entity.Id,
                  entity: payload
              }));
  
              const locationLicenseplate = (await $datasources.Locations.ds_get_licenseplates_by_locationId.get({ locationId: $editor.inParams.locationId })).result;
              if ($utils.isDefined(locationLicenseplate)) {
  
                  const locationLicenseplateId = locationLicenseplate[0].Id;
  
                  (await $flows.Utilities.crud_update_flow({
                      entitySet: 'LicensePlates',
                      id: locationLicenseplateId,
                      entity: licenseplatePayload
                  }));
  
              }
  
              $editor.outParams.confirm = true;
              $editor.close();
  
          }
          //no active licenseplate in location  
          else if ((!$utils.isDefined(lpCountInLocation))) {
  
              const payload: any = {};
  
              payload.IsLoose = true;
  
  
              (await $flows.Utilities.crud_update_flow({
                  entitySet: 'LocationContainers',
                  id: $editor.entity.Id,
                  entity: payload
              }));
  
              //create licensePlate in location if no active LP exists
              const nextLicensePlateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
              const licensePlatePayload = {
                  "Id": nextLicensePlateId,
                  "WarehouseId": $editor.entity.WarehouseId,
                  "LocationId": $editor.entity.Id,
                  "TypeId": 3,
                  "StatusId": 1,
                  "LookupCode": $editor.fields.name.control.value,
                  "Archived": false
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'LicensePlates', entity: licensePlatePayload });
  
  
              $editor.outParams.confirm = true;
              $editor.close();
  
  
          }
          //more than 1 active LP in location
          else if ($utils.isDefined(lpCountInLocation) || lpCountInLocation.licensePlateCount > 1) {
              await $shell.Locations.openErrorDialog('Error', 'Location currently contains more than 1 licenseplate and cannot be converted to a loose location.')
          }
  
      }
      else {
  
          $editor.refresh();
      }
  }
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  async function createStatusChangeTask() {
      const currentStatus = (await $datasources.Locations.ds_get_status_by_statusId.get({ statusId: $editor.entity.StatusId })).result;
      var currentStatusName = currentStatus.Name;
  
      const updatedStatus = (await $datasources.Locations.ds_get_status_by_statusId.get({ statusId: $editor.fields.status.control.value })).result;
      var updatedStatusName = updatedStatus.Name;
  
      const nextTaskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
      const employee = (await $flows.Utilities.get_username_flow({})).userName;
  
      const taskPayload = {
          "Id": nextTaskId,
          "ChainHead": 0,
          "StatusId": 2,
          "WarehouseId": $editor.entity.WarehouseId,
          "OperationCodeId": 34,
          "ProjectId": null,
          "MaterialId": null,
          "LotId": null,
          "VendorLotId": null,
          "Employee": employee,
          "CreatedSysDateTime": $utils.date.now(),
          "CompletedDateTime": $utils.date.now(),
          "ModifiedSysDateTime": $utils.date.now(),
          "Notes": `Status changed from [${currentStatusName}] to [${updatedStatusName}] on [${$utils.date.format($utils.date.now(), $settings.FootPrintManager.DateFormat + ', LT')}]`,
          "ActualSourceLocationId": $editor.entity.Id,
          "ActualTargetLocationId": $editor.entity.Id,
          "StartDateTime": $utils.date.now()
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayload });
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_cancel_clicked');
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_init');
  $editor.outParams.confirm = false;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_location_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_location_editor.on_data_loaded');
  async function getScale() {
      $editor.fields.scale.hidden = true;
      let usePrintNode = false;
      try { 
          await $flows.PrintNode.validate_credentials_flow({});
          usePrintNode = true;
      } catch (error) { }
  
      if (usePrintNode) {
          $editor.fields.scale.hidden = false;
          $editor.fields.scale.label = 'PrintNode scale';
  
          const result = (await $datasources.PrintNode.ds_get_scales.getList({ entity: { type: 'location', id: $editor.entity.Id.toString()}})).result;
  
          $editor.fields.scale.control.value = result[0]?.id;
      }
  }
  }
  //#endregion private flows
  
}
