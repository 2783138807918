import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { LocusRobotics_measurement_units_dd_singleComponent } from './LocusRobotics.measurement_units_dd_single.component'
import { LocusRobotics_warehouses_dd_singleComponent } from './LocusRobotics.warehouses_dd_single.component'
import { LocusRobotics_bot_type_dd_singleComponent } from './LocusRobotics.bot_type_dd_single.component'


interface ILocusRobotics_bot_configuration_gridComponentEntity {
  Lookup?: string, weight_capacity?: number, height?: number, width?: number, length?: number, warehouse?: string, type?: string, bin_capacity?: number, dimension_uom?: string, volume_capacity?: number, weight_uom?: string}



class LocusRobotics_bot_configuration_gridComponentRowModel extends GridRowModel {
  grid: LocusRobotics_bot_configuration_gridComponent;
  entity: ILocusRobotics_bot_configuration_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    Lookup_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    type_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    bin_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    Lookup: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['Lookup_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    weight_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['weight_capacity_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['weight_uom_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['height_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    width: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['width_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['length_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    dimension_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['dimension_uom_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['warehouse_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    bin_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['bin_capacity_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    volume_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['volume_capacity_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: LocusRobotics_bot_configuration_gridComponent, entity: ILocusRobotics_bot_configuration_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Lookup].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: LocusRobotics_bot_configuration_gridComponent, entity?: ILocusRobotics_bot_configuration_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Lookup].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Lookup;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  null ,
    };
    const data = await this.datasources.LocusRobotics.ds_bot_configuration_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as ILocusRobotics_bot_configuration_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Lookup.displayControl as TextModel).text = $row.entity.Lookup;
    (this.cells.Lookup.editControl as TextBoxModel).reset($row.entity?.Lookup);
    (this.cells.weight_capacity.displayControl as TextModel).text = $row.entity.weight_capacity?.toString();;
    (this.cells.weight_capacity.editControl as NumberBoxModel).reset($row.entity.weight_capacity);
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity?.weight_uom;
    (this.cells.weight_uom.editControl as SelectBoxModel).reset($row.cells.weight_uom.editControl.value);
    (this.cells.height.displayControl as TextModel).text = $row.entity.height?.toString();
    (this.cells.height.editControl as NumberBoxModel).reset($row.entity.height);
    (this.cells.width.displayControl as TextModel).text = $row.entity.width?.toString();
    (this.cells.width.editControl as NumberBoxModel).reset($row.entity.width);
    (this.cells.length.displayControl as TextModel).text = $row.entity.length?.toString();
    (this.cells.length.editControl as NumberBoxModel).reset($row.entity.length);
    (this.cells.dimension_uom.displayControl as TextModel).text = $row.entity?.dimension_uom;
    (this.cells.dimension_uom.editControl as SelectBoxModel).reset($row.cells.dimension_uom.editControl.value);
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity?.warehouse;
    (this.cells.warehouse.editControl as SelectBoxModel).reset($row.entity?.warehouse);
    (this.cells.type.displayControl as TextModel).text = $row.entity?.type;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity?.type);
    (this.cells.bin_capacity.displayControl as TextModel).text = $row.entity.bin_capacity?.toString();
    (this.cells.bin_capacity.editControl as NumberBoxModel).reset($row.entity.bin_capacity);
    (this.cells.volume_capacity.displayControl as TextModel).text = $row.entity.volume_capacity?.toString();
    (this.cells.volume_capacity.editControl as NumberBoxModel).reset($row.entity.volume_capacity);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: LocusRobotics_bot_configuration_gridComponentRowModel,
  $grid: LocusRobotics_bot_configuration_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  let lookup = $row.cells.Lookup.editControl.value;
  let weight = $row.cells.weight_capacity.editControl.value;
  let warehouse = $row.cells.warehouse.editControl.value;
  let weightUOM = $row.cells.weight_uom.editControl.value;
  let binLength = $row.cells.length.editControl.value;
  let width = $row.cells.width.editControl.value;
  let height = $row.cells.height.editControl.value;
  let dimensionsUom = $row.cells.dimension_uom.editControl.value;
  let type = $row.cells.type.editControl.value;
  let binCapacity = $row.cells.bin_capacity.editControl.value;
  let volumeCapacity = $row.cells.volume_capacity.editControl.value;
  
  if ($utils.isDefined(lookup)) {
  
      try {
  
          await $flows.LocusRobotics.insert_bot_configuration_flow({
              lookup: lookup,
              weight_capacity: weight,
              weight_uom: weightUOM,
              dimension_uom: dimensionsUom,
              height: height,
              length: binLength,
              width: width,
              warehouse: warehouse,
              type: type,
              bin_capacity: binCapacity,
              volume_capacity: volumeCapacity
          })
  
  
          $grid.refresh();
      }
  
  
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  else {
      $shell.LocusRobotics.openErrorDialog('Bot configuration', 'Please enter in data into all fields.')
      throw 'Please enter in data into all fields.';
  }
  
  
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: LocusRobotics_bot_configuration_gridComponentRowModel,
  $grid: LocusRobotics_bot_configuration_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  var lookup = $row.entity.Lookup
  
  if ($row.cells.Lookup.editControl.isChanged) {
      lookup = $row.cells.Lookup.editControl.value;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup })
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  
  if ($row.cells.weight_capacity.editControl.isChanged) {
      var weight = $row.cells.weight_capacity.editControl.value;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, weightCapacity: weight })
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  
  if ($row.cells.warehouse.editControl.isChanged) {
      var warehouse = $row.cells.warehouse.editControl.value;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, warehouse: warehouse })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.weight_uom.editControl.isChanged) {
      var weightUOM = $row.cells.weight_uom.editControl.displayText;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, weightUOM: weightUOM })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.length.editControl.isChanged) {
      var binLength = Number($row.cells.length.editControl.value);
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, length: binLength })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.width.editControl.isChanged) {
      var width = Number($row.cells.width.editControl.value);
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, width: width })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.height.editControl.isChanged) {
      var height = Number($row.cells.height.editControl.value);
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, height: height })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.dimension_uom.editControl.isChanged) {
      var dimensionsUom = $row.cells.dimension_uom.editControl.displayText;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, dimensionUOM: dimensionsUom })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.type.editControl.isChanged) {
      var type = $row.cells.type.editControl.value;
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, type: type })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.bin_capacity.editControl.isChanged) {
      var binCapacity = Number($row.cells.bin_capacity.editControl.value);
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, binCapacity: binCapacity })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  if ($row.cells.volume_capacity.editControl.isChanged) {
      var volumeCapacity = Number($row.cells.volume_capacity.editControl.value);
      try {
          await $flows.LocusRobotics.update_bot_configuration_flow({ Lookup: lookup, volumeCapacity: volumeCapacity })
  
      }
      catch (error) {
          $shell.LocusRobotics.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  
  
  
  $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_measurement_units_dd_singleComponent),
    forwardRef(() => LocusRobotics_warehouses_dd_singleComponent),
    forwardRef(() => LocusRobotics_bot_type_dd_singleComponent),
  ],
  selector: 'LocusRobotics-bot_configuration_grid',
  templateUrl: './LocusRobotics.bot_configuration_grid.component.html'
})
export class LocusRobotics_bot_configuration_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: ILocusRobotics_bot_configuration_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Lookup: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup', 'Cart', false, false, null, false),       weight_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'weight_capacity', 'Weight capacity', false, false, null, false),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'weight_uom', 'Weight UOM', false, false, null, false),       height: new GridHeaderModel(new HeaderStyles(null, null), 'height', 'Height', false, false, null, false),       width: new GridHeaderModel(new HeaderStyles(null, null), 'width', 'Width', false, false, null, false),       length: new GridHeaderModel(new HeaderStyles(null, null), 'length', 'Length', false, false, null, false),       dimension_uom: new GridHeaderModel(new HeaderStyles(null, null), 'dimension_uom', 'Dimension UOM', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       type: new GridHeaderModel(new HeaderStyles(null, null), 'type', 'Type', false, false, null, false),       bin_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'bin_capacity', 'Bin capacity', false, false, null, false),       volume_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'volume_capacity', 'Volume capacity', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: LocusRobotics_bot_configuration_gridComponentRowModel[] = [];


  topToolbar = {
      delete_button: new ToolModel(new ButtonModel('delete_button', new ButtonStyles(null, null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: LocusRobotics_ShellService,
    private datasources: LocusRobotics_DatasourceService,
    private flows: LocusRobotics_FlowService,
    private reports: LocusRobotics_ReportService,
    private localization: LocusRobotics_LocalizationService,
    private operations: LocusRobotics_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Cart configuration';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  null ,
    };
    try {
      const data = await this.datasources.LocusRobotics.ds_bot_configuration_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as ILocusRobotics_bot_configuration_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new LocusRobotics_bot_configuration_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: LocusRobotics_bot_configuration_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  async addRow(entity?: ILocusRobotics_bot_configuration_gridComponentEntity) {
    const row = new LocusRobotics_bot_configuration_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: LocusRobotics_bot_configuration_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  for(let row of $grid.selectedRows){
      await $flows.LocusRobotics.delete_bot_configuration({lookup: row.entity.Lookup})
  }
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
