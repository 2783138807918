<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="update" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_update_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="check_in" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_check_in_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_assign_door" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_assign_door_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_mark_in_process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_mark_in_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="complete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_delete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="link_appointment" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_link_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator2" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="submit_to_tms" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_submit_to_tms_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attachments" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="surveys" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_surveys_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}} {{fields.lookupcode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupcode.control.readOnly || fields.lookupcode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-type" *ngIf="!fields.type.hidden && !fields.type.removed" 
                            class="field-container standard {{fields.type.invalid ? 'invalid' : ''}} {{fields.type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.type.styles.style"
                            [ngClass]="fields.type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.type.label + (fields.type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.type.label}}<span *ngIf="fields.type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-dock_appointment_types_dd_single 
                            data-cy="selector"
                            [type]="fields.type.control.type"
                            formControlName="type"
                            (displayTextChange)="fields.type.control.displayText=$event"
                            [placeholder]="fields.type.control.placeholder"
                            [styles]="fields.type.control.styles"
                            [tooltip]="fields.type.control.tooltip"
                          [option]="$fields_type_selector_inParams_option"
                        >
                        </DockAppointments-dock_appointment_types_dd_single>
                        <ng-container *ngIf="fields.type.invalid">
                          <ng-container *ngFor="let error of fields.type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-dock_appointment_statuses_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                            [tooltip]="fields.status.control.tooltip"
                        >
                        </DockAppointments-dock_appointment_statuses_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dockDoor" *ngIf="!fields.dockDoor.hidden && !fields.dockDoor.removed" 
                            class="field-container standard {{fields.dockDoor.invalid ? 'invalid' : ''}} {{fields.dockDoor.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dockDoor.styles.style"
                            [ngClass]="fields.dockDoor.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dockDoor.label + (fields.dockDoor.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dockDoor.label}}<span *ngIf="fields.dockDoor.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-dock_doors_dd_single 
                            data-cy="selector"
                            [type]="fields.dockDoor.control.type"
                            formControlName="dockDoor"
                            (displayTextChange)="fields.dockDoor.control.displayText=$event"
                            [placeholder]="fields.dockDoor.control.placeholder"
                            [styles]="fields.dockDoor.control.styles"
                            [tooltip]="fields.dockDoor.control.tooltip"
                          [warehouseIds]="$fields_dockDoor_selector_inParams_warehouseIds"
                          [appointmentType]="$fields_dockDoor_selector_inParams_appointmentType"
                        >
                        </DockAppointments-dock_doors_dd_single>
                        <ng-container *ngIf="fields.dockDoor.invalid">
                          <ng-container *ngFor="let error of fields.dockDoor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scheduled_arrival" *ngIf="!fields.scheduled_arrival.hidden && !fields.scheduled_arrival.removed" 
                            class="field-container standard {{fields.scheduled_arrival.invalid ? 'invalid' : ''}} {{fields.scheduled_arrival.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scheduled_arrival.styles.style"
                            [ngClass]="fields.scheduled_arrival.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scheduled_arrival.label + (fields.scheduled_arrival.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scheduled_arrival.label}}<span *ngIf="fields.scheduled_arrival.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="scheduled_arrival"
                            [format]="fields.scheduled_arrival.control.format"
                            [mode]="fields.scheduled_arrival.control.mode"
                            [ngStyle]="fields.scheduled_arrival.control.styles.style"
                            [ngClass]="fields.scheduled_arrival.control.styles.classes"
                            [tooltip]="fields.scheduled_arrival.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.scheduled_arrival.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_arrival.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scheduled_departure" *ngIf="!fields.scheduled_departure.hidden && !fields.scheduled_departure.removed" 
                            class="field-container standard {{fields.scheduled_departure.invalid ? 'invalid' : ''}} {{fields.scheduled_departure.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scheduled_departure.styles.style"
                            [ngClass]="fields.scheduled_departure.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scheduled_departure.label + (fields.scheduled_departure.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scheduled_departure.label}}<span *ngIf="fields.scheduled_departure.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="scheduled_departure"
                            [format]="fields.scheduled_departure.control.format"
                            [mode]="fields.scheduled_departure.control.mode"
                            [ngStyle]="fields.scheduled_departure.control.styles.style"
                            [ngClass]="fields.scheduled_departure.control.styles.classes"
                            [tooltip]="fields.scheduled_departure.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.scheduled_departure.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_departure.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference_number" *ngIf="!fields.reference_number.hidden && !fields.reference_number.removed" 
                            class="field-container standard {{fields.reference_number.invalid ? 'invalid' : ''}} {{fields.reference_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reference_number.styles.style"
                            [ngClass]="fields.reference_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reference_number.label + (fields.reference_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reference_number.label}}<span *ngIf="fields.reference_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="reference_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.reference_number.control.readOnly || fields.reference_number.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference_number.control.placeholder}}"
                                [ngStyle]="fields.reference_number.control.styles.style"
                                [ngClass]="fields.reference_number.control.styles.classes"
                                [matTooltip]="fields.reference_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.reference_number.invalid">
                          <ng-container *ngFor="let error of fields.reference_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden && !fields.order.removed" 
                            class="field-container double {{fields.order.invalid ? 'invalid' : ''}} {{fields.order.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order.label + (fields.order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order.label}}<span *ngIf="fields.order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-orders_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.order.control.type"
                            formControlName="order"
                            (displayTextChange)="fields.order.control.displayText=$event"
                            [placeholder]="fields.order.control.placeholder"
                            [styles]="fields.order.control.styles"
                            [tooltip]="fields.order.control.tooltip"
                          [projectId]="$fields_order_selector_inParams_projectId"
                          [warehouseId]="$fields_order_selector_inParams_warehouseId"
                          [typeId]="$fields_order_selector_inParams_typeId"
                        >
                        </DockAppointments-orders_dd_multi>
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden && !fields.carrier.removed" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}} {{fields.carrier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier.label + (fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier.label}}<span *ngIf="fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                            [tooltip]="fields.carrier.control.tooltip"
                        >
                        </Carriers-carriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_pallets" *ngIf="!fields.estimated_pallets.hidden && !fields.estimated_pallets.removed" 
                            class="field-container standard {{fields.estimated_pallets.invalid ? 'invalid' : ''}} {{fields.estimated_pallets.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.estimated_pallets.styles.style"
                            [ngClass]="fields.estimated_pallets.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.estimated_pallets.label + (fields.estimated_pallets.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.estimated_pallets.label}}<span *ngIf="fields.estimated_pallets.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="estimated_pallets"
                                matInput
                                numberBox
                                [format]="fields.estimated_pallets.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.estimated_pallets.control.readOnly || fields.estimated_pallets.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.estimated_pallets.control.placeholder}}"
                                [ngStyle]="fields.estimated_pallets.control.styles.style"
                                [ngClass]="fields.estimated_pallets.control.styles.classes"
                                [matTooltip]="fields.estimated_pallets.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.estimated_pallets.invalid">
                          <ng-container *ngFor="let error of fields.estimated_pallets.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_quantity" *ngIf="!fields.estimated_quantity.hidden && !fields.estimated_quantity.removed" 
                            class="field-container standard {{fields.estimated_quantity.invalid ? 'invalid' : ''}} {{fields.estimated_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.estimated_quantity.styles.style"
                            [ngClass]="fields.estimated_quantity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.estimated_quantity.label + (fields.estimated_quantity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.estimated_quantity.label}}<span *ngIf="fields.estimated_quantity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="estimated_quantity"
                                matInput
                                numberBox
                                [format]="fields.estimated_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.estimated_quantity.control.readOnly || fields.estimated_quantity.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.estimated_quantity.control.placeholder}}"
                                [ngStyle]="fields.estimated_quantity.control.styles.style"
                                [ngClass]="fields.estimated_quantity.control.styles.classes"
                                [matTooltip]="fields.estimated_quantity.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.estimated_quantity.invalid">
                          <ng-container *ngFor="let error of fields.estimated_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-load_configuration" *ngIf="!fields.load_configuration.hidden && !fields.load_configuration.removed" 
                            class="field-container standard {{fields.load_configuration.invalid ? 'invalid' : ''}} {{fields.load_configuration.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.load_configuration.styles.style"
                            [ngClass]="fields.load_configuration.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.load_configuration.label + (fields.load_configuration.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.load_configuration.label}}<span *ngIf="fields.load_configuration.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="load_configuration"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.load_configuration.control.readOnly || fields.load_configuration.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.load_configuration.control.placeholder}}"
                                [ngStyle]="fields.load_configuration.control.styles.style"
                                [ngClass]="fields.load_configuration.control.styles.classes"
                                [matTooltip]="fields.load_configuration.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.load_configuration.invalid">
                          <ng-container *ngFor="let error of fields.load_configuration.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer_number" *ngIf="!fields.trailer_number.hidden && !fields.trailer_number.removed" 
                            class="field-container standard {{fields.trailer_number.invalid ? 'invalid' : ''}} {{fields.trailer_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.trailer_number.styles.style"
                            [ngClass]="fields.trailer_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.trailer_number.label + (fields.trailer_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.trailer_number.label}}<span *ngIf="fields.trailer_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="trailer_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.trailer_number.control.readOnly || fields.trailer_number.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer_number.control.placeholder}}"
                                [ngStyle]="fields.trailer_number.control.styles.style"
                                [ngClass]="fields.trailer_number.control.styles.classes"
                                [matTooltip]="fields.trailer_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.trailer_number.invalid">
                          <ng-container *ngFor="let error of fields.trailer_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-full_trailer_temp" *ngIf="!fields.full_trailer_temp.hidden && !fields.full_trailer_temp.removed" 
                            class="field-container standard {{fields.full_trailer_temp.invalid ? 'invalid' : ''}} {{fields.full_trailer_temp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.full_trailer_temp.styles.style"
                            [ngClass]="fields.full_trailer_temp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.full_trailer_temp.label + (fields.full_trailer_temp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.full_trailer_temp.label}}<span *ngIf="fields.full_trailer_temp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="full_trailer_temp"
                                matInput
                                numberBox
                                [format]="fields.full_trailer_temp.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.full_trailer_temp.control.readOnly || fields.full_trailer_temp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.full_trailer_temp.control.placeholder}}"
                                [ngStyle]="fields.full_trailer_temp.control.styles.style"
                                [ngClass]="fields.full_trailer_temp.control.styles.classes"
                                [matTooltip]="fields.full_trailer_temp.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.full_trailer_temp.invalid">
                          <ng-container *ngFor="let error of fields.full_trailer_temp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-empty_trailer_temp" *ngIf="!fields.empty_trailer_temp.hidden && !fields.empty_trailer_temp.removed" 
                            class="field-container standard {{fields.empty_trailer_temp.invalid ? 'invalid' : ''}} {{fields.empty_trailer_temp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.empty_trailer_temp.styles.style"
                            [ngClass]="fields.empty_trailer_temp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.empty_trailer_temp.label + (fields.empty_trailer_temp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.empty_trailer_temp.label}}<span *ngIf="fields.empty_trailer_temp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="empty_trailer_temp"
                                matInput
                                numberBox
                                [format]="fields.empty_trailer_temp.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.empty_trailer_temp.control.readOnly || fields.empty_trailer_temp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.empty_trailer_temp.control.placeholder}}"
                                [ngStyle]="fields.empty_trailer_temp.control.styles.style"
                                [ngClass]="fields.empty_trailer_temp.control.styles.classes"
                                [matTooltip]="fields.empty_trailer_temp.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.empty_trailer_temp.invalid">
                          <ng-container *ngFor="let error of fields.empty_trailer_temp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-seal_number" *ngIf="!fields.seal_number.hidden && !fields.seal_number.removed" 
                            class="field-container standard {{fields.seal_number.invalid ? 'invalid' : ''}} {{fields.seal_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.seal_number.styles.style"
                            [ngClass]="fields.seal_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.seal_number.label + (fields.seal_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.seal_number.label}}<span *ngIf="fields.seal_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="seal_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.seal_number.control.readOnly || fields.seal_number.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.seal_number.control.placeholder}}"
                                [ngStyle]="fields.seal_number.control.styles.style"
                                [ngClass]="fields.seal_number.control.styles.classes"
                                [matTooltip]="fields.seal_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.seal_number.invalid">
                          <ng-container *ngFor="let error of fields.seal_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-linked_appointment" *ngIf="!fields.linked_appointment.hidden && !fields.linked_appointment.removed" 
                            class="field-container double {{fields.linked_appointment.invalid ? 'invalid' : ''}} {{fields.linked_appointment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.linked_appointment.styles.style"
                            [ngClass]="fields.linked_appointment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.linked_appointment.label + (fields.linked_appointment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.linked_appointment.label}}<span *ngIf="fields.linked_appointment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.linked_appointment.control.styles.style"
                              [ngClass]="fields.linked_appointment.control.styles.classes"
                              [matTooltip]="fields.linked_appointment.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.linked_appointment.control.text }}</div>
                        <ng-container *ngIf="fields.linked_appointment.invalid">
                          <ng-container *ngFor="let error of fields.linked_appointment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-transfer_appointment" *ngIf="!fields.transfer_appointment.hidden && !fields.transfer_appointment.removed" 
                            class="field-container standard {{fields.transfer_appointment.invalid ? 'invalid' : ''}} {{fields.transfer_appointment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.transfer_appointment.styles.style"
                            [ngClass]="fields.transfer_appointment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.transfer_appointment.label + (fields.transfer_appointment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.transfer_appointment.label}}<span *ngIf="fields.transfer_appointment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="transfer_appointment"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.transfer_appointment.control.styles.style"
                                      [ngClass]="fields.transfer_appointment.control.styles.classes"
                                      [matTooltip]="fields.transfer_appointment.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.transfer_appointment.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.transfer_appointment.invalid">
                          <ng-container *ngFor="let error of fields.transfer_appointment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-unlink_appointment" *ngIf="!fields.unlink_appointment.hidden && !fields.unlink_appointment.removed" 
                            class="field-container standard {{fields.unlink_appointment.invalid ? 'invalid' : ''}} {{fields.unlink_appointment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.unlink_appointment.styles.style"
                            [ngClass]="fields.unlink_appointment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.unlink_appointment.label + (fields.unlink_appointment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.unlink_appointment.label}}<span *ngIf="fields.unlink_appointment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="unlink_appointment"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.unlink_appointment.control.styles.style"
                                      [ngClass]="fields.unlink_appointment.control.styles.classes"
                                      [matTooltip]="fields.unlink_appointment.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.unlink_appointment.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.unlink_appointment.invalid">
                          <ng-container *ngFor="let error of fields.unlink_appointment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.activity.hasTabToShow" class="tab" data-cy="tab-activity">
              <h2 [className]="tabs.activity.active? 'active': ''" (click)="tabs.activity.activate()">{{tabs.activity.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-dock_appointment_activity_list *ngIf="tabs.activity.active"
              #$tabs_activity
              [dockAppointmentId]="$tabs_activity_dock_appointment_activity_list_inParams_dockAppointmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_activity')">
              </FootPrintManager-dock_appointment_activity_list>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>