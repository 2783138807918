import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Inspections_inspection_operation_codes_dd_singleComponent } from './Inspections.inspection_operation_codes_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Inspections_task_statuses_dd_singleComponent } from './Inspections.task_statuses_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'
import { Inspections_reason_codes_dd_singleComponent } from './Inspections.reason_codes_dd_single.component'

type EntityType = { 
    Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Id?: number, Name?: string }, Project?: { Id?: number, LookupCode?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inspections_inspection_operation_codes_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Inspections_task_statuses_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => Lots_lots_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
    forwardRef(() => Inspections_reason_codes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inspection_task_editor',
  templateUrl: './FootPrintManager.inspection_task_editor.component.html'
})
export class FootPrintManager_inspection_task_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { taskId: number } = { taskId: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    operation_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reason_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  fields = {
    operation_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['operation_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Operation', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', true, false),
    order: new FieldModel(new NumberBoxModel(this.formGroup.controls['order'] as DatexFormControl, null, true, false, '', '', null)
, new ControlContainerStyles(null, null), 'Order', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot', false, false),
    source_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source location', false, false),
    source_license_plate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_license_plate'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source license plate', false, false),
    reason_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Reason', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    created_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Created on', false, false),
    released_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Released on', false, false),
    started_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Started on', false, false),
    completed_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Completed on', false, false),
    cancelled_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Cancelled on', false, false),
    modified_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Modified on', false, false),
    modified_by: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Modified by', false, false),
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true, false),
};


  //#region fields inParams
  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.material.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.lot.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.vendor_lot.control.value;
    
    return expr;
  }

  get $fields_source_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_source_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_source_license_plate_selector_inParams_locationId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.source_location.control.value;
    
    return expr;
  }

  get $fields_source_license_plate_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_license_plate_selector_inParams_archived(): boolean {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    operation_code: this.fields.operation_code.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    warehouse: this.fields.warehouse.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    status: this.fields.status.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    order: this.fields.order.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    project: this.fields.project.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    material: this.fields.material.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    lot: this.fields.lot.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    source_location: this.fields.source_location.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    source_license_plate: this.fields.source_license_plate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    reason_code: this.fields.reason_code.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
    notes: this.fields.notes.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validate(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Inspection task';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      taskId:  $editor.inParams.taskId 
    };

    const data = await this.datasources.Inspections.ds_inspection_task_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.operation_code.control as SelectBoxModel).reset($editor.entity?.OperationCode?.Id);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity?.Warehouse?.Id);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity?.Status?.Id);
    (this.fields.order.control as NumberBoxModel).reset($editor.entity?.Order?.Id);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity?.Project?.Id);
    (this.fields.material.control as SelectBoxModel).reset($editor.entity?.Material?.Id);
    (this.fields.vendor_lot.control as SelectBoxModel).reset($editor.entity?.VendorLot?.Id);
    (this.fields.lot.control as SelectBoxModel).reset($editor.entity?.Lot?.Id);
    (this.fields.source_location.control as SelectBoxModel).reset($editor.entity?.ActualSourceLocation?.Id ?? $editor.entity?.ExpectedSourceLocation?.Id);
    (this.fields.source_license_plate.control as SelectBoxModel).reset($editor.entity?.ActualSourceLicensePlate?.Id ?? $editor.entity?.ExpectedSourceLicensePlate?.Id);
    (this.fields.reason_code.control as SelectBoxModel).reset($editor.entity?.ReasonCode?.Id);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity?.Notes);
    (this.fields.created_on.control as TextModel).text = $editor.entity?.CreatedSysDateTime;
    (this.fields.released_on.control as TextModel).text = $editor.entity?.ReleaseDateTime;
    (this.fields.started_on.control as TextModel).text = $editor.entity?.StartDateTime;
    (this.fields.completed_on.control as TextModel).text = $editor.entity?.CompletedDateTime;
    (this.fields.cancelled_on.control as TextModel).text = $editor.entity?.CancelledDateTime;
    (this.fields.modified_on.control as TextModel).text = $editor.entity?.ModifiedSysDateTime;
    (this.fields.modified_by.control as TextModel).text = $editor.entity?.ModifiedSysUser;

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .operation_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_changed();
      });
    this.$formGroupFieldValidationObservables
      .status
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_status_changed();
      });
    this.$formGroupFieldValidationObservables
      .order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendor_lot_changed();
      });
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .source_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_location_changed();
      });
    this.$formGroupFieldValidationObservables
      .source_license_plate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.title =  `Inspection task ${$editor.inParams.taskId}`
  }
  on_status_changed(event = null) {
    return this.on_status_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_status_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let dateFormat = `${$settings.FootPrintManager.DateFormat} ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '24 HOUR' ? 'HH:mm' : 'hh:mm a'}`;
  
  
  if ($utils.isDefinedTrimmed($editor.fields.created_on.control.text)) {
      $editor.fields.created_on.control.text = $utils.date.format($editor.fields.created_on.control.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($editor.fields.started_on.control.text)) {
      $editor.fields.started_on.control.text = $utils.date.format($editor.fields.started_on.control.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($editor.fields.modified_on.control.text)) {
      $editor.fields.modified_on.control.text = $utils.date.format($editor.fields.modified_on.control.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($editor.fields.released_on.control.text)) {
      $editor.fields.released_on.control.text = $utils.date.format($editor.fields.released_on.control.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($editor.fields.cancelled_on.control.text)) {
      $editor.fields.cancelled_on.control.text = $utils.date.format($editor.fields.cancelled_on.control.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($editor.fields.completed_on.control.text)) {
      $editor.fields.completed_on.control.text = $utils.date.format($editor.fields.completed_on.control.text, dateFormat);
  }
  
  
  switch ($editor.entity.Status.Id) {
      case 1: // Released
          $editor.fields.operation_code.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = false;
          $editor.fields.status.control.readOnly = false;
          $editor.fields.order.control.readOnly = false;
          $editor.fields.project.control.readOnly = false;
          $editor.fields.material.control.readOnly = false;
          $editor.fields.vendor_lot.control.readOnly = false;
          $editor.fields.lot.control.readOnly = false;
          $editor.fields.source_location.control.readOnly = false;
          $editor.fields.source_license_plate.control.readOnly = false;
          $editor.fields.reason_code.control.readOnly = false;
          $editor.fields.notes.control.readOnly = false;
          break;
      case 2: // Completed
          $editor.fields.operation_code.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.status.control.readOnly = true;
          $editor.fields.order.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.material.control.readOnly = true;
          $editor.fields.vendor_lot.control.readOnly = true;
          $editor.fields.lot.control.readOnly = true;
          $editor.fields.source_location.control.readOnly = true;
          $editor.fields.source_license_plate.control.readOnly = true;
          $editor.fields.reason_code.control.readOnly = true;
          $editor.fields.notes.control.readOnly = true;
          break;
      case 3: // Cancelled
          $editor.fields.operation_code.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.status.control.readOnly = true;
          $editor.fields.order.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.material.control.readOnly = true;
          $editor.fields.vendor_lot.control.readOnly = true;
          $editor.fields.lot.control.readOnly = true;
          $editor.fields.source_location.control.readOnly = true;
          $editor.fields.source_license_plate.control.readOnly = true;
          $editor.fields.reason_code.control.readOnly = true;
          $editor.fields.notes.control.readOnly = true;
          break;
      case 4: // Planned
          $editor.fields.operation_code.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = false;
          $editor.fields.status.control.readOnly = false;
          $editor.fields.order.control.readOnly = false;
          $editor.fields.project.control.readOnly = false;
          $editor.fields.material.control.readOnly = false;
          $editor.fields.vendor_lot.control.readOnly = false;
          $editor.fields.lot.control.readOnly = false;
          $editor.fields.source_location.control.readOnly = false;
          $editor.fields.source_license_plate.control.readOnly = false;
          $editor.fields.reason_code.control.readOnly = false;
          $editor.fields.notes.control.readOnly = false;
          break;
  }
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($editor.valid) {
      // Update task
      let payload: any = {};
  
      if ($editor.fields.warehouse.control.isChanged) {
          payload.WarehouseId = $editor.fields.warehouse.control.value;
      }
      if ($editor.fields.operation_code.control.isChanged) {
          payload.OperationCodeId = $editor.fields.operation_code.control.value;
      }
  
      if ($editor.fields.project.control.isChanged) {
          payload.ProjectId = $editor.fields.project.control.value;
      }
      if ($editor.fields.material.control.isChanged) {
          payload.MaterialId = $editor.fields.material.control.value;
      }
      if ($editor.fields.vendor_lot.control.isChanged) {
          payload.VendorLotId = $editor.fields.vendor_lot.control.value;
      }
      if ($editor.fields.lot.control.isChanged) {
          payload.LotId = $editor.fields.lot.control.value;
      }
  
      if ($editor.fields.source_location.control.isChanged) {
          payload.ExpectedSourceLocationId = $editor.fields.source_location.control.value;
      }
      if ($editor.fields.source_license_plate.control.isChanged) {
          payload.ExpectedSourceLicensePlateId = $editor.fields.source_license_plate.control.value;
      }
  
      if ($editor.fields.reason_code.control.isChanged) {
          payload.ReasonCodeId = $editor.fields.reason_code.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
  
      if (Object.keys(payload).length > 0) {
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: $editor.entity.Id,
              entity: payload
          });
      }
  
      if ($editor.fields.status.control.isChanged) {
          await $flows.Inspections.change_task_statuses_flow({ taskIds: [$editor.entity.Id], targetStatusId: $editor.fields.status.control.value });
      }
  
      $editor.close();
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.close()
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.material.control.value = null;
  $editor.fields.vendor_lot.control.value = null;
  $editor.fields.lot.control.value = null;
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.vendor_lot.control.value = null;
  $editor.fields.lot.control.value = null;
  }
  on_vendor_lot_changed(event = null) {
    return this.on_vendor_lot_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendor_lot_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.lot.control.value = null;
  }
  on_warehouse_changed(event = null) {
    return this.on_warehouse_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.source_location.control.value = null;
  $editor.fields.source_license_plate.control.value = null;
  }
  on_source_location_changed(event = null) {
    return this.on_source_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_location_changedInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.source_license_plate.control.value = null;
  }
  //#endregion private flows
  //#region validation flows
  
  on_form_validate(fieldErrors: { operation_code: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,status: Pick<string[], 'push'>,order: Pick<string[], 'push'>,project: Pick<string[], 'push'>,material: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,source_location: Pick<string[], 'push'>,source_license_plate: Pick<string[], 'push'>,reason_code: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, } = null) {
    const emptyResult = { operation_code:[],warehouse:[],status:[],order:[],project:[],material:[],vendor_lot:[],lot:[],source_location:[],source_license_plate:[],reason_code:[],notes:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_form_validateInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async on_form_validateInternal(
    $editor: FootPrintManager_inspection_task_editorComponent,
    $validation:{
      fieldErrors: { operation_code: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,status: Pick<string[], 'push'>,order: Pick<string[], 'push'>,project: Pick<string[], 'push'>,material: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,source_location: Pick<string[], 'push'>,source_license_plate: Pick<string[], 'push'>,reason_code: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, }
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    let hasAllRequiredFields = true;
  
  for (let key in $editor.fields) {
      if ($editor.fields[key].required && !$utils.isDefined($editor.fields[key]?.control?.value)) {
          hasAllRequiredFields = false;
      }
  }
  
  $editor.toolbar.confirm.control.readOnly = !hasAllRequiredFields;
    return $validation.fieldErrors as { operation_code:[],warehouse:[],status:[],order:[],project:[],material:[],vendor_lot:[],lot:[],source_location:[],source_license_plate:[],reason_code:[],notes:[], };
  }
  //#endregion validation flows
  
}
