import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_dock_appointment_by_appointmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { appointmentId: number }): 
  Promise<{ result: { Id?: number, AssignedBy?: string, AssignedOn?: string, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DriverLicense?: string, DriverName?: string, InProcesesBy?: string, InProcessOn?: string, linkedDockAppointmentId?: number, LookupCode?: string, ScheduledArrival?: string, ScheduledBy?: string, ScheduledDeparture?: string, ScheduledOn?: string, StatusId?: number, TypeId?: number, VehicleLicense?: string, WarehouseId?: number, AssignedLocation?: { Id?: number, Name?: string, ShortName?: string, WarehouseId?: number }, ScheduledLocation?: { Id?: number, Name?: string, ShortName?: string, WarehouseId?: number }, ScheduledProject?: { LookupCode?: string }, ScheduledCarrier?: { Name?: string }, CancelReasonCode?: { Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, convertedCancelledOn?: { id?: number, convertedDate?: string }, convertedScheduledOn?: { id?: number, convertedDate?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.appointmentId)) {
      missingRequiredInParams.push('\'appointmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_dock_appointment_by_appointmentId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      appointmentId: number    }) {
        if(isNil(inParams.appointmentId)) {
          return true; 
        }
      return false;
    }
}
