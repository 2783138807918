import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_label_by_shippingContainerIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerId: number }): 
  Promise<{ result: { Id?: number, ActualWeight?: number, LookupCode?: string, TrackingNumber?: string, Shipment?: { ContainerSize?: string, LookupCode?: string, TrackingIdentifier?: string, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }[], Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, LoadContainer?: { Id?: number, LookupCode?: string } }, ContainerType?: { Description?: string, Name?: string }, deliver_to?: { Id?: number, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Name?: string, PostalCode?: string, PrimaryFax?: string, PrimaryTelephone?: string, State?: string, Title?: string }, first7?: string, ord4char?: string } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_label_by_shippingContainerId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shippingContainerId: number    }) {
        if(isNil(inParams.shippingContainerId)) {
          return true; 
        }
      return false;
    }
}
