<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.custom_fields.readOnly"
                [ngStyle]="row.options.custom_fields.styles.style"
                [ngClass]="row.options.custom_fields.styles.classes"
                (click)="row.on_custom_fields_clicked()">
          <span *ngIf="row.options.custom_fields.label">{{row.options.custom_fields.label}}</span>
        </button>
      </div>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.cancel_line_button.readOnly"
                [ngStyle]="row.options.cancel_line_button.styles.style"
                [ngClass]="row.options.cancel_line_button.styles.classes"
                (click)="row.on_cancel_line_clicked()">
          <span *ngIf="row.options.cancel_line_button.label">{{row.options.cancel_line_button.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="add_by_inventory" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_add_by_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="select_inventory" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_select_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="cancel" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cancel($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.material.editControl.type"
        [formControl]="row.formGroup.controls['material_edit']"
        (displayTextChange)="row.cells.material.editControl.displayText=$event"
        [placeholder]="row.cells.material.editControl.placeholder"
        [styles]="row.cells.material.editControl.styles"
        [tooltip]="row.cells.material.editControl.tooltip"
      [projectId]="row.$fields_material_selector_inParams_projectId"
    >
    </Materials-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
        [tooltip]="row.cells.packaging.editControl.tooltip"
      [materialId]="row.$fields_packaging_selector_inParams_materialId"
    >
    </Materials-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['packaged_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.packaged_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.packaged_amount.editControl.readOnly || row.cells.packaged_amount.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.packaged_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.packaged_amount.editControl.styles.style"
            [ngClass]="row.cells.packaged_amount.editControl.styles.classes"
            [matTooltip]="row.cells.packaged_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available.displayControl.styles.style"
          [ngClass]="row.cells.available.displayControl.styles.classes"
          [matTooltip]="row.cells.available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="non_allocatable">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.non_allocatable.displayControl.styles.style"
          [ngClass]="row.cells.non_allocatable.displayControl.styles.classes"
          [matTooltip]="row.cells.non_allocatable.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.non_allocatable.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Lots-lots_dd_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
        [tooltip]="row.cells.lot.editControl.tooltip"
      [materialId]="row.$fields_lot_selector_inParams_materialId"
    >
    </Lots-lots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Lots-vendorlots_dd_single 
        data-cy="selector"
        [type]="row.cells.vendorlot.editControl.type"
        [formControl]="row.formGroup.controls['vendorlot_edit']"
        (displayTextChange)="row.cells.vendorlot.editControl.displayText=$event"
        [placeholder]="row.cells.vendorlot.editControl.placeholder"
        [styles]="row.cells.vendorlot.editControl.styles"
        [tooltip]="row.cells.vendorlot.editControl.tooltip"
      [materialId]="row.$fields_vendorlot_selector_inParams_materialId"
      [lotId]="row.$fields_vendorlot_selector_inParams_lotId"
    >
    </Lots-vendorlots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-licenseplate_by_inventory_dd_single 
        data-cy="selector"
        [type]="row.cells.licenseplate.editControl.type"
        [formControl]="row.formGroup.controls['licenseplate_edit']"
        (displayTextChange)="row.cells.licenseplate.editControl.displayText=$event"
        [placeholder]="row.cells.licenseplate.editControl.placeholder"
        [styles]="row.cells.licenseplate.editControl.styles"
        [tooltip]="row.cells.licenseplate.editControl.tooltip"
      [lotId]="row.$fields_licenseplate_selector_inParams_lotId"
      [packagingId]="row.$fields_licenseplate_selector_inParams_packagingId"
      [warehouseId]="row.$fields_licenseplate_selector_inParams_warehouseId"
    >
    </Inventory-licenseplate_by_inventory_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serialnumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serialnumber.displayControl.styles.style"
          [ngClass]="row.cells.serialnumber.displayControl.styles.classes"
          [matTooltip]="row.cells.serialnumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serialnumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-serialnumbers_by_inventory_dd_single 
        data-cy="selector"
        [type]="row.cells.serialnumber.editControl.type"
        [formControl]="row.formGroup.controls['serialnumber_edit']"
        (displayTextChange)="row.cells.serialnumber.editControl.displayText=$event"
        [placeholder]="row.cells.serialnumber.editControl.placeholder"
        [styles]="row.cells.serialnumber.editControl.styles"
        [tooltip]="row.cells.serialnumber.editControl.tooltip"
      [lotId]="row.$fields_serialnumber_selector_inParams_lotId"
      [packagingId]="row.$fields_serialnumber_selector_inParams_packagingId"
      [warehouseId]="row.$fields_serialnumber_selector_inParams_warehouseId"
      [licenseplateId]="row.$fields_serialnumber_selector_inParams_licenseplateId"
      [materialId]="row.$fields_serialnumber_selector_inParams_materialId"
    >
    </Inventory-serialnumbers_by_inventory_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_key">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.allocation_key.displayControl.styles.style"
          [ngClass]="row.cells.allocation_key.displayControl.styles.classes"
          [matTooltip]="row.cells.allocation_key.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.allocation_key.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['allocation_key_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.allocation_key.editControl.readOnly || row.cells.allocation_key.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.allocation_key.editControl.placeholder}}"
            [ngStyle]="row.cells.allocation_key.editControl.styles.style"
            [ngClass]="row.cells.allocation_key.editControl.styles.classes"
            [matTooltip]="row.cells.allocation_key.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot_status.displayControl.styles.style"
          [ngClass]="row.cells.lot_status.displayControl.styles.classes"
          [matTooltip]="row.cells.lot_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_string_lot_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.lot_status.editControl.type"
        [formControl]="row.formGroup.controls['lot_status_edit']"
        (displayTextChange)="row.cells.lot_status.editControl.displayText=$event"
        [placeholder]="row.cells.lot_status.editControl.placeholder"
        [styles]="row.cells.lot_status.editControl.styles"
        [tooltip]="row.cells.lot_status.editControl.tooltip"
    >
    </app-custom_string_lot_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate_status.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_status.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_string_licenseplate_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.licenseplate_status.editControl.type"
        [formControl]="row.formGroup.controls['licenseplate_status_edit']"
        (displayTextChange)="row.cells.licenseplate_status.editControl.displayText=$event"
        [placeholder]="row.cells.licenseplate_status.editControl.placeholder"
        [styles]="row.cells.licenseplate_status.editControl.styles"
        [tooltip]="row.cells.licenseplate_status.editControl.tooltip"
    >
    </app-custom_string_licenseplate_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.notes.editControl.readOnly || row.cells.notes.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"
            [matTooltip]="row.cells.notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
