import { Inject, Injectable, Injector } from '@angular/core';

import { BarcodeParsing_FlowService } from './BarcodeParsing.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';

import { LocusRobotics_check_for_replenishmentsService } from './LocusRobotics.flow.index';
import { LocusRobotics_confirm_and_build_buttonService } from './LocusRobotics.flow.index';
import { LocusRobotics_countback_validationService } from './LocusRobotics.flow.index';
import { LocusRobotics_create_packing_taskService } from './LocusRobotics.flow.index';
import { LocusRobotics_create_partial_manual_allocation_tasksService } from './LocusRobotics.flow.index';
import { LocusRobotics_create_standalone_count_tasksService } from './LocusRobotics.flow.index';
import { LocusRobotics_delete_bot_configurationService } from './LocusRobotics.flow.index';
import { LocusRobotics_delete_detail_infoService } from './LocusRobotics.flow.index';
import { LocusRobotics_delete_jobsService } from './LocusRobotics.flow.index';
import { LocusRobotics_delete_printer_configurationService } from './LocusRobotics.flow.index';
import { LocusRobotics_duplicate_order_checkService } from './LocusRobotics.flow.index';
import { LocusRobotics_generate_jobs_by_orderService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_authorization_tokenService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_bot_configurationsService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_cartonized_serials_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_friendly_order_statusService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_jobIds_by_orderIdService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_job_header_info_by_orderIdService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_job_header_info_by_project_warehouse_botService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_orderId_by_jobIdsService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_orderIds_already_in_locusService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_orderJob_by_cartService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_orders_to_queueService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_printersService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_printers_gridService } from './LocusRobotics.flow.index';
import { LocusRobotics_get_serial_numbers_by_jobIdService } from './LocusRobotics.flow.index';
import { LocusRobotics_insert_bot_configuration_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_insert_printer_configuration_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_inventoryCount_mainService } from './LocusRobotics.flow.index';
import { LocusRobotics_inventoryCount_requestService } from './LocusRobotics.flow.index';
import { LocusRobotics_inventoryCount_resultService } from './LocusRobotics.flow.index';
import { LocusRobotics_job_detail_gridService } from './LocusRobotics.flow.index';
import { LocusRobotics_job_prioritization_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_move_inventory_to_stagingService } from './LocusRobotics.flow.index';
import { LocusRobotics_orderJob_cancelService } from './LocusRobotics.flow.index';
import { LocusRobotics_orderJob_cancel_resultService } from './LocusRobotics.flow.index';
import { LocusRobotics_orderJob_requestService } from './LocusRobotics.flow.index';
import { LocusRobotics_orderJob_resultService } from './LocusRobotics.flow.index';
import { LocusRobotics_orderJob_statusService } from './LocusRobotics.flow.index';
import { LocusRobotics_parse_location_xml_stringService } from './LocusRobotics.flow.index';
import { LocusRobotics_pickJob_resultService } from './LocusRobotics.flow.index';
import { LocusRobotics_printJob_requestService } from './LocusRobotics.flow.index';
import { LocusRobotics_print_mainService } from './LocusRobotics.flow.index';
import { LocusRobotics_resubmit_to_locusService } from './LocusRobotics.flow.index';
import { LocusRobotics_serialValidation_requestService } from './LocusRobotics.flow.index';
import { LocusRobotics_split_order_job_by_weightService } from './LocusRobotics.flow.index';
import { LocusRobotics_toteInduct_requestService } from './LocusRobotics.flow.index';
import { LocusRobotics_update_bot_configuration_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_update_log_levelService } from './LocusRobotics.flow.index';
import { LocusRobotics_update_orderJobsService } from './LocusRobotics.flow.index';
import { LocusRobotics_update_pick_job_with_locationService } from './LocusRobotics.flow.index';
import { LocusRobotics_update_printer_configuration_flowService } from './LocusRobotics.flow.index';
import { LocusRobotics_validate_serialService } from './LocusRobotics.flow.index';
import { LocusRobotics_vector_splitService } from './LocusRobotics.flow.index';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.InventoryCounts = this.injector.get(InventoryCounts_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
  }

    public BarcodeParsing: BarcodeParsing_FlowService;
    public Utilities: Utilities_FlowService;
    public InventoryCounts: InventoryCounts_FlowService;
    public PrintNode: PrintNode_FlowService;
  public LocusRobotics: LocusRobotics_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _check_for_replenishments: LocusRobotics_check_for_replenishmentsService;
  public async check_for_replenishments(inParams: { orderId?: number }): Promise< { hasReplenishment?: boolean, reasons?: string[] }> {
    if(!this._check_for_replenishments) { 
      this._check_for_replenishments = this.injector.get(LocusRobotics_check_for_replenishmentsService);
    }
    return this._check_for_replenishments.run(inParams);
  }
   
   

   
 
  private _confirm_and_build_button: LocusRobotics_confirm_and_build_buttonService;
  public async confirm_and_build_button(inParams: { jobId?: string, readyToBuild?: boolean, locationString?: string }): Promise< { taskIds?: number[], locationString?: string, locationId?: number, currentCount?: number, totalCount?: number, licensePlateId?: number, orderId?: number, reasons?: string[] }> {
    if(!this._confirm_and_build_button) { 
      this._confirm_and_build_button = this.injector.get(LocusRobotics_confirm_and_build_buttonService);
    }
    return this._confirm_and_build_button.run(inParams);
  }
   
   

   
 
  private _countback_validation: LocusRobotics_countback_validationService;
  public async countback_validation(inParams: { countLocation?: string, orderId?: number, location?: number, jobId?: string, numberOfPickJobs?: number, uomId?: number }): Promise< { isValid?: boolean, reasons?: string[] }> {
    if(!this._countback_validation) { 
      this._countback_validation = this.injector.get(LocusRobotics_countback_validationService);
    }
    return this._countback_validation.run(inParams);
  }
   
   

   
 
  private _create_packing_task: LocusRobotics_create_packing_taskService;
  public async create_packing_task(inParams: { jobId?: string, targetLocationId?: number, targetLicensePlate?: string, taskIds?: number[], warehouseId?: number, locationString?: string }): Promise< { packingTaskId?: number, reasons?: string[], locationId?: number, warehouseId?: number }> {
    if(!this._create_packing_task) { 
      this._create_packing_task = this.injector.get(LocusRobotics_create_packing_taskService);
    }
    return this._create_packing_task.run(inParams);
  }
   
   

   
 
  private _create_partial_manual_allocation_tasks: LocusRobotics_create_partial_manual_allocation_tasksService;
  public async create_partial_manual_allocation_tasks(inParams: { taskId: number, task_qty: number, transactionId: string, jobId: string }): Promise< { manualAllocationTaskId?: number, reasons?: string[] }> {
    if(!this._create_partial_manual_allocation_tasks) { 
      this._create_partial_manual_allocation_tasks = this.injector.get(LocusRobotics_create_partial_manual_allocation_tasksService);
    }
    return this._create_partial_manual_allocation_tasks.run(inParams);
  }
   
   

   
 
  private _create_standalone_count_tasks: LocusRobotics_create_standalone_count_tasksService;
  public async create_standalone_count_tasks(inParams: { warehouseId?: number, lotId?: number, expectedAmount?: number, projectId?: number, actualAmount?: number, expectedPackageId?: number, actualPackageId?: number, locationId?: number, materialId?: number, statusId?: number, vendorLotId?: number, jobId?: string, countTaskId?: number, licenseplateId?: number }): Promise< { newTaskId?: number, reasons?: string[], amountsEqual?: boolean }> {
    if(!this._create_standalone_count_tasks) { 
      this._create_standalone_count_tasks = this.injector.get(LocusRobotics_create_standalone_count_tasksService);
    }
    return this._create_standalone_count_tasks.run(inParams);
  }
   
   

   
 
  private _delete_bot_configuration: LocusRobotics_delete_bot_configurationService;
  public async delete_bot_configuration(inParams: { lookup?: string }): Promise< { reasons?: string[] }> {
    if(!this._delete_bot_configuration) { 
      this._delete_bot_configuration = this.injector.get(LocusRobotics_delete_bot_configurationService);
    }
    return this._delete_bot_configuration.run(inParams);
  }
   
   

   
 
  private _delete_detail_info: LocusRobotics_delete_detail_infoService;
  public async delete_detail_info(inParams: { orderId?: string }): Promise<void> {
    if(!this._delete_detail_info) { 
      this._delete_detail_info = this.injector.get(LocusRobotics_delete_detail_infoService);
    }
    return this._delete_detail_info.run(inParams);
  }
   
   

   
 
  private _delete_jobs: LocusRobotics_delete_jobsService;
  public async delete_jobs(inParams: { Id: string }): Promise<void> {
    if(!this._delete_jobs) { 
      this._delete_jobs = this.injector.get(LocusRobotics_delete_jobsService);
    }
    return this._delete_jobs.run(inParams);
  }
   
   

   
 
  private _delete_printer_configuration: LocusRobotics_delete_printer_configurationService;
  public async delete_printer_configuration(inParams: { printerName?: string, botType?: string }): Promise< { reasons?: string[] }> {
    if(!this._delete_printer_configuration) { 
      this._delete_printer_configuration = this.injector.get(LocusRobotics_delete_printer_configurationService);
    }
    return this._delete_printer_configuration.run(inParams);
  }
   
   

   
 
  private _duplicate_order_check: LocusRobotics_duplicate_order_checkService;
  public async duplicate_order_check(inParams: { orderId: string, shippingContainerId?: string, licenseplateId?: string }): Promise< { foundOrders?: boolean, reasons?: string[] }> {
    if(!this._duplicate_order_check) { 
      this._duplicate_order_check = this.injector.get(LocusRobotics_duplicate_order_checkService);
    }
    return this._duplicate_order_check.run(inParams);
  }
   
   

   
 
  private _generate_jobs_by_order: LocusRobotics_generate_jobs_by_orderService;
  public async generate_jobs_by_order(inParams: { orderId: number, eventAction: string, logId?: string, transactionId?: string }): Promise< { orderJobs?: $frontendTypes.LocusRobotics.OrderJob[], reasons?: string[], output?: string }> {
    if(!this._generate_jobs_by_order) { 
      this._generate_jobs_by_order = this.injector.get(LocusRobotics_generate_jobs_by_orderService);
    }
    return this._generate_jobs_by_order.run(inParams);
  }
   
   

   
 
  private _get_authorization_token: LocusRobotics_get_authorization_tokenService;
  public async get_authorization_token(inParams: { endpoint?: string, key?: string }): Promise< { token?: string, reasons?: string[] }> {
    if(!this._get_authorization_token) { 
      this._get_authorization_token = this.injector.get(LocusRobotics_get_authorization_tokenService);
    }
    return this._get_authorization_token.run(inParams);
  }
   
   

   
 
  private _get_bot_configurations: LocusRobotics_get_bot_configurationsService;
  public async get_bot_configurations(inParams: {  }): Promise< { list?: { Lookup?: string, weight_capacity?: number, height?: number, width?: number, length?: number, warehouse?: string, type?: string, bin_capacity?: number, dimension_uom?: string, volume_capacity?: number, weight_uom?: string }[], reasons?: string[] }> {
    if(!this._get_bot_configurations) { 
      this._get_bot_configurations = this.injector.get(LocusRobotics_get_bot_configurationsService);
    }
    return this._get_bot_configurations.run(inParams);
  }
   
   

   
 
  private _get_cartonized_serials_flow: LocusRobotics_get_cartonized_serials_flowService;
  public async get_cartonized_serials_flow(inParams: { scannedValue?: string, jobId?: string }): Promise< { matchedSerials?: string[], misMatchedSerials?: string[] }> {
    if(!this._get_cartonized_serials_flow) { 
      this._get_cartonized_serials_flow = this.injector.get(LocusRobotics_get_cartonized_serials_flowService);
    }
    return this._get_cartonized_serials_flow.run(inParams);
  }
   
   

   
 
  private _get_friendly_order_status: LocusRobotics_get_friendly_order_statusService;
  public async get_friendly_order_status(inParams: { orderIds?: string[] }): Promise< { statusOutput?: { orderId?: string, status?: string }[], reasons?: string[] }> {
    if(!this._get_friendly_order_status) { 
      this._get_friendly_order_status = this.injector.get(LocusRobotics_get_friendly_order_statusService);
    }
    return this._get_friendly_order_status.run(inParams);
  }
   
   

   
 
  private _get_jobIds_by_orderId: LocusRobotics_get_jobIds_by_orderIdService;
  public async get_jobIds_by_orderId(inParams: { orderId: string }): Promise< { jobIds?: string[], jobInfo?: { jobId?: string, jobStatus?: string }[], reasons?: string[] }> {
    if(!this._get_jobIds_by_orderId) { 
      this._get_jobIds_by_orderId = this.injector.get(LocusRobotics_get_jobIds_by_orderIdService);
    }
    return this._get_jobIds_by_orderId.run(inParams);
  }
   
   

   
 
  private _get_job_header_info_by_orderId: LocusRobotics_get_job_header_info_by_orderIdService;
  public async get_job_header_info_by_orderId(inParams: { orderId?: string, projects?: string[], warehouse?: string, statuses?: string[] }): Promise< { list?: { jobId?: string, jobPriority?: number, toteId?: string, jobDate?: string, status?: string, jobTaskCount?: number, bot?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, shippingContainer?: string, licensePlate?: string, botType?: string, orderLookup?: string }[], reasons?: string[] }> {
    if(!this._get_job_header_info_by_orderId) { 
      this._get_job_header_info_by_orderId = this.injector.get(LocusRobotics_get_job_header_info_by_orderIdService);
    }
    return this._get_job_header_info_by_orderId.run(inParams);
  }
   
   

   
 
  private _get_job_header_info_by_project_warehouse_bot: LocusRobotics_get_job_header_info_by_project_warehouse_botService;
  public async get_job_header_info_by_project_warehouse_bot(inParams: { warehouse?: string, projects?: string[], fullTextSearch?: string }): Promise< { list?: { jobId?: string, jobPriority?: number, toteId?: string, jobDate?: string, status?: string, jobTaskCount?: number, bot?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, shippingContainer?: string, licensePlate?: string, botType?: string, jobCount?: any, totalUnits?: number, orderLookup?: string }[], reasons?: string[] }> {
    if(!this._get_job_header_info_by_project_warehouse_bot) { 
      this._get_job_header_info_by_project_warehouse_bot = this.injector.get(LocusRobotics_get_job_header_info_by_project_warehouse_botService);
    }
    return this._get_job_header_info_by_project_warehouse_bot.run(inParams);
  }
   
   

   
 
  private _get_orderId_by_jobIds: LocusRobotics_get_orderId_by_jobIdsService;
  public async get_orderId_by_jobIds(inParams: { jobId?: string }): Promise< { orderIds?: string[], reasons?: string[] }> {
    if(!this._get_orderId_by_jobIds) { 
      this._get_orderId_by_jobIds = this.injector.get(LocusRobotics_get_orderId_by_jobIdsService);
    }
    return this._get_orderId_by_jobIds.run(inParams);
  }
   
   

   
 
  private _get_orderIds_already_in_locus: LocusRobotics_get_orderIds_already_in_locusService;
  public async get_orderIds_already_in_locus(inParams: { transactionId?: string }): Promise< { orderIds?: number[], reasons?: string[] }> {
    if(!this._get_orderIds_already_in_locus) { 
      this._get_orderIds_already_in_locus = this.injector.get(LocusRobotics_get_orderIds_already_in_locusService);
    }
    return this._get_orderIds_already_in_locus.run(inParams);
  }
   
   

   
 
  private _get_orderJob_by_cart: LocusRobotics_get_orderJob_by_cartService;
  public async get_orderJob_by_cart(inParams: { jobId_or_cartId: string }): Promise< { jobId?: string }> {
    if(!this._get_orderJob_by_cart) { 
      this._get_orderJob_by_cart = this.injector.get(LocusRobotics_get_orderJob_by_cartService);
    }
    return this._get_orderJob_by_cart.run(inParams);
  }
   
   

   
 
  private _get_orders_to_queue: LocusRobotics_get_orders_to_queueService;
  public async get_orders_to_queue(inParams: { minimumDateThreshold?: string }): Promise< { output?: string[] }> {
    if(!this._get_orders_to_queue) { 
      this._get_orders_to_queue = this.injector.get(LocusRobotics_get_orders_to_queueService);
    }
    return this._get_orders_to_queue.run(inParams);
  }
   
   

   
 
  private _get_printers: LocusRobotics_get_printersService;
  public async get_printers(inParams: { project?: string, warehouse?: string, botType?: string, retailer?: string }): Promise< { list?: { printerName?: string, retailer?: string, project?: string, warehouse?: string, botType?: string, configId?: string }[] }> {
    if(!this._get_printers) { 
      this._get_printers = this.injector.get(LocusRobotics_get_printersService);
    }
    return this._get_printers.run(inParams);
  }
   
   

   
 
  private _get_printers_grid: LocusRobotics_get_printers_gridService;
  public async get_printers_grid(inParams: {  }): Promise< { list?: { printerName?: string, project?: string, warehouse?: string, botType?: string, configId?: string }[] }> {
    if(!this._get_printers_grid) { 
      this._get_printers_grid = this.injector.get(LocusRobotics_get_printers_gridService);
    }
    return this._get_printers_grid.run(inParams);
  }
   
   

   
 
  private _get_serial_numbers_by_jobId: LocusRobotics_get_serial_numbers_by_jobIdService;
  public async get_serial_numbers_by_jobId(inParams: { jobId: string }): Promise< { serials?: string[], taskIds?: number[], warehouseId?: number, stagingLocation?: string }> {
    if(!this._get_serial_numbers_by_jobId) { 
      this._get_serial_numbers_by_jobId = this.injector.get(LocusRobotics_get_serial_numbers_by_jobIdService);
    }
    return this._get_serial_numbers_by_jobId.run(inParams);
  }
   
   

   
 
  private _insert_bot_configuration_flow: LocusRobotics_insert_bot_configuration_flowService;
  public async insert_bot_configuration_flow(inParams: { lookup?: string, weight_capacity?: number, height?: number, width?: number, length?: number, warehouse?: string, type?: string, bin_capacity?: number, dimension_uom?: string, volume_capacity?: number, weight_uom?: string }): Promise<void> {
    if(!this._insert_bot_configuration_flow) { 
      this._insert_bot_configuration_flow = this.injector.get(LocusRobotics_insert_bot_configuration_flowService);
    }
    return this._insert_bot_configuration_flow.run(inParams);
  }
   
   

   
 
  private _insert_printer_configuration_flow: LocusRobotics_insert_printer_configuration_flowService;
  public async insert_printer_configuration_flow(inParams: { printerName?: string, project?: string, warehouse?: string, botType?: string }): Promise<void> {
    if(!this._insert_printer_configuration_flow) { 
      this._insert_printer_configuration_flow = this.injector.get(LocusRobotics_insert_printer_configuration_flowService);
    }
    return this._insert_printer_configuration_flow.run(inParams);
  }
   
   

   
 
  private _inventoryCount_main: LocusRobotics_inventoryCount_mainService;
  public async inventoryCount_main(inParams: { JobId?: string, ItemNo?: string, Location?: string, InventoryCountResult?: $frontendTypes.LocusRobotics.InventoryCountResult }): Promise< { InventoryResultsResponse?: any, JobId?: string, ItemNo?: string, Location?: string, Result?: boolean, ResultReason?: string, CountDetails?: { UOMLabel?: string, Quantity?: number }[] }> {
    if(!this._inventoryCount_main) { 
      this._inventoryCount_main = this.injector.get(LocusRobotics_inventoryCount_mainService);
    }
    return this._inventoryCount_main.run(inParams);
  }
   
   

   
 
  private _inventoryCount_request: LocusRobotics_inventoryCount_requestService;
  public async inventoryCount_request(inParams: { JobId: string, ItemNo: string, Location: string, projectId?: number }): Promise< { InventoryCountResponse?: any }> {
    if(!this._inventoryCount_request) { 
      this._inventoryCount_request = this.injector.get(LocusRobotics_inventoryCount_requestService);
    }
    return this._inventoryCount_request.run(inParams);
  }
   
   

   
 
  private _inventoryCount_result: LocusRobotics_inventoryCount_resultService;
  public async inventoryCount_result(inParams: { InventoryCountResult?: { JobId?: string, JobTaskId?: string, TaskLocation?: string, ItemNo?: string, LotNo?: string, CountDiscrepency?: boolean, CountExpectedQty?: number, CountActualQty?: number, CountUser?: string, CountDate?: string, CountRobot?: string, UOM?: string } }): Promise< { hasDiscrepancy?: boolean }> {
    if(!this._inventoryCount_result) { 
      this._inventoryCount_result = this.injector.get(LocusRobotics_inventoryCount_resultService);
    }
    return this._inventoryCount_result.run(inParams);
  }
   
   

   
 
  private _job_detail_grid: LocusRobotics_job_detail_gridService;
  public async job_detail_grid(inParams: { orderId: string }): Promise< { list?: { jobTaskId?: string, orderId?: string, orderLineNumber?: string, taskLocation?: string, taskZone?: string, taskQty?: number, itemNo?: string, itemDesc?: string, custom1?: string, countEligible?: boolean, serialValidation?: boolean, jobId?: string }[] }> {
    if(!this._job_detail_grid) { 
      this._job_detail_grid = this.injector.get(LocusRobotics_job_detail_gridService);
    }
    return this._job_detail_grid.run(inParams);
  }
   
   

   
 
  private _job_prioritization_flow: LocusRobotics_job_prioritization_flowService;
  public async job_prioritization_flow(inParams: { orderId?: number, containerSize?: string }): Promise< { jobPriority?: number }> {
    if(!this._job_prioritization_flow) { 
      this._job_prioritization_flow = this.injector.get(LocusRobotics_job_prioritization_flowService);
    }
    return this._job_prioritization_flow.run(inParams);
  }
   
   

   
 
  private _move_inventory_to_staging: LocusRobotics_move_inventory_to_stagingService;
  public async move_inventory_to_staging(inParams: { locationId?: number, orderId?: number, jobId?: string }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._move_inventory_to_staging) { 
      this._move_inventory_to_staging = this.injector.get(LocusRobotics_move_inventory_to_stagingService);
    }
    return this._move_inventory_to_staging.run(inParams);
  }
   
   

   
 
  private _orderJob_cancel: LocusRobotics_orderJob_cancelService;
  public async orderJob_cancel(inParams: { jobId?: string, transactionId?: string, orderId?: string, warehouse?: string }): Promise< { reasons?: string[] }> {
    if(!this._orderJob_cancel) { 
      this._orderJob_cancel = this.injector.get(LocusRobotics_orderJob_cancelService);
    }
    return this._orderJob_cancel.run(inParams);
  }
   
   

   
 
  private _orderJob_cancel_result: LocusRobotics_orderJob_cancel_resultService;
  public async orderJob_cancel_result(inParams: { OrderJobResult: { EventType?: string, JobId?: string, JobStatus?: string, JobDate?: string, RequestId?: string, EventInfo?: string }, transactionId?: string }): Promise<void> {
    if(!this._orderJob_cancel_result) { 
      this._orderJob_cancel_result = this.injector.get(LocusRobotics_orderJob_cancel_resultService);
    }
    return this._orderJob_cancel_result.run(inParams);
  }
   
   

   
 
  private _orderJob_request: LocusRobotics_orderJob_requestService;
  public async orderJob_request(inParams: { orderId: number, eventAction: string, transactionId?: string }): Promise< { locusPayload?: any, locusPayloadJsonString?: string, reasons?: string[] }> {
    if(!this._orderJob_request) { 
      this._orderJob_request = this.injector.get(LocusRobotics_orderJob_requestService);
    }
    return this._orderJob_request.run(inParams);
  }
   
   

   
 
  private _orderJob_result: LocusRobotics_orderJob_resultService;
  public async orderJob_result(inParams: { OrderJobResult?: $frontendTypes.LocusRobotics.OrderJobResult }): Promise< { errorMessages?: string[], IsError?: boolean }> {
    if(!this._orderJob_result) { 
      this._orderJob_result = this.injector.get(LocusRobotics_orderJob_resultService);
    }
    return this._orderJob_result.run(inParams);
  }
   
   

   
 
  private _orderJob_status: LocusRobotics_orderJob_statusService;
  public async orderJob_status(inParams: { OrderJobResult: { EventType?: string, JobId: string, JobStatus?: string, JobDate?: string, RequestId?: string, EventInfo?: string }, logId?: string, transactionId?: string }): Promise< { reasons?: string[] }> {
    if(!this._orderJob_status) { 
      this._orderJob_status = this.injector.get(LocusRobotics_orderJob_statusService);
    }
    return this._orderJob_status.run(inParams);
  }
   
   

   
 
  private _parse_location_xml_string: LocusRobotics_parse_location_xml_stringService;
  public async parse_location_xml_string(inParams: { locationXML?: string }): Promise< { locationIds?: number[] }> {
    if(!this._parse_location_xml_string) { 
      this._parse_location_xml_string = this.injector.get(LocusRobotics_parse_location_xml_stringService);
    }
    return this._parse_location_xml_string.run(inParams);
  }
   
   

   
 
  private _pickJob_result: LocusRobotics_pickJob_resultService;
  public async pickJob_result(inParams: { OrderJobResult: any }): Promise< { reasons?: string[], taskId?: string }> {
    if(!this._pickJob_result) { 
      this._pickJob_result = this.injector.get(LocusRobotics_pickJob_resultService);
    }
    return this._pickJob_result.run(inParams);
  }
   
   

   
 
  private _printJob_request: LocusRobotics_printJob_requestService;
  public async printJob_request(inParams: { OrderJobResult?: { EventType?: string, JobId?: string, JobStatus?: string, JobDate?: string, RequestId?: string }, logId?: string, transactionId?: string }): Promise< { base64Check?: string[] }> {
    if(!this._printJob_request) { 
      this._printJob_request = this.injector.get(LocusRobotics_printJob_requestService);
    }
    return this._printJob_request.run(inParams);
  }
   
   

   
 
  private _print_main: LocusRobotics_print_mainService;
  public async print_main(inParams: { content?: number, logId?: string, transactionId?: string, jobId: string, tote?: string, originalTote?: string }): Promise< { originBase64LabelData?: string[], vectorBase64LabelData?: string }> {
    if(!this._print_main) { 
      this._print_main = this.injector.get(LocusRobotics_print_mainService);
    }
    return this._print_main.run(inParams);
  }
   
   

   
 
  private _resubmit_to_locus: LocusRobotics_resubmit_to_locusService;
  public async resubmit_to_locus(inParams: { eventAction?: string, orderId?: number, jobId?: string, warehouse?: string }): Promise< { reasons?: string[] }> {
    if(!this._resubmit_to_locus) { 
      this._resubmit_to_locus = this.injector.get(LocusRobotics_resubmit_to_locusService);
    }
    return this._resubmit_to_locus.run(inParams);
  }
   
   

   
 
  private _serialValidation_request: LocusRobotics_serialValidation_requestService;
  public async serialValidation_request(inParams: { SerialValidationRequest?: { EventType?: string, JobId?: string, OrderId?: string, OrderLineId?: string, ItemNo?: string, JobTaskId?: string, Quantity?: number, Serial?: string }, transactionId?: string }): Promise< { SerialValidationResult?: { SerialNumber?: string, IsError?: boolean, Message?: string } }> {
    if(!this._serialValidation_request) { 
      this._serialValidation_request = this.injector.get(LocusRobotics_serialValidation_requestService);
    }
    return this._serialValidation_request.run(inParams);
  }
   
   

   
 
  private _split_order_job_by_weight: LocusRobotics_split_order_job_by_weightService;
  public async split_order_job_by_weight(inParams: { orderJobTasks: $frontendTypes.LocusRobotics.OrderJobTask[], weightCapacity?: number, dimFactor?: number }): Promise< { groupedJobTasks?: { jobNumber: number, orderJobTasks: $frontendTypes.LocusRobotics.OrderJobTask[] }[] }> {
    if(!this._split_order_job_by_weight) { 
      this._split_order_job_by_weight = this.injector.get(LocusRobotics_split_order_job_by_weightService);
    }
    return this._split_order_job_by_weight.run(inParams);
  }
   
   

   
 
  private _toteInduct_request: LocusRobotics_toteInduct_requestService;
  public async toteInduct_request(inParams: { OrderJobResult?: $frontendTypes.LocusRobotics.OrderJobResult, transactionId?: string }): Promise< { OrderJobResult?: { IsError?: boolean, Message?: string } }> {
    if(!this._toteInduct_request) { 
      this._toteInduct_request = this.injector.get(LocusRobotics_toteInduct_requestService);
    }
    return this._toteInduct_request.run(inParams);
  }
   
   

   
 
  private _update_bot_configuration_flow: LocusRobotics_update_bot_configuration_flowService;
  public async update_bot_configuration_flow(inParams: { Lookup: string, weightCapacity?: number, warehouse?: string, weightUOM?: string, length?: number, width?: number, height?: number, dimensionUOM?: string, type?: string, binCapacity?: number, volumeCapacity?: number }): Promise<void> {
    if(!this._update_bot_configuration_flow) { 
      this._update_bot_configuration_flow = this.injector.get(LocusRobotics_update_bot_configuration_flowService);
    }
    return this._update_bot_configuration_flow.run(inParams);
  }
   
   

   
 
  private _update_log_level: LocusRobotics_update_log_levelService;
  public async update_log_level(inParams: { logLevel?: string, application_name?: string }): Promise< { reasons?: string[] }> {
    if(!this._update_log_level) { 
      this._update_log_level = this.injector.get(LocusRobotics_update_log_levelService);
    }
    return this._update_log_level.run(inParams);
  }
   
   

   
 
  private _update_orderJobs: LocusRobotics_update_orderJobsService;
  public async update_orderJobs(inParams: { transactionId?: string }): Promise< { reasons?: string[] }> {
    if(!this._update_orderJobs) { 
      this._update_orderJobs = this.injector.get(LocusRobotics_update_orderJobsService);
    }
    return this._update_orderJobs.run(inParams);
  }
   
   

   
 
  private _update_pick_job_with_location: LocusRobotics_update_pick_job_with_locationService;
  public async update_pick_job_with_location(inParams: { locationString?: string, jobId?: string }): Promise< { reasons?: string[], locationId?: number }> {
    if(!this._update_pick_job_with_location) { 
      this._update_pick_job_with_location = this.injector.get(LocusRobotics_update_pick_job_with_locationService);
    }
    return this._update_pick_job_with_location.run(inParams);
  }
   
   

   
 
  private _update_printer_configuration_flow: LocusRobotics_update_printer_configuration_flowService;
  public async update_printer_configuration_flow(inParams: { printerName?: string, warehouse?: string, project?: string, botType?: string, newPrinterName?: string, newProject?: string, newWarehouse?: string, newBotType?: string }): Promise<void> {
    if(!this._update_printer_configuration_flow) { 
      this._update_printer_configuration_flow = this.injector.get(LocusRobotics_update_printer_configuration_flowService);
    }
    return this._update_printer_configuration_flow.run(inParams);
  }
   
   

   
 
  private _validate_serial: LocusRobotics_validate_serialService;
  public async validate_serial(inParams: { serialNumber: string, material: string, sourceLocationId?: number, packagingId?: number }): Promise< { message?: string, error?: boolean }> {
    if(!this._validate_serial) { 
      this._validate_serial = this.injector.get(LocusRobotics_validate_serialService);
    }
    return this._validate_serial.run(inParams);
  }
   
   

   
 
  private _vector_split: LocusRobotics_vector_splitService;
  public async vector_split(inParams: { orderJob?: $frontendTypes.LocusRobotics.OrderJob }): Promise< { orderJobs?: $frontendTypes.LocusRobotics.OrderJob[], reasons?: string[] }> {
    if(!this._vector_split) { 
      this._vector_split = this.injector.get(LocusRobotics_vector_splitService);
    }
    return this._vector_split.run(inParams);
  }
   
   

   
}
