import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-transfer_licenseplate_form',
  templateUrl: './FootPrintManager.transfer_licenseplate_form.component.html'
})
export class FootPrintManager_transfer_licenseplate_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateIds?: number[], sourceShipmentId: number, targetShipmentId?: number } = { licenseplateIds: [], sourceShipmentId: null, targetShipmentId: null };
  //#region Inputs
  @Input('licenseplateIds') set $inParams_licenseplateIds(v: number[]) {
    this.inParams.licenseplateIds = v;
  }
  get $inParams_licenseplateIds(): number[] {
    return this.inParams.licenseplateIds;
  }
  @Input('sourceShipmentId') set $inParams_sourceShipmentId(v: number) {
    this.inParams.sourceShipmentId = v;
  }
  get $inParams_sourceShipmentId(): number {
    return this.inParams.sourceShipmentId;
  }
  @Input('targetShipmentId') set $inParams_targetShipmentId(v: number) {
    this.inParams.targetShipmentId = v;
  }
  get $inParams_targetShipmentId(): number {
    return this.inParams.targetShipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, auto_pick?: boolean } = { confirm: null, auto_pick: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, auto_pick?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    source_order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    target_order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    auto_pick_flag: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm_button: new ToolModel(new ButtonModel('confirm_button', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(null, null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    source_order: new FieldModel(new TextBoxModel(this.formGroup.controls['source_order'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Source order', false, false),
    target_order: new FieldModel(new TextBoxModel(this.formGroup.controls['target_order'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Target order', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    auto_pick_flag: new FieldModel(new CheckBoxModel(this.formGroup.controls['auto_pick_flag'] as DatexFormControl, null, false, false, 'Auto pick', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    source_order: this.fields.source_order.control.valueChanges
    ,
    target_order: this.fields.target_order.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    auto_pick_flag: this.fields.auto_pick_flag.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.sourceShipmentId)) {
        this.$missingRequiredInParams.push('sourceShipmentId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'transfer_licenseplate_form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .source_order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .target_order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .auto_pick_flag
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_auto_pick_clicked();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_transfer_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transfer_licenseplate_form.on_confirm_clicked');
  try {
      $form.outParams.confirm = true;
      let response = (await $flows.SalesOrders.transfer_licenseplates_to_new_salesorder_flow({licenseplateIds: $form.inParams.licenseplateIds})).reasons
      if($utils.isDefined(response)){
          throw new Error(response.join(' ,'))
      }
      $form.close();
  }
  catch (error) {
      $shell.SalesOrders.showErrorDetails(`Transfer error`, `Error transferring licenseplates ${$form.inParams.licenseplateIds.join(` ,`)}`, error)
  }
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_transfer_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transfer_licenseplate_form.on_cancel_clicked');
  $form.outParams.confirm = false;
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_transfer_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transfer_licenseplate_form.on_init');
  $form.outParams.confirm = false;
  $form.fields.auto_pick_flag.control.value = false;
  
  var title = '';
  var licenseplates: string[] = [];
  
  for (let licenseplate of $form.inParams.licenseplateIds) {
      let licenseplateInfo = (await $datasources.SalesOrders.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: licenseplate })).result
      if ($utils.isDefined(licenseplateInfo?.LookupCode)) {
          licenseplates.push(licenseplateInfo?.LookupCode);
      }
  }
  
  let shipmentInfo = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({shipmentId: $form.inParams.sourceShipmentId})).result
  $form.fields.source_order.control.value = shipmentInfo.OrderLookups[0]?.Order?.LookupCode
  
  if ($utils.isDefined(licenseplates)) {
      title = `Transfer Licenseplates ${licenseplates.join(` ,`).toString().toUpperCase()}`;
  }
  
  // Set title field
  $form.title = title;
  
  // Set focus on target location field
  //$form.fields.location.control.focus();
  $form.outParams.auto_pick = $form.fields.auto_pick_flag.control.value
  $form.fields.auto_pick_flag.hidden = true;
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $form: FootPrintManager_transfer_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transfer_licenseplate_form.on_auto_pick_clicked');
  $form.outParams.auto_pick = $form.fields.auto_pick_flag.control.value
  
  
  }
  //#endregion private flows
}
