import { Inject, Injectable, Injector } from '@angular/core';

import { BarcodeParsing_ReportService } from './BarcodeParsing.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';

import { LocusRobotics_outbound_labelsService } from './LocusRobotics.report.index';
import { LocusRobotics_vector_labelService } from './LocusRobotics.report.index';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.InventoryCounts = this.injector.get(InventoryCounts_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
  }
    public BarcodeParsing: BarcodeParsing_ReportService;
    public Utilities: Utilities_ReportService;
    public InventoryCounts: InventoryCounts_ReportService;
    public PrintNode: PrintNode_ReportService;

  public LocusRobotics: LocusRobotics_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _outbound_labels: LocusRobotics_outbound_labelsService;
  public get outbound_labels(): LocusRobotics_outbound_labelsService {
    if(!this._outbound_labels) {
      this._outbound_labels = this.injector.get(LocusRobotics_outbound_labelsService);
    }
    return this._outbound_labels;
  }
  private _vector_label: LocusRobotics_vector_labelService;
  public get vector_label(): LocusRobotics_vector_labelService {
    if(!this._vector_label) {
      this._vector_label = this.injector.get(LocusRobotics_vector_labelService);
    }
    return this._vector_label;
  }
}

