import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'


interface Iapp_custom_purchase_order_lines_gridComponentEntity {
  OrderId?: number, LineNumber?: number, Form222?: string, GrossWeight?: number, LotId?: number, Marks?: string, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, MaterialGroupId?: number, ReceivingStatus?: string, MaterialGroup?: { Name?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[]}

interface Iapp_custom_purchase_order_lines_gridComponentInParams {
  orderId: number, shipmentId: number, projectId: number, orderStatusId: number}


class app_custom_purchase_order_lines_gridComponentRowModel extends GridRowModel {
  grid: app_custom_purchase_order_lines_gridComponent;
  entity: Iapp_custom_purchase_order_lines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    form222_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    marks_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    group: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    receiving_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    form222: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['form222_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    edit_lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new ButtonModel('', new ButtonStyles(null, null), false, false, false, ' ', 'ms-Icon ms-Icon--AccountActivity', null)
,
null
      ),
    marks: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['marks_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, false, '', null)
      ),
  }

  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectId;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
  }

  async $initializeExisting(grid: app_custom_purchase_order_lines_gridComponent, entity: Iapp_custom_purchase_order_lines_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_purchase_order_lines_gridComponent, entity?: Iapp_custom_purchase_order_lines_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'LineNumber',this.entity.LineNumber);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.app.custom_ds_purchase_order_lines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as Iapp_custom_purchase_order_lines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity.MaterialId);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.InventoryMeasurementUnit?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.PackagedId);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity.PackagedAmount);
    (this.cells.group.displayControl as TextModel).text = $row.entity.Material?.MaterialGroup?.Name;
    (this.cells.receiving_status.displayControl as TextModel).text = $row.entity.Material?.ReceivingStatus;
    (this.cells.form222.displayControl as TextModel).text = $row.entity?.Form222;
    (this.cells.form222.editControl as TextBoxModel).reset($row.entity?.Form222);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.VendorLotId);
    (this.cells.marks.displayControl as TextModel).text = $row.entity.Marks;
    (this.cells.marks.editControl as TextBoxModel).reset($row.entity.Marks);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'edit_lot' : {
        this.on_vendorlot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_init_new_row');
  
  
  $row.entity = {
      OrderId: $grid.inParams.orderId,
      StatusId: 1,
      PackagedAmount: null
  };
  
  
  
  // Lock Specific UDFs
  $row.cells.form222.editControl.readOnly = true;
  $row.cells.form222.editControl.styles.setStyle('background', 'transparent');
  
  
  // Focus on the material cell
  $row.cells.material.editControl.focus();
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_save_new_row');
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.material.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  
  var orderStatusId = $grid.inParams.orderStatusId;
  if (orderStatusId === 1) {
  
      try {
          var linenumber;
          var orderId = $grid.inParams.orderId;
  
          // Vendor lot provided but lot is null
          if ($utils.isDefined($row.cells.vendorlot.editControl.value)) {
  
              const vendorLotId = $row.cells.vendorlot.editControl.value;
  
              const vendorlot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({
                  vendorLotId: vendorLotId
              })).result;
  
              const vendorlotLookup = vendorlot.LookupCode;
  
              if ($utils.isDefined(vendorlotLookup)) {
                  const recommendedLot = (await $flows.Lots.recommend_lot_creation({
                      vendorLotLookupCode: vendorlotLookup,
                      recommendVendorLotOnly: false
                  }));
  
                  const lotLookup = recommendedLot.lotLookupCode;
  
                  if ($utils.isDefined(lotLookup)) {
                      const newLot = (await $flows.Lots.create_lot({
                          vendorLotId: $row.cells.vendorlot.editControl.value,
                          lookupCode: lotLookup,
                          statusId: 1
                      }));
  
                      const lotId = newLot.lotId;
  
                      if ($utils.isDefined(lotId)) {
                          const orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                              orderId: orderId,
                              packagedAmount: $row.cells.packaged_amount.editControl.value,
                              lotId: lotId,
                              packagingId: $row.cells.packaging.editControl.value,
                              shipmentId: $grid.inParams.shipmentId
                          }));
                          if ($utils.isDefined(orderLine)) {
  
                              if ($utils.isDefined(orderLine.linenumber)) {
                                  linenumber = orderLine.linenumber;
                                  $row.entity.LineNumber = linenumber;
                                  $row.entity.OrderId = orderId;
                              }
                              else {
                                  throw new Error('Unable to create order line.')
                              }
                          }
                          else {
                              throw new Error('Unable to create order line.')
                          }
                      }
                      else {
                          throw new Error('Unable to create child lot for the order line vendor lot.')
                      }
  
                  }
                  else {
                      throw new Error('Unable to generate a recommended lot in the format vendorlot_YYYYMMDD_HHMMS.')
                  }
  
              }
              else {
                  throw new Error('Unable to determine vendor lot from order line.')
              }
  
  
          }
          // Material only provided
          else {
              const orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  materialId: $row.cells.material.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipmentId
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
          }
  
  
  
          if ($utils.isAllDefined(linenumber, orderId)) {
              if ($utils.isDefined($row.cells.form222.editControl.value) || $utils.isDefined($row.cells.marks.editControl.value) || $utils.isDefined($row.cells.notes.editControl.value)) {
                  const payload = {
                      Form222: $row.cells.form222.editControl.value,
                      Marks: $row.cells.marks.editControl.value,
                      Notes: $row.cells.notes.editControl.value
                  }
                  await $flows.Utilities.crud_update_dual_pk_flow({
                      entitySet: 'OrderLines',
                      pk1String: 'OrderId',
                      pk1: orderId,
                      pk2String: 'LineNumber',
                      pk2: linenumber,
                      entity: payload
                  });
              }
          }
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  }
  // Legacy implementation to add a order line after the order has been processed, to be removed with oData API Actions
  else if (orderStatusId === 2) {
  
      try {
  
          // Check if material is  g-lot controlled
          const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $row.cells.material.editControl.value
          })).result;
  
          var materialControllerTypeId = material[0].ControllerTypeId;
          if (materialControllerTypeId === 1 || materialControllerTypeId === 3) {
  
              const lot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
              if ($utils.isDefined(lot) && lot[0].TypeId === 1) {
                  var lotId = lot[0].Id;
                  var vendorLotId = lot[0].VendorLotId;
              }
          }
  
          const result = await $flows.PurchaseOrders.create_purchase_order_line_in_processing_status_flow({
              projectId: $grid.inParams.projectId,
              orderId: $grid.inParams.orderId,
              shipmentId: $grid.inParams.shipmentId,
              materialId: $row.cells.material.editControl.value,
              packagingId: $row.cells.packaging.editControl.value,
              packagedAmount: $row.cells.packaged_amount.editControl.value,
              vendorLotId: $utils.isDefined(vendorLotId) ? vendorLotId : $row.cells.vendorlot.editControl.value
          });
  
          if ($utils.isDefined(result)) {
              if ($utils.isDefined(result.lineNumber)) {
                  $row.entity.LineNumber = result.lineNumber;
                  await $grid.refresh();
  
              }
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = errorMessage;
          await $shell.openErrorDialog('Order line error', 'An error occurred during while adding the order line', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  
  else {
      $shell.openErrorDialog('Save', 'Order is not in a created or processing status');
      throw new Error('Order is not in a created or processing status'); // to prevent displayMode 
  
  }
  
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_material_changed');
  $row.cells.packaging.editControl.value = null;
  
  $row.cells.vendorlot.editControl.value = null;
  
  $row.cells.vendorlot.displayControl.text = null;
  
  
  
  
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($utils.isDefined(materialId)) {
  
      // Default the packaging
      const defaultPackaging = (await $flows.Materials.get_material_default_packaging({ materialId: materialId }));
      var packagingId = defaultPackaging.packagingId;
  
      if ($utils.isDefined(packagingId)) {
  
          $row.cells.packaging.editControl.value = packagingId;
  
  
  
          // Default the decimal formatting
          const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
              materialId: materialId,
              packagingId: packagingId
          }));
  
          if ($utils.isDefined(decimalFormat)) {
              $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
          }
  
      }
      // Extract packaging detail
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      }))?.result;
      // Check if material is serial controlled and or lot controlled
  
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
       const materialIsFixedLength = material[0].IsFixedLength;
      const materialIsFixedVolume = material[0].IsFixedVolume;
      const materialGroupName = material[0].MaterialGroup.Name;
  
      // Custom UDF check
      if (materialGroupName == 'Vault' || materialGroupName == 'Cage') {
          $row.cells.form222.editControl.readOnly = false;
          $row.cells.form222.editControl.styles.resetStyle();
      }
      else {
          $row.cells.form222.editControl.readOnly = true;
          $row.cells.form222.editControl.styles.setStyle('background', 'transparent');
  
      }
  
  
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4 || materialIsFixedLength === false ||
          materialIsFixedVolume === false) {
  
          const weightUomId = packaging[0].WeightUomId;
          if ($utils.isDefined(weightUomId)) {
  
          }
  
      } else {
  
  
      }
  
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
  
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
  
      } else {
  
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
  
      }
  
  
  
  }
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_row_data_loaded');
  var materialId = $row.entity.MaterialId;
  
  if ($utils.isDefined(materialId)) {
  
      // Check if material is serial controlled and or lot controlled
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
      const materialIsFixedLength = material[0].IsFixedLength;
      const materialIsFixedVolume = material[0].IsFixedVolume;
      const materialGroupName = material[0].MaterialGroup.Name;
  
  
  
      // Custom UDF check
  
      if (materialGroupName == 'Vault' || materialGroupName == 'Cage') {
          $row.cells.form222.editControl.readOnly = false;
          $row.cells.form222.editControl.styles.resetStyle();
      }
      else {
          $row.cells.form222.editControl.readOnly = true;
          $row.cells.form222.editControl.styles.setStyle('background', 'transparent');
  
      }
  
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
  
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
      } else {
  
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
      }
  
  
  }
  
  
  
  // Set Expected / Actual calculation
  
  let list: number[] = [1, 8]; // Created and Cancelled
  if (!($grid.inParams.orderStatusId in list) && $utils.isDefined($row.entity.PackagedAmount)) {
      const actualPackagedAmount = $utils.isDefined($row.entity.ShipmentLines[0]?.ActualPackagedAmount) ? $row.entity.ShipmentLines[0]?.ActualPackagedAmount : 0;
      const calculation = `${actualPackagedAmount} / ${$row.entity.PackagedAmount}`;
      $row.cells.packaged_amount.displayControl.text = calculation
  
  
  }
  
  //prevent adding orderlines if orderclass = 'FDA' (orderClassId = 33) |127193|
  const orderClassId = (await $datasources.custom_ds_get_order_class_by_orderId.get
          ({
          orderId: $row.entity.OrderId
          })
          ).result;
  
  if (orderClassId.OrderClass.LookupCode.trim().toUpperCase() == 'FDA')
      {
          $grid.canAdd = false;
      }
  else   
      {
         $grid.canAdd = true; 
      }
  
  //prevent adding orderlines if orderStatus = Cancelled  (orderStatusId = 8) |120421|
  
  if ($grid.inParams.orderStatusId == 8)
      {
          $grid.canAdd = false;
      }
  
  $grid.on_grid_data_loaded();
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_save_existing_row');
  try {
  
      const allRequiredFieldHaveValue =
          $utils.isDefined($row.cells.material.editControl.value) &&
          $utils.isDefined($row.cells.packaging.editControl.value) &&
          $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
      if (allRequiredFieldHaveValue === false) {
          $shell.openErrorDialog('Save', 'Missing Required fields');
          throw new Error('Missing Required fields'); // to prevent displayMode 
      }
  
      const payload: any = {};
      if ($row.cells.material.editControl.isChanged) {
          payload.MaterialId = $row.cells.material.editControl.value;
      }
      if ($row.cells.packaging.editControl.isChanged) {
          payload.PackagedId = $row.cells.packaging.editControl.value;
      }
  
      if ($row.cells.packaged_amount.editControl.isChanged) {
          if ($row.cells.packaged_amount.editControl.value <= 0) {
              $shell.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
              throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
          }
          else {
              payload.PackagedAmount = $row.cells.packaged_amount.editControl.value;
          }
      }
  
      if ($row.cells.vendorlot.editControl.isChanged) {
          if ($utils.isDefined($row.cells.vendorlot.editControl.value)) {
              const vendorLotId = $row.cells.vendorlot.editControl.value;
  
              const vendorlot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({
                  vendorLotId: vendorLotId
              })).result;
  
              const vendorlotLookup = vendorlot?.LookupCode;
  
              if ($utils.isDefined(vendorlotLookup)) {
                  const recommendedLot = (await $flows.Lots.recommend_lot_creation({
                      vendorLotLookupCode: vendorlotLookup,
                      recommendVendorLotOnly: false
                  }));
  
                  const lotLookup = recommendedLot.lotLookupCode;
  
                  if ($utils.isDefined(lotLookup)) {
                      const newLot = (await $flows.Lots.create_lot({
                          vendorLotId: vendorLotId,
                          lookupCode: lotLookup,
                          statusId: 1
                      }));
  
                      const lotId = newLot.lotId;
                      if ($utils.isDefined(lotId)) {
  
                          payload.VendorLotId = vendorLotId;
                          payload.LotId = lotId;
  
                      }
                      else {
                          throw new Error('Unable to create child lot for the order line vendor lot.')
                      }
  
                  }
                  else {
                      throw new Error('Unable to generate a recommended lot in the format vendorlot_YYYYMMDD_HHMMS.')
                  }
              }
              else {
                  throw new Error('Unable to determine vendor lot from order line.')
              }
          }
          else {
              payload.VendorLotId = null;
              payload.LotId = null;
          }
      }
  
      if ($row.cells.marks.editControl.isChanged) {
          payload.Marks = $row.cells.marks.editControl.value;
      }
  
      if ($row.cells.notes.editControl.isChanged) {
          payload.Notes = $row.cells.notes.editControl.value;
      }
      // Custom order line UDF
      if ($row.cells.form222.editControl.isChanged) {
          payload.Form222 = $row.cells.form222.editControl.value;
      }
  
  
      await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'OrderLines',
          pk1String: 'OrderId',
          pk1: $row.entity.OrderId,
          pk2String: 'LineNumber',
          pk2: $row.entity.LineNumber,
          entity: payload
      });
  
  
      await $row.refresh();
  } catch (error) {
      $shell.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_packaging_changed');
  
  
  
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  if ($utils.isAllDefined(materialId, packagingId)) {
  
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
          materialId: materialId,
          packagingId: packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
      }
  
  }
  }
  on_vendorlot_clicked(event = null) {
    return this.on_vendorlot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_clickedInternal(
    $row: app_custom_purchase_order_lines_gridComponentRowModel,
  $grid: app_custom_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_vendorlot_clicked');
  
  const lotId = $row.entity.LotId;
  
  
  
  if ($utils.isDefined(lotId) && !$row.isNew) {
  
      const dialogResult = (await $shell.opencustom_lot_editorDialog({ lotId: lotId }, 'flyout'));
      if ($utils.isDefined(dialogResult)) {
          const userConfirmed = dialogResult.confirm;
  
          if (userConfirmed) {
              $row.refresh();
          }
      }
  
  }
  else {
  
      await $shell.openInfoDialog('Vendor lot editor', 'Cannot open editor as the lot and or vendor lot is not defined on the order line.')
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
  ],
  selector: 'app-custom_purchase_order_lines_grid',
  templateUrl: './app.custom_purchase_order_lines_grid.component.html'
})
export class app_custom_purchase_order_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_purchase_order_lines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_purchase_order_lines_gridComponentInParams = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', '#', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', true, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'Quantity', true, false, null, false),       group: new GridHeaderModel(new HeaderStyles(null, null), 'group', 'Group', false, false, null, false),       receiving_status: new GridHeaderModel(new HeaderStyles(null, null), 'receiving_status', 'Rec. Lot Status', false, false, null, false),       form222: new GridHeaderModel(new HeaderStyles(null, null), 'form222', 'Form222', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor Lot', false, false, null, false),       edit_lot: new GridHeaderModel(new HeaderStyles(null, null), 'edit_lot', ' ', false, false, null, false),       marks: new GridHeaderModel(new HeaderStyles(null, null), 'marks', 'Marks', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_purchase_order_lines_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Order Lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.app.custom_ds_purchase_order_lines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as Iapp_custom_purchase_order_lines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_purchase_order_lines_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: app_custom_purchase_order_lines_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: Iapp_custom_purchase_order_lines_gridComponentEntity) {
    const row = new app_custom_purchase_order_lines_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_init');
  
  // Disable Delete button
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  
  
  const orderStatusId = $grid.inParams.orderStatusId;
  
  // Created
  if (orderStatusId === 1) {
  
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.canEdit = true;
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
  
  
  }
  // Processing
  else if (orderStatusId === 2) {
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
      $grid.canEdit = true;
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.headers.packaged_amount.name = 'Expected';
  }
  // Cancelled
  else if (orderStatusId === 8) {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = true;
      $grid.headers.material.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.packaged_amount.required = false;
      $grid.headers.packaged_amount.name = 'Quantity';
  
  }
  else {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = true;
      $grid.headers.material.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.packaged_amount.required = false;
      $grid.headers.packaged_amount.name = 'Expected';
  
  }
  
  
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_row_selected');
  
  
  const orderStatusId = $grid.inParams.orderStatusId;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount > 0 && orderStatusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
  
  }
  
  
  
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: app_custom_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_delete');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.openErrorDialog('Order Line Delete Error', 'No order lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.OrderId,
              lineNumber: row.entity.LineNumber
          };
          const result = await $flows.PurchaseOrders.is_purchase_order_line_deletable_flow(flowParams);
          const reasons = result.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order Line ${row.entity.LineNumber.toString()} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete order line errors';
          const errorMessage = `Order line(s) ${failures.map(c => c.entity.LineNumber.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order line(s) ${candidates.map(c => c.entity.LineNumber.toString()).join(',')} - Once deleted, the order line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order line(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following order line(s)';
              const message = confirmCandidates;
              confirm = await $shell.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.OrderId,
                          lineNumber: candidate.entity.LineNumber
                      }
                      const result = await $flows.PurchaseOrders.delete_purchase_order_line_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order line ${candidate.entity.LineNumber.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All order line(s) deleted';
                  const message = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} deleted`;
                  await $shell.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All order line(s) failed to delete';
                      const message = `Order line(s) ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')} could not be deleted`;
                      await $shell.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order lines could not be deleted';
                      const success = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} were deleted.`;
                      const errors = `The following order lin(s) could not be deleted: ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: app_custom_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_lines_grid.on_grid_data_loaded');
  if ($grid.inParams.orderStatusId !== 1) {
      // Restrict row edits
      let exclusion_columns = [
          'marks',
          'notes'
      ];
  
      for (let row of $grid.rows) {
          for (let key in row.cells) {
              if (!exclusion_columns.includes(key) && $utils.isDefined(row.cells[key]?.editControl?.readOnly)) {
                  row.cells[key].editControl.readOnly = true;
              }
          }
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
