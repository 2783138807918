import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_pack_verification_shipping_container_contents_gridComponent } from './FootPrintManager.pack_verification_shipping_container_contents_grid.component';

type EntityType = { 
    Id?: number, Height?: number, Length?: number, LookupCode?: string, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, Weight?: number, Width?: number, LicensePlates?: { Id?: number, Archived?: boolean, LocationId?: number, WarehouseId?: number }[], Shipment?: { Id?: number, ActualWarehouseId?: number, LookupCode?: string, TrailerId?: string, OrderLookups?: { CreatedSysDateTime?: string, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, Account?: { Id?: number, LookupCode?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string } } }[], Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } }, ContainerType?: { Id?: number, Name?: string }, DimensionUom?: { Id?: number, Name?: string }, WeightUom?: { Id?: number, Name?: string }, VolumeUom?: { Id?: number, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_pack_verification_shipping_container_contents_gridComponent),
  ],
  selector: 'FootPrintManager-pack_verification_completion_editor',
  templateUrl: './FootPrintManager.pack_verification_completion_editor.component.html'
})
export class FootPrintManager_pack_verification_completion_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { shippingContainerId: number, startVerificationTaskId: number, instructions?: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[], scannedValues?: { scannedValue: string, lotId: number, packagingId: number }[], properties?: { containerType?: string, dimensions?: string, weight?: string }, targetLocationId: number, can_fail_verification: boolean } = { shippingContainerId: null, startVerificationTaskId: null, instructions: [], scannedValues: [], properties: { containerType: null, dimensions: null, weight: null }, targetLocationId: null, can_fail_verification: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  @Input('startVerificationTaskId') set $inParams_startVerificationTaskId(v: number) {
    this.inParams.startVerificationTaskId = v;
  }
  get $inParams_startVerificationTaskId(): number {
    return this.inParams.startVerificationTaskId;
  }
  @Input('instructions') set $inParams_instructions(v: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[]) {
    this.inParams.instructions = v;
  }
  get $inParams_instructions(): { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[] {
    return this.inParams.instructions;
  }
  @Input('scannedValues') set $inParams_scannedValues(v: { scannedValue: string, lotId: number, packagingId: number }[]) {
    this.inParams.scannedValues = v;
  }
  get $inParams_scannedValues(): { scannedValue: string, lotId: number, packagingId: number }[] {
    return this.inParams.scannedValues;
  }
  @Input('properties') set $inParams_properties(v: { containerType?: string, dimensions?: string, weight?: string }) {
    this.inParams.properties = v;
  }
  get $inParams_properties(): { containerType?: string, dimensions?: string, weight?: string } {
    return this.inParams.properties;
  }
  @Input('targetLocationId') set $inParams_targetLocationId(v: number) {
    this.inParams.targetLocationId = v;
  }
  get $inParams_targetLocationId(): number {
    return this.inParams.targetLocationId;
  }
  @Input('can_fail_verification') set $inParams_can_fail_verification(v: boolean) {
    this.inParams.can_fail_verification = v;
  }
  get $inParams_can_fail_verification(): boolean {
    return this.inParams.can_fail_verification;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { eventsInitialized?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    instructions: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      fail_verification: new ToolModel(new ButtonModel('fail_verification', new ButtonStyles(['destructive'], null), false, false, false, 'Fail verification', 'icon-ic_fluent_prohibited_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new SplitButtonModel<{ print_master_packing_list: ButtonModel, print_packing_list: ButtonModel, print_shipping_label: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('print_master_packing_list', new ButtonStyles(null, null), false, false, false, 'Master packing list', ''),
          new ButtonModel('print_packing_list', new ButtonStyles(null, null), false, false, false, ' Packing list', ''),
          new ButtonModel('print_shipping_label', new ButtonStyles(null, null), false, false, false, 'Shipping label', '')
        ])
    , false)
  };

  fields = {
    instructions: new FieldModel(new TextBoxModel(this.formGroup.controls['instructions'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    container_type: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Container type', false, false),
    dimensions: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Dimensions', false, false),
    weight: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Instructions', false, false, true, false),
  newGroup2: new FieldsetModel('Container setup', false, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      container_contents: new TabItemModel(
        this.rootTabGroup, 
        'Container contents', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_shippingContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.shippingContainerId;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_canAddManually(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_startVerificationTaskId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.startVerificationTaskId;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isReadOnly(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[];
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues(): { scannedValue?: string, lotId?: number, packagingId?: number }[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.scannedValues;
      
      if(!isEqual(this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues, expr)) {
        this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues = expr;
      }
      return this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isBlind(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_locationId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.targetLocationId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_container_contents', { read: FootPrintManager_pack_verification_shipping_container_contents_gridComponent }) $tabs_container_contents: FootPrintManager_pack_verification_shipping_container_contents_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    instructions: this.fields.instructions.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.container_contents,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
      if(isNil(this.inParams.startVerificationTaskId)) {
        this.$missingRequiredInParams.push('startVerificationTaskId');
      }
      if(isNil(this.inParams.targetLocationId)) {
        this.$missingRequiredInParams.push('targetLocationId');
      }
      if(isNil(this.inParams.can_fail_verification)) {
        this.$missingRequiredInParams.push('can_fail_verification');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Complete verification';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      shippingContainerId:  $editor.inParams.shippingContainerId 
    };

    const data = await this.datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerId.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    
    (this.fields.container_type.control as TextModel).text = $editor.inParams.properties?.containerType ?? '';
    (this.fields.dimensions.control as TextModel).text = $editor.inParams.properties?.dimensions ?? '';
    (this.fields.weight.control as TextModel).text = $editor.inParams.properties?.weight ?? '';

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_container_contents') {
        if (!isNil(this.$tabs_container_contents) && !this.tabs.container_contents.hidden && !this.tabs.container_contents.removed) {
          this.$tabs_container_contents.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .instructions
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  try {
      $editor.fields.instructions.control.value = $editor.inParams.instructions?.map(i => `${i.isCompleted ? '✓' : '✕'} - ${i.entity} ${i.code} - ${i.instruction}`).join('\n\n');
      $editor.fields.container_type.control.text = $editor.inParams.properties.containerType;
      $editor.fields.dimensions.control.text = $editor.inParams.properties.dimensions;
      $editor.fields.weight.control.text = $editor.inParams.properties.weight;
  
      await autoPrintReports();
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error loading summary', error.message);
  }
  
  /***********************************************
   * FUNCTIONS
  ************************************************/
  async function autoPrintReports() {
      // Auto print reports
      if (!$utils.isDefined($settings.FootPrintManager.PrintNodeKey)) { return; }
  
      // Validate credentials 
      const printerResults = (await $flows.PrintNode.get_printers_by_location_flow({ location_id: $editor.inParams.targetLocationId }));
  
      if ($utils.isDefined(printerResults.reasons)) {
          $shell.FootPrintManager.openToaster(`Error auto-printing with PrintNode`, printerResults.reasons.join(', '), EToasterType.Error, { timeOut: 10000, positionClass: EToasterPosition.bottomRight, closeButton: true });
          return;
      }
  
      const { letter_printer, label_printer } = printerResults.printers;
  
      // Reports 
      if ($utils.isDefined(letter_printer)) {
          let packingList = (await $reports.ShippingContainers.packing_slip_report.exportAsPdf({ shippingContainerId: $editor.entity.Id })).data;
          $flows.PrintNode.print_request_flow({ printerId: letter_printer.id, content: await $utils.blob.toBase64(packingList), contentType: 'pdf_base64' });
  
          // Check if last container on shipment
          let unverifiedContainers = (await $datasources.PackVerification.ds_get_shipping_containers_not_verified_by_shipmentId.get({ shipmentId: $editor.entity.Shipment?.Id })).result;
          if (!unverifiedContainers.find(c => c.Id !== $editor.entity.Id)) {
              let packingList = (await $reports.SalesOrders.packing_list_report.exportAsPdf({ orderId: $editor.entity.Shipment.OrderLookups[0]?.Order?.Id })).data;
              $flows.PrintNode.print_request_flow({ printerId: letter_printer.id, content: await $utils.blob.toBase64(packingList), contentType: 'pdf_base64' });
          }
      }
  
      if ($utils.isDefined(label_printer)) {
          const results = (await $datasources.Attachments.ds_get_attachments_by_entity.getList({ entityType: 'ShippingContainer', entityKeys: [{ name: 'Id', value: $editor.entity.Id }] })).result;
          if (results.length > 0) {
              const rawData = (await $flows.Attachments.get_file_content_flow({ entityType: 'ShippingContainer', attachmentId: results[0].Id })).fileContent;
              const rawDataText = await rawData.text()           
  
              const reportData = btoa(rawDataText);
              $flows.PrintNode.print_request_flow({ printerId: label_printer.id, content: reportData, contentType: 'raw_base64' });
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  if (!$utils.isDefined($editor.inParams.scannedValues)) {
      $editor.inParams.scannedValues = [];
  }
  
  if (!$utils.isDefined($editor.inParams.instructions)) {
      $editor.inParams.instructions = [];
  }
  
  /******************************************
   * EVENTS
  *******************************************/
  // Get element to attach to 
  const element = document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0];
  
  if (element == null) {
      throw new Error(`Failed to identify the HTML element for pack verification wizard`);
  }
  
  element.addEventListener('handleContainerInstructionsChanged', ((event: CustomEvent<{ entity: string, code: string, instruction: string, isCompleted: boolean }[]>) => {
      $editor.fields.instructions.control.value = event.detail?.map(i => `${i.isCompleted ? '✓' : '✕'} - ${i.entity} ${i.code} - ${i.instruction}`).join('\n\n');
  }) as EventListener);
  
  element.addEventListener('handleContainerPropertiesChanged', ((event: CustomEvent<{ containerType: string, dimensions: string, weight: string }>) => {
      $editor.fields.container_type.control.text = event.detail.containerType;
      $editor.fields.dimensions.control.text = event.detail.dimensions;
      $editor.fields.weight.control.text = event.detail.weight;
  }) as EventListener);
  
  
  element.dispatchEvent(new CustomEvent('on_pack_verification_completion_editor_initialized'));
  
  }
  on_fail_verification_clicked(event = null) {
    return this.on_fail_verification_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fail_verification_clickedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  try {
      let confirmationResult = (await $shell.FootPrintManager.openpack_verification_failure_formDialog({ shippingContainerCode: $editor.entity.LookupCode }));
  
      if (confirmationResult.isConfirmed) {
          let targetLocationId = (await $flows.PackVerification.get_failure_location_flow({
              inputs: {
                  context: 'ManualFail',
                  defaultLocationId: $editor.inParams.targetLocationId,
                  taskId: $editor.inParams.startVerificationTaskId
              }
          })).targetLocationId;
  
          let result = (await $flows.PackVerification.fail_pack_verification_flow({
              shippingContainerId: $editor.entity.Id,
              startPackVerificationTaskId: $editor.inParams.startVerificationTaskId,
              reasonCodeId: confirmationResult.reasonCodeId,
              targetLocationId: targetLocationId
          }));
  
          document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handleCloseWizard'));
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error verifying content', targetError.message);
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_pack_verification_completion_editor.on_print_clicked');
  try {
     // let result = await $flows.PackVerification.report_context_pack_verification_print({ shippingContainerId: $editor.inParams.shippingContainerId });
  
      // if ($utils.isDefined(result.reasons)) {
      //     throw new Error((await $flows.Utilities.grammar_format_string_array_flow({values: result.reasons})).formattedValue);
      // }
  
      await $shell.FootPrintManager.openInfoDialog('Successfully printed label', `The label for shipping container ${$editor.entity.LookupCode} has been printed.`);
  
  }
  catch (error) {
      await $shell.FootPrintManager.openErrorDialog('Error printing label', error.message);
  }
  }
  on_print_master_packing_list_clicked(event = null) {
    return this.on_print_master_packing_list_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_master_packing_list_clickedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $reports.SalesOrders.packing_list_report.print({ orderId: $editor.entity.Shipment.OrderLookups[0]?.Order?.Id });
  }
  on_print_packing_list_clicked(event = null) {
    return this.on_print_packing_list_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_packing_list_clickedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $reports.ShippingContainers.packing_slip_report.print({ shippingContainerId: $editor.entity.Id });
  }
  on_print_label_clicked(event = null) {
    return this.on_print_label_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_label_clickedInternal(
    $editor: FootPrintManager_pack_verification_completion_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  const reportData = (await $flows.ShippingContainers.get_shipping_label_report_flow({ shipping_container_id: $editor.entity.Id })).reportData;
  
  await $shell.FootPrintManager.openshipping_label_contentDialog({url: await $utils.blob.toBase64(reportData, true)}, 'modal', EModalSize.Xlarge);
  
  
  }
  //#endregion private flows
  
}
