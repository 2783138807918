import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { LocusRobotics_jobs_detail_by_order_gridComponent } from './LocusRobotics.jobs_detail_by_order_grid.component';

interface ILocusRobotics_jobs_header_gridComponentEntity {
  jobId?: string, status?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, jobCount?: number, totalUnits?: number, botType?: string, orderLookup?: string}

interface ILocusRobotics_jobs_header_gridComponentInParams {
  projectIds?: number[], warehouseId?: number}


class LocusRobotics_jobs_header_gridComponentRowModel extends GridRowModel {
  grid: LocusRobotics_jobs_header_gridComponent;
  entity: ILocusRobotics_jobs_header_gridComponentEntity;


  options: { cancel_button: ButtonModel } = {
    cancel_button: new ButtonModel('cancel_button', new ButtonStyles(null, null), false, false, false, 'Cancel jobs', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    orderId: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    number_of_jobs: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    botType: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_orderId(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.orderId;
    
    return expr;
  }


  get $rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouseId;
    
    return expr;
  }

  cacheValueFor_$rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_projectIds: number[];

  get $rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_projectIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectIds;
    
    if(!isEqual(this.cacheValueFor_$rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_projectIds, expr)) {
      this.cacheValueFor_$rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_projectIds = expr;
    }
    return this.cacheValueFor_$rowExpand_LocusRobotics_jobs_detail_by_order_grid_inParams_projectIds;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: LocusRobotics_jobs_header_gridComponent, entity: ILocusRobotics_jobs_header_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.jobId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: LocusRobotics_jobs_header_gridComponent, entity?: ILocusRobotics_jobs_header_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.jobId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.jobId;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      orderId:  null ,
      projectIds:  $grid.inParams.projectIds ,
      warehouseId:  $grid.inParams.warehouseId ,
    };
    const data = await this.datasources.LocusRobotics.ds_jobs_header_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as ILocusRobotics_jobs_header_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.orderId.displayControl as TextModel).text = $utils.isDefined($row.entity.orderLookup) ? $row.entity.orderLookup : $row.entity.orderId;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status;
    (this.cells.number_of_jobs.displayControl as TextModel).text = $row.entity?.jobCount?.toString();
    (this.cells.total_items.displayControl as TextModel).text = $row.entity?.totalUnits?.toString()   ;
    (this.cells.project.displayControl as TextModel).text = $row.entity?.project;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity?.warehouse;
    (this.cells.botType.displayControl as TextModel).text = $row.entity?.botType;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'orderId' : {
        this.on_order_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_tote_id_clicked(event = null) {
    return this.on_tote_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_tote_id_clickedInternal(
    $row: LocusRobotics_jobs_header_gridComponentRowModel,
  $grid: LocusRobotics_jobs_header_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_tote_id_clicked');
  $shell.Utilities.openInfoDialog('Development', 'TBD')
  }
  on_bot_clicked(event = null) {
    return this.on_bot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_bot_clickedInternal(
    $row: LocusRobotics_jobs_header_gridComponentRowModel,
  $grid: LocusRobotics_jobs_header_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_bot_clicked');
  $shell.Utilities.openInfoDialog('Development', 'beep boop beep')
  }
  on_order_id_clicked(event = null) {
    return this.on_order_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_id_clickedInternal(
    $row: LocusRobotics_jobs_header_gridComponentRowModel,
  $grid: LocusRobotics_jobs_header_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_order_id_clicked');
  
  
  
  
  $shell.LocusRobotics.opensingle_job_hub({ orderId: Number($row.entity.orderId)})
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $row: LocusRobotics_jobs_header_gridComponentRowModel,
  $grid: LocusRobotics_jobs_header_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_cancel_clicked');
  let message: string;
  
  
  const confirmation = await $shell.LocusRobotics.openConfirmationDialog(`Cancel job?`, `This will cancel ALL jobs associated with order: ${$row.entity.orderId}. Once cancelled, jobs will no longer be available for Locus.`, 'Confirm', 'Cancel')
  
  
  if (confirmation) {    
      let response = (await $flows.LocusRobotics.orderJob_cancel({
          orderId: $row.entity.orderId,
          warehouse: $row.entity.warehouse,
          transactionId: $utils.createGuid()
      }))
      if($utils.isDefined(response.reasons)){
          message = `Failed to cancel job ${$row.entity.jobId}`
      } else {
          message = `Successfully canceled job ${$row.entity.jobId}`
      }
  }
  
  await $shell.LocusRobotics.openInfoDialog(`Cancel job`, message)
  await $grid.refresh();
  }
  on_refresh_row_clicked(event = null) {
    return this.on_refresh_row_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_row_clickedInternal(
    $row: LocusRobotics_jobs_header_gridComponentRowModel,
  $grid: LocusRobotics_jobs_header_gridComponent, 
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_refresh_row_clicked');
  $row.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_jobs_detail_by_order_gridComponent)
  ],
  selector: 'LocusRobotics-jobs_header_grid',
  templateUrl: './LocusRobotics.jobs_header_grid.component.html'
})
export class LocusRobotics_jobs_header_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: ILocusRobotics_jobs_header_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: ILocusRobotics_jobs_header_gridComponentInParams = { projectIds: [], warehouseId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     orderId: new GridHeaderModel(new HeaderStyles(null, null), 'orderId', 'Order', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       number_of_jobs: new GridHeaderModel(new HeaderStyles(null, null), 'number_of_jobs', 'Number of jobs', false, false, null, false),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'total_items', 'Total items', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       botType: new GridHeaderModel(new HeaderStyles(null, null), 'botType', 'Bot type', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: LocusRobotics_jobs_header_gridComponentRowModel[] = [];

  @Input('projectIds') set $inParams_projectIds(value: any) {
    this.inParams['projectIds'] = value;
  }
  get $inParams_projectIds(): any {
    return this.inParams['projectIds'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }

  topToolbar = {
      build_pallet: new ToolModel(new ButtonModel('build_pallet', new ButtonStyles(null, null), false, false, false, 'Build pallet', 'icon-ic_fluent_tetris_app_20_filled', null)
    , false),
      resubmit_button: new ToolModel(new ButtonModel('resubmit_button', new ButtonStyles(null, null), true, false, false, 'Resubmit', 'ms-Icon ms-Icon--Rerun', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_button: new ToolModel(new ButtonModel('delete_button', new ButtonStyles(['destructive'], null), true, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: LocusRobotics_ShellService,
    private datasources: LocusRobotics_DatasourceService,
    private flows: LocusRobotics_FlowService,
    private reports: LocusRobotics_ReportService,
    private localization: LocusRobotics_LocalizationService,
    private operations: LocusRobotics_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Job header grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      orderId:  null ,
      projectIds:  $grid.inParams.projectIds ,
      warehouseId:  $grid.inParams.warehouseId ,
    };
    try {
      const data = await this.datasources.LocusRobotics.ds_jobs_header_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as ILocusRobotics_jobs_header_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new LocusRobotics_jobs_header_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: LocusRobotics_jobs_header_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_delete_clicked');
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Utilities.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
                  
                  $flows.LocusRobotics.delete_jobs({Id: entity.orderId})
                  
  
                  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.jobId.toString())
              } else {
                  errors.push({
                      id: row.entity.jobId, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.Utilities.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
  
          
      };
  };
  
  $grid.refresh();
  
  }
  on_split_clicked(event = null) {
    return this.on_split_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_split_clickedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_split_clicked');
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_data_loaded');
  var orderIds: number[] = [];
  var orderIdsString: string[] = [];
  for (let row of $grid.rows) {
      orderIds.push(Number(row.entity.orderId))
      orderIdsString.push(row.entity.orderId)
  }
  
  let results = (await $datasources.LocusRobotics.ds_get_project_by_orderIds.get({ orderIds: orderIds })).result
  let orderStatuses = (await $flows.LocusRobotics.get_friendly_order_status({ orderIds: orderIdsString })).statusOutput
  
  
  for (let row of $grid.rows) {
      for (let result of results) {
          if (Number(row.entity.orderId) === result.Id) {
              row.cells.project.displayControl.text = result.Project.LookupCode
          }
      }
      for (let order of orderStatuses) {
          if (row.entity.orderId === order.orderId) {
              row.cells.status.displayControl.text = order.status
          }
      }
      row.options.cancel_button.readOnly = true;
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_init');
  
  $grid.topToolbar.build_pallet.hidden = true;
  $grid.topToolbar.separator1.hidden = true;
  $grid.topToolbar.build_pallet.control.readOnly = true;
      
  
  if ($operations.LocusRobotics.Disable_LocusRobotics_Configurations.isAuthorized) {
      $grid.topToolbar.delete_button.hidden = false;
      $grid.topToolbar.resubmit_button.hidden = false;
  } else {
      $grid.topToolbar.delete_button.hidden = true;
      $grid.topToolbar.resubmit_button.hidden = true;
  
  }
  
  
  $grid.topToolbar.resubmit_button.hidden = true;
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  // Pallet build button availability
  $grid.topToolbar.build_pallet.control.readOnly = (selectedRowsCount === 1) ? false : true;
  
  let hasOld = false;
  for (let row of $grid.selectedRows) {
      if (row.cells.status.displayControl.text.trim().toUpperCase() === 'NEW' || row.cells.status.displayControl.text.trim().toUpperCase() === 'ACCEPT' || row.cells.status.displayControl.text.trim().toUpperCase().includes('REJECT') ||
      row.cells.status.displayControl.text.trim().toUpperCase().includes('PRINT') || row.cells.status.displayControl.text.trim().toUpperCase().includes('IN PROGRESS')) {
      } else {
          hasOld = true;
      }
  }
  
  $grid.topToolbar.resubmit_button.control.readOnly = hasOld
  $grid.topToolbar.delete_button.control.readOnly = hasOld
  
  
  if (selectedRowsCount < 1) {
      $grid.topToolbar.resubmit_button.control.readOnly = true
      $grid.topToolbar.delete_button.control.readOnly = true
  }
  }
  on_pallet_build_clicked(event = null) {
    return this.on_pallet_build_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_build_clickedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_pallet_build_clicked');
  let jobIds: string[] = [];
  
  jobIds = (await $flows.LocusRobotics.get_jobIds_by_orderId({orderId: $grid.selectedRows[0].entity.orderId})).jobIds;
  
  
  (await $shell.LocusRobotics.openpallet_build_hubDialog({jobIds: jobIds},"flyout", EModalSize.Xlarge))
  }
  on_resubmit_clicked(event = null) {
    return this.on_resubmit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_resubmit_clickedInternal(
    $grid: LocusRobotics_jobs_header_gridComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
    this.logger.log('LocusRobotics', 'jobs_header_grid.on_resubmit_clicked');
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let resubmittedRecords: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Resubmit error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Utilities.openConfirmationDialog(`Confirm resubmit!`, `Do you want to resubmit ${selected_rows.length} records?`, `Resubmit`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  let results = (await $flows.LocusRobotics.resubmit_to_locus({ eventAction: row.cells.status.displayControl.text, orderId: Number(entity.orderId) }))
  
                  if ($utils.isDefined(results.reasons)) {
                      success = false;
                      throw Error(`Error during resubmit ${results.reasons}`)
                  } else {
                      success = true;
                  }
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  resubmittedRecords.push(row.entity.jobId.toString())
              } else {
                  errors.push({
                      id: row.entity.jobId, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.Utilities.openErrorDialog(
                  `Resubmit record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmittedRecords.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully resubmitted.` : `No records were resubmitted.`,
                  null,
                  `Errors resumitting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
  
  
      };
  };
  
  $grid.refresh();
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
