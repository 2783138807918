import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_fda_order_by_awb_entrynumberService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { awb: string, entrynumber: string, projectId: number, lookupcode: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.awb)) {
      missingRequiredInParams.push('\'awb\'');
    }
    if (isNil(inParams.entrynumber)) {
      missingRequiredInParams.push('\'entrynumber\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.lookupcode)) {
      missingRequiredInParams.push('\'lookupcode\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_fda_order_by_awb_entrynumber/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      awb: string, entrynumber: string, projectId: number, lookupcode: string    }) {
        if(isNil(inParams.awb)) {
          return true; 
        }
        if(isNil(inParams.entrynumber)) {
          return true; 
        }
        if(isNil(inParams.projectId)) {
          return true; 
        }
        if(isNil(inParams.lookupcode)) {
          return true; 
        }
      return false;
    }
}
