import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_warehouse_addr_by_accService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { Id: number }): 
  Promise<{ result: { Id?: number, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_warehouse_addr_by_acc/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { Id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_warehouse_addr_by_acc/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { Id: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_warehouse_addr_by_acc/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      Id: number    }) {
        if(isNil(inParams.Id)) {
          return true; 
        }
      return false;
    }
}
