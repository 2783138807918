import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'LocusRobotics-prompt_form',
  templateUrl: './LocusRobotics.prompt_form.component.html'
})
export class LocusRobotics_prompt_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { getDate?: boolean, getLocation?: boolean, location?: string, currentJob?: number, totalJobs?: number } = { getDate: null, getLocation: null, location: null, currentJob: null, totalJobs: null };
  //#region Inputs
  @Input('getDate') set $inParams_getDate(v: boolean) {
    this.inParams.getDate = v;
  }
  get $inParams_getDate(): boolean {
    return this.inParams.getDate;
  }
  @Input('getLocation') set $inParams_getLocation(v: boolean) {
    this.inParams.getLocation = v;
  }
  get $inParams_getLocation(): boolean {
    return this.inParams.getLocation;
  }
  @Input('location') set $inParams_location(v: string) {
    this.inParams.location = v;
  }
  get $inParams_location(): string {
    return this.inParams.location;
  }
  @Input('currentJob') set $inParams_currentJob(v: number) {
    this.inParams.currentJob = v;
  }
  get $inParams_currentJob(): number {
    return this.inParams.currentJob;
  }
  @Input('totalJobs') set $inParams_totalJobs(v: number) {
    this.inParams.totalJobs = v;
  }
  get $inParams_totalJobs(): number {
    return this.inParams.totalJobs;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { date?: string, location?: string } = { date: null, location: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ date?: string, location?: string }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    date_threshold: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    target_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(['destructive'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    date_threshold: new FieldModel(new DateBoxModel(this.formGroup.controls['date_threshold'] as DatexFormControl, null, false, false, 'MM/DD/YYYY', 'date', null)
, new ControlContainerStyles(null, null), 'Date', false, false),
    target_location: new FieldModel(new TextBoxModel(this.formGroup.controls['target_location'] as DatexFormControl, null, false, false, 'Scan staging location', null)
, new ControlContainerStyles(null, null), 'Staging location', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    date_threshold: this.fields.date_threshold.control.valueChanges
    ,
    target_location: this.fields.target_location.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Prompt form';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.date_threshold.control as DateBoxModel).reset(new Date().toDateString());
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .date_threshold
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_field_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_field_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: LocusRobotics_prompt_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  
  
  
  $form.outParams.date = $form.fields.date_threshold.control.value
  $form.outParams.location = $form.fields.target_location.control.value
  $form.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: LocusRobotics_prompt_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: LocusRobotics_prompt_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($form.inParams.getDate)) {
      $form.title = 'Enter date to retrieve orders'
      const today = new Date();
      const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
      $form.fields.date_threshold.control.value = formattedDate;
      $form.fields.target_location.hidden = true
      $form.fields.date_threshold.hidden = false
  }
  
  if ($utils.isDefined($form.inParams.getLocation)) {
      $form.title = 'Scan staging location'
      if($utils.isDefined($form.inParams.location))
      {   
          $form.toolbar.confirm.control.readOnly = true;
          $form.title = `Confirm staging location: ${$form.inParams.location}`
      }
      $form.fields.target_location.hidden = false
      $form.fields.date_threshold.hidden = true
      $form.fields.target_location.control.focus();
  }
  }
  on_date_field_changed(event = null) {
    return this.on_date_field_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_date_field_changedInternal(
    $form: LocusRobotics_prompt_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  if ($form.fields.date_threshold.control.isChanged) {
      if ($utils.isDefined($form.fields.date_threshold.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
      } else {
          $form.toolbar.confirm.control.readOnly = true;
      }
  }
  }
  on_location_field_changed(event = null) {
    return this.on_location_field_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_field_changedInternal(
    $form: LocusRobotics_prompt_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  if ($form.fields.target_location.control.isChanged) {
      if ($utils.isDefined($form.fields.target_location.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
          if ($form.inParams.location?.trim().toUpperCase() === $form.fields.target_location.control.value?.trim().toUpperCase()) {
              $form.on_confirm_clicked();
          } else {
              if ($utils.isDefined($form.inParams.location)) {
                  $form.toolbar.confirm.control.readOnly = true;
                  await $shell.LocusRobotics.openErrorDialog(`Incorrect staging location`, `Location ${$form.fields.target_location.control.value} does not match expected location of ${$form.inParams.location}`)
              }
          }
      } else {
          $form.toolbar.confirm.control.readOnly = true;
      }
      $form.fields.target_location.control.focus();
  }
  }
  //#endregion private flows
}
