<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.cancel_button.readOnly"
                [ngStyle]="row.options.cancel_button.styles.style"
                [ngClass]="row.options.cancel_button.styles.classes"
                (click)="row.cancel_job_button()">
          <span *ngIf="row.options.cancel_button.label">{{row.options.cancel_button.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="build_pallet" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_pallet_build_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-status_filter" *ngIf="!filters.status_filter.hidden && !filters.status_filter.removed" 
            class="field-container standard {{filters.status_filter.invalid ? 'invalid' : ''}} {{filters.status_filter.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.status_filter.styles.style"
            [ngClass]="filters.status_filter.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.status_filter.label + (filters.status_filter.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.status_filter.label}}<span *ngIf="filters.status_filter.required" class="required-asterisk">*</span>
          </label>
        </div>
        <LocusRobotics-job_statuses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.status_filter.control.type"
            formControlName="status_filter"
            (displayTextChange)="filters.status_filter.control.displayText=$event"
            [placeholder]="filters.status_filter.control.placeholder"
            [styles]="filters.status_filter.control.styles"
            [tooltip]="filters.status_filter.control.tooltip"
        >
        </LocusRobotics-job_statuses_dd_multi>
        <ng-container *ngIf="filters.status_filter.invalid">
          <ng-container *ngFor="let error of filters.status_filter.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="toteId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.toteId.displayControl.styles.style"
          [ngClass]="row.cells.toteId.displayControl.styles.classes"
          [matTooltip]="row.cells.toteId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.toteId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order.displayControl.styles.style"
          [ngClass]="row.cells.order.displayControl.styles.classes"
          [matTooltip]="row.cells.order.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobPriority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.jobPriority.displayControl.styles.style"
          [ngClass]="row.cells.jobPriority.displayControl.styles.classes"
          [matTooltip]="row.cells.jobPriority.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.jobPriority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.bot.displayControl.styles.style"
          [ngClass]="row.cells.bot.displayControl.styles.classes"
          [matTooltip]="row.cells.bot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.bot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="botType">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.botType.displayControl.styles.style"
          [ngClass]="row.cells.botType.displayControl.styles.classes"
          [matTooltip]="row.cells.botType.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.botType.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.jobDate.displayControl.styles.style"
          [ngClass]="row.cells.jobDate.displayControl.styles.classes"
          [matTooltip]="row.cells.jobDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.jobDate.displayControl.text | formatText : row.cells.jobDate.displayControl.formatType : row.cells.jobDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="jobId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.jobId.displayControl.styles.style"
          [ngClass]="row.cells.jobId.displayControl.styles.classes"
          [matTooltip]="row.cells.jobId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.jobId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
