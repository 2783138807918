import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_warehouse_addr_by_shipping_containerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { scid?: number }): 
  Promise<{ result: { Id?: number, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { Id?: number, PreferredWarehouseId?: number, ProjectId?: number, Addresses?: { City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, Line4?: string, Name?: string, PostalCode?: string, TypeId?: number }[], Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string } } } }[] } } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_warehouse_addr_by_shipping_container/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      scid?: number    }) {
      return false;
    }
}
