import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_fda_hold_options_form',
  templateUrl: './app.custom_fda_hold_options_form.component.html'
})
export class app_custom_fda_hold_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { awb?: string, entryNumber?: string, palletCount?: number } = { awb: null, entryNumber: null, palletCount: null };
  //#region Inputs
  @Input('awb') set $inParams_awb(v: string) {
    this.inParams.awb = v;
  }
  get $inParams_awb(): string {
    return this.inParams.awb;
  }
  @Input('entryNumber') set $inParams_entryNumber(v: string) {
    this.inParams.entryNumber = v;
  }
  get $inParams_entryNumber(): string {
    return this.inParams.entryNumber;
  }
  @Input('palletCount') set $inParams_palletCount(v: number) {
    this.inParams.palletCount = v;
  }
  get $inParams_palletCount(): number {
    return this.inParams.palletCount;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, awb?: string, entryNumber?: string, palletCount?: number } = { confirm: null, awb: null, entryNumber: null, palletCount: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, awb?: string, entryNumber?: string, palletCount?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    awb: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    entry_number: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    pallet_count: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    title: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    awb: new FieldModel(new TextBoxModel(this.formGroup.controls['awb'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'AWB', true, false),
    entry_number: new FieldModel(new TextBoxModel(this.formGroup.controls['entry_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Entry Number', true, false),
    pallet_count: new FieldModel(new NumberBoxModel(this.formGroup.controls['pallet_count'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Pallet Count', true, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    awb: this.fields.awb.control.valueChanges
    ,
    entry_number: this.fields.entry_number.control.valueChanges
    ,
    pallet_count: this.fields.pallet_count.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'FDA Hold Options';
  
    const $form = this;
    const $utils = this.utils;

    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .awb
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .entry_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pallet_count
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: app_custom_fda_hold_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: app_custom_fda_hold_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  
  //validate pallet count as positive number
  
  if ($utils.isDefined($form.fields.pallet_count.control.value) && $form.fields.pallet_count.control.value > 0) {
  
  
      // Check required fields
      const allRequiredFieldsHaveValue = $utils.isAllDefined(
          $form.fields.awb.control.value,
          $form.fields.entry_number.control.value,
          $form.fields.pallet_count.control.value
      );
  
      if (allRequiredFieldsHaveValue === false) {
          // $shell.Locations.openErrorDialog('Save', 'Missing required fields');
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Missing required fields'); // To prevent display mode
      }
  
  
      $form.outParams.awb = $form.fields.awb.control.value;
      $form.outParams.entryNumber = $form.fields.entry_number.control.value;
      $form.outParams.palletCount = $form.fields.pallet_count.control.value;
  
      $form.outParams.confirm = true;
  
      $form.events.outParamsChange.emit();
  
  
      $form.close();
  
  }
  
  else {
      $form.toolbar.confirm.control.readOnly = false;
      throw new Error('Pallet Count must be positive number.'); // To prevent display mode
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: app_custom_fda_hold_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  var title = 'Create FDA hold order?';
  
  // Set title field
  $form.title = title;
  
  
  // Set focus on awb field
  $form.fields.awb.control.focus();
  
  
  var awb = $form.inParams.awb;
  var entryNumber = $form.inParams.entryNumber;
  var palletCount = $form.inParams.palletCount
  
  
  if ($utils.isDefined(awb)) {
  
      $form.fields.awb.control.value = awb;
      $form.fields.entry_number.control.focus();
  }
  
  if ($utils.isDefined(entryNumber)) {
  
      $form.fields.entry_number.control.value = entryNumber;
      if ($utils.isDefined(awb)) {
          $form.fields.pallet_count.control.focus();
      }
      else {
          $form.fields.awb.control.focus();
      }
  }
  
  if ($utils.isAllDefined(awb, entryNumber)) {
  
      $form.fields.pallet_count.control.focus();
  
  }
  
  if ($utils.isDefined(palletCount)){
  
      $form.fields.pallet_count.control.value = palletCount;
  }
  
  
  
  }
  //#endregion private flows
}
