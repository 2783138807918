import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_get_shipping_containers_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { actualTargetShippingContainerId?: number, taskIds?: number[], fullTextSearch?: string, chainHead?: number }): 
  Promise<{ result: { Id?: number, ActualTargetShippingContainerId?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, OrderId?: number, ProjectId?: number, ShipmentId?: number, StartDateTime?: string, WarehouseId?: number, Order?: { Id?: number, AccountId?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Account?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] } }, Shipment?: { Id?: number, AccountId?: number, LookupCode?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, ShippingContainers?: { Id?: number, LookupCode?: string, ContainerType?: { Description?: string, Name?: string } }[] }, Project?: { Name?: string }, ActualTargetShippingContainer?: { ContainerTypeId?: number, LookupCode?: string, ContainerType?: { Description?: string, Name?: string } }, ship_to?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, ship2?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryTelephone?: string, State?: string, OAddresses?: string }, first7?: string, ord4char?: string } }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_get_shipping_containers_tasks/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      actualTargetShippingContainerId?: number, taskIds?: number[], fullTextSearch?: string, chainHead?: number    }) {
      return false;
    }
}
