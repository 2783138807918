import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { LocusRobotics_pallet_build_gridComponent } from './LocusRobotics.pallet_build_grid.component';
import { LocusRobotics_scanned_values_gridComponent } from './LocusRobotics.scanned_values_grid.component';
import { LocusRobotics_current_job_widgetComponent } from './LocusRobotics.current_job_widget.component';
import { LocusRobotics_total_pallet_widgetComponent } from './LocusRobotics.total_pallet_widget.component';
import { LocusRobotics_jobs_selector_singleComponent } from './LocusRobotics.jobs_selector_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_pallet_build_gridComponent),
    forwardRef(() => LocusRobotics_scanned_values_gridComponent),
    forwardRef(() => LocusRobotics_current_job_widgetComponent),
    forwardRef(() => LocusRobotics_total_pallet_widgetComponent),
    forwardRef(() => LocusRobotics_jobs_selector_singleComponent),
  ],
  selector: 'LocusRobotics-pallet_build_hub',
  templateUrl: './LocusRobotics.pallet_build_hub.component.html'
})
export class LocusRobotics_pallet_build_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { jobIds?: string[] } = { jobIds: [] };
  //#region Inputs
  @Input('jobIds') set $inParams_jobIds(v: string[]) {
    this.inParams.jobIds = v;
  }
  get $inParams_jobIds(): string[] {
    return this.inParams.jobIds;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { displayValues?: { Value: string, Note: string }[], expectedValues?: string[], scannedValuesMatch?: boolean, scannedValuesCounter?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    job_selector: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    cart_or_job_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pallet_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scanned_values: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      clear_button: new ToolModel(new ButtonModel('clear_button', new ButtonStyles(null, null), false, false, false, 'Clear', 'ms-Icon ms-Icon--ChromeClose', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    job_selector: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['job_selector'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Jobs', false, false),
    cart_or_job_id: new FieldModel(new TextBoxModel(this.formGroup.controls['cart_or_job_id'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Cart / Job #', false, false),
    pallet_location: new FieldModel(new TextBoxModel(this.formGroup.controls['pallet_location'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Location', false, false),
    scanned_values: new FieldModel(new TextBoxModel(this.formGroup.controls['scanned_values'] as DatexFormControl, null, false, false, 'Scan serial number', null)
, new ControlContainerStyles(null, null), 'Scanned values', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true, false),
  newGroup2: new FieldsetModel('Label of newGroup2', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      job_contents: new TabItemModel(
        this.rootTabGroup, 
        'Job contents', 
        false,
        ),
      scanned_values: new TabItemModel(
        this.rootTabGroup, 
        'Scanned values', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_job_contents_pallet_build_grid_inParams_jobId(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $utils.isDefined($hub.inParams.jobIds) ? $hub.inParams.jobIds[0] : null;
      
      return expr;
    }
  
    get $tabs_job_contents_pallet_build_grid_inParams_readyforbuild(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.scannedValuesMatch;
      
      return expr;
    }
  
    cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues: { Value: string, Note: string }[];
    get $tabs_scanned_values_scanned_values_grid_inParams_scannedValues(): { Value: string, Note: string }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.displayValues;
      
      if(!isEqual(this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues, expr)) {
        this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues = expr;
      }
      return this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues;
    }
  
    cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_expectedValues: string[];
    get $tabs_scanned_values_scanned_values_grid_inParams_expectedValues(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.expectedValues;
      
      if(!isEqual(this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_expectedValues, expr)) {
        this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_expectedValues = expr;
      }
      return this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_expectedValues;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_job_contents', { read: LocusRobotics_pallet_build_gridComponent }) $tabs_job_contents: LocusRobotics_pallet_build_gridComponent;
      @ViewChild('$tabs_scanned_values', { read: LocusRobotics_scanned_values_gridComponent }) $tabs_scanned_values: LocusRobotics_scanned_values_gridComponent;
    //#endregion tabs children
    widgets = {
      current_pallet: new WidgetModel(false),
      total_jobs: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_current_pallet_inParams_jobId(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $utils.isDefined($hub.inParams.jobIds) ? $hub.inParams.jobIds[0] : null;
      
      return expr;
    }
  
    get $widgets_total_jobs_inParams_jobId(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $utils.isDefined($hub.inParams.jobIds) ? $hub.inParams.jobIds[0] : null;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_current_pallet', { read:  LocusRobotics_current_job_widgetComponent }) $widgets_current_pallet: LocusRobotics_current_job_widgetComponent;
      @ViewChild('$widgets_total_jobs', { read:  LocusRobotics_total_pallet_widgetComponent }) $widgets_total_jobs: LocusRobotics_total_pallet_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Pallet build`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: LocusRobotics_ShellService,
  private datasources: LocusRobotics_DatasourceService,
  private flows: LocusRobotics_FlowService,
  private reports: LocusRobotics_ReportService,
  private localization: LocusRobotics_LocalizationService,
  private operations: LocusRobotics_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.job_contents,
      this.tabs.scanned_values,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Pallet build';
    
    const $hub = this;
    const $utils = this.utils;

    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['cart_or_job_id']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_cart_or_job_value_changed();
      });
    this.formGroup
      .controls['scanned_values']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scanned_value_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_current_pallet') {
      if (!isNil(this.$widgets_current_pallet) && !this.widgets.current_pallet.hidden) {
        this.$widgets_current_pallet.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_jobs') {
      if (!isNil(this.$widgets_total_jobs) && !this.widgets.total_jobs.hidden) {
        this.$widgets_total_jobs.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_job_contents') {
      if (!isNil(this.$tabs_job_contents) && !this.tabs.job_contents.hidden) {
        this.$tabs_job_contents.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_scanned_values') {
      if (!isNil(this.$tabs_scanned_values) && !this.tabs.scanned_values.hidden) {
        this.$tabs_scanned_values.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: LocusRobotics_pallet_build_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  
  $hub.vars.displayValues = [];
  $hub.vars.expectedValues = [];
  $hub.filters.job_selector.hidden = true;
  $hub.filters.cart_or_job_id.control.value = ''
  $hub.filters.pallet_location.control.value = ''
  $hub.tabs.scanned_values.title = 'Scanned values'
  $hub.filters.cart_or_job_id.control.readOnly = false;
  $hub.filters.cart_or_job_id.control.focus();
  
  
  if ($utils.isDefined($hub.inParams.jobIds)) {
      let getLocation = (await $flows.LocusRobotics.get_serial_numbers_by_jobId({ jobId: $hub.inParams.jobIds[0] })).stagingLocation
      $hub.filters.pallet_location.control.value = getLocation
      $hub.filters.cart_or_job_id.control.value = $hub.inParams.jobIds
      $hub.filters.scanned_values.control.focus();
      $hub.on_cart_or_job_value_changed();
  }
  
  }
  on_scanned_value_changed(event = null) {
    return this.on_scanned_value_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scanned_value_changedInternal(
    $hub: LocusRobotics_pallet_build_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  let catchValue = String($hub.filters.scanned_values.control.value).trim()
  let parsedBarcode = (await $flows.BarcodeParsing.parse_gs1_barcode_flow({ inputScan: catchValue })).extractedValues
  let serialCode = (await $flows.BarcodeParsing.get_serial_number_code_by_parsedValues({ parsedValues: parsedBarcode }))
  
  if ($utils.isDefined(serialCode.reasons)) {
      catchValue = catchValue
  } else {
      catchValue = serialCode.serialNumberCode
  }
  
  $hub.filters.scanned_values.control.value = ''
  $hub.vars.displayValues.push({ Value: catchValue, Note: null })
  $hub.filters.scanned_values.control.focus();
  let cartonValues: {
      matchedSerials?: string[],
      misMatchedSerials?: string[]
  } = {};
  
  $hub.vars.scannedValuesCounter++;
  
  if ($hub.tabs.scanned_values.active) {
      $hub.refresh();
  }
  
  if ($hub.vars.scannedValuesCounter > 0) {
      $hub.tabs.scanned_values.title = `Scanned values (${$hub.vars.scannedValuesCounter})`
  }
  let validValue: string;
  let availableValues = $hub.vars.expectedValues;
  let catchValues = $hub.vars.displayValues.map(displayValue => displayValue.Value);
  let allValuesFound = true;
  
  cartonValues = (await $flows.LocusRobotics.get_cartonized_serials_flow({ scannedValue: catchValue, jobId: $hub.inParams.jobIds[0] }))
  
  if ($utils.isDefined(cartonValues.matchedSerials) && cartonValues.matchedSerials.length > 0) {
      $hub.vars.expectedValues.push(catchValue)
      for (let value of cartonValues.matchedSerials) {
          if (availableValues.includes(value)) {
              validValue += value;
              catchValues.push(value)
              $hub.vars.displayValues.push({ Value: value, Note: null })
          }
      }
  } else {
      if (availableValues.includes(catchValue)) {
          validValue += catchValue;
      } else {
          if ($utils.isDefined(cartonValues.misMatchedSerials) && cartonValues.misMatchedSerials.length > 0) {
              await $shell.LocusRobotics.openErrorDialog(`Invalid scan`, `Scanned value ${cartonValues.misMatchedSerials.join(', ')} are not expected for job ${$hub.inParams.jobIds[0]}`)
          } else {
              await $shell.LocusRobotics.openErrorDialog(`Invalid scan`, `Scanned value ${catchValue} is not expected for job ${$hub.inParams.jobIds[0]}`)
          }
          $hub.filters.scanned_values.control.focus();
      }
  }
  for (let value of availableValues) {
      if (!catchValues.includes(value)) {
          allValuesFound = false;
          break;
      }
  }
  $hub.vars.scannedValuesMatch = allValuesFound;
  
  if (allValuesFound) {
      await $hub.tabs.job_contents.activate();
      await $hub.refresh();
      $hub.filters.cart_or_job_id.control.focus();
  }
  
  }
  on_cart_or_job_value_changed(event = null) {
    return this.on_cart_or_job_value_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cart_or_job_value_changedInternal(
    $hub: LocusRobotics_pallet_build_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  let scannedJob = [String($hub.filters.cart_or_job_id.control.value).trim()]
  $hub.inParams.jobIds = scannedJob
  $hub.vars.displayValues = []
  $hub.vars.expectedValues = []
  $hub.vars.scannedValuesCounter = 0
  $hub.tabs.scanned_values.title = 'Scanned values'
  $hub.filters.cart_or_job_id.control.readOnly = true;
  
  
  let validJob: string
  if (scannedJob.length === 1) {
      validJob = (await $flows.LocusRobotics.get_orderJob_by_cart({ jobId_or_cartId: scannedJob[0] })).jobId
      if ($hub.inParams.jobIds[0] !== validJob) {
          $hub.inParams.jobIds = [validJob]
          $hub.refresh();
      }
  
      if ($utils.isDefined(validJob)) {
          let results = (await $flows.LocusRobotics.get_serial_numbers_by_jobId({ jobId: validJob }))
          $hub.vars.expectedValues.push(...results.serials)
          let location = results.stagingLocation
          $hub.filters.scanned_values.control.focus();
          $hub.filters.pallet_location.control.value = location
  
          if ($hub.vars.expectedValues.length > 0) {
              $hub.vars.scannedValuesMatch = false;
          } else {
              $hub.vars.scannedValuesMatch = true;
          }
      } else {
          $shell.LocusRobotics.openErrorDialog('Pallet build not available', `${scannedJob[0]} is not available for building`)
          await $hub.refresh();
  
      }
  }
  
  
  await $hub.refresh();
  
  }
  handle_reset(event = null) {
    return this.handle_resetInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async handle_resetInternal(
    $hub: LocusRobotics_pallet_build_hubComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $hub.inParams.jobIds = null
  await $hub.on_init();
  await $hub.refresh();
  
  }
  //#endregion private flows
}
