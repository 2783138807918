import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_job_prioritizationService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number, carrierId?: number, carrierServiceTypeId?: number }): 
  Promise<{ result: { Id?: number, HighPriority?: boolean, ManagedDelivery?: boolean, OutboundPriorityId?: number, RequestedDeliveryDate?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, AvailableDate?: string, DateNeeded?: string, DatePromised?: string, ExpectedDate?: string, Priority?: number, Carrier?: { Id?: number, Name?: string, ScacCode?: string, ShortName?: string, Services?: { Name?: string, Service_Type?: string, ServiceClass?: string, ServiceGroup?: string, ServiceMode?: string }[] }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string } } }[], Project?: { ContractClient?: boolean, LookupCode?: string, Name?: string } } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_job_prioritization/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number, carrierId?: number, carrierServiceTypeId?: number    }) {
      return false;
    }
}
