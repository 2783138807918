import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ds_get_tasks_ready_for_queueService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ShipmentId?: number, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ExpectedSourceLocation?: { Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } }, Warehouse?: { Name?: string }, Shipment?: { Id?: number, ContainerSize?: string, Wave?: { ReleaseDate?: string } }, Order?: { Id?: number, LookupCode?: string, RequestedDeliveryDate?: string, OrderClass?: { Name?: string } }, Lot?: { LookupCode?: string }, Project?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, ControllerType?: { Id?: number, Name?: string }, PackagingLookups?: { PackagingId?: number, Height?: number, Length?: number, UpcCode?: string, Weight?: number, Width?: number }[] }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } } }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_get_tasks_ready_for_queue/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ShipmentId?: number, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ExpectedSourceLocation?: { Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } }, Warehouse?: { Name?: string }, Shipment?: { Id?: number, ContainerSize?: string, Wave?: { ReleaseDate?: string } }, Order?: { Id?: number, LookupCode?: string, RequestedDeliveryDate?: string, OrderClass?: { Name?: string } }, Lot?: { LookupCode?: string }, Project?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, ControllerType?: { Id?: number, Name?: string }, PackagingLookups?: { PackagingId?: number, Height?: number, Length?: number, UpcCode?: string, Weight?: number, Width?: number }[] }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } } }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_get_tasks_ready_for_queue/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ShipmentId?: number, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ExpectedSourceLocation?: { Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } }, Warehouse?: { Name?: string }, Shipment?: { Id?: number, ContainerSize?: string, Wave?: { ReleaseDate?: string } }, Order?: { Id?: number, LookupCode?: string, RequestedDeliveryDate?: string, OrderClass?: { Name?: string } }, Lot?: { LookupCode?: string }, Project?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, ControllerType?: { Id?: number, Name?: string }, PackagingLookups?: { PackagingId?: number, Height?: number, Length?: number, UpcCode?: string, Weight?: number, Width?: number }[] }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, ParentLocationContainer?: { Name?: string, ParentLocationContainer?: { Name?: string } } } }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_get_tasks_ready_for_queue/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
