<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-cartonization"
                     *ngIf="!fieldsets.cartonization.hidden && !fieldsets.cartonization.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.cartonization.collapsible }">
                    <div *ngIf="!fieldsets.cartonization.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.cartonization.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.cartonization.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.cartonization.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.cartonization.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.cartonization.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.cartonization.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-shipment_id" *ngIf="!fields.shipment_id.hidden && !fields.shipment_id.removed" 
                            class="field-container standard {{fields.shipment_id.invalid ? 'invalid' : ''}} {{fields.shipment_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shipment_id.styles.style"
                            [ngClass]="fields.shipment_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shipment_id.label + (fields.shipment_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shipment_id.label}}<span *ngIf="fields.shipment_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="shipment_id"
                                matInput
                                numberBox
                                [format]="fields.shipment_id.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.shipment_id.control.readOnly || fields.shipment_id.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shipment_id.control.placeholder}}"
                                [ngStyle]="fields.shipment_id.control.styles.style"
                                [ngClass]="fields.shipment_id.control.styles.classes"
                                [matTooltip]="fields.shipment_id.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.shipment_id.invalid">
                          <ng-container *ngFor="let error of fields.shipment_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cartonize_shipment" *ngIf="!fields.cartonize_shipment.hidden && !fields.cartonize_shipment.removed" 
                            class="field-container standard {{fields.cartonize_shipment.invalid ? 'invalid' : ''}} {{fields.cartonize_shipment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.cartonize_shipment.styles.style"
                            [ngClass]="fields.cartonize_shipment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.cartonize_shipment.label + (fields.cartonize_shipment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.cartonize_shipment.label}}<span *ngIf="fields.cartonize_shipment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.cartonize_shipment.control.readOnly || fields.cartonize_shipment.control.disabled"
                                [ngStyle]="fields.cartonize_shipment.control.styles.style"
                                [ngClass]="fields.cartonize_shipment.control.styles.classes"
                                [matTooltip]="fields.cartonize_shipment.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_cartonize_shipment_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.cartonize_shipment.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.cartonize_shipment.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.cartonize_shipment.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.cartonize_shipment.invalid">
                          <ng-container *ngFor="let error of fields.cartonize_shipment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-palletize_shipment" *ngIf="!fields.palletize_shipment.hidden && !fields.palletize_shipment.removed" 
                            class="field-container standard {{fields.palletize_shipment.invalid ? 'invalid' : ''}} {{fields.palletize_shipment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.palletize_shipment.styles.style"
                            [ngClass]="fields.palletize_shipment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.palletize_shipment.label + (fields.palletize_shipment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.palletize_shipment.label}}<span *ngIf="fields.palletize_shipment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.palletize_shipment.control.readOnly || fields.palletize_shipment.control.disabled"
                                [ngStyle]="fields.palletize_shipment.control.styles.style"
                                [ngClass]="fields.palletize_shipment.control.styles.classes"
                                [matTooltip]="fields.palletize_shipment.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_palletize_shipment_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.palletize_shipment.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.palletize_shipment.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.palletize_shipment.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.palletize_shipment.invalid">
                          <ng-container *ngFor="let error of fields.palletize_shipment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-dock_appointments"
                     *ngIf="!fieldsets.dock_appointments.hidden && !fieldsets.dock_appointments.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.dock_appointments.collapsible }">
                    <div *ngIf="!fieldsets.dock_appointments.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.dock_appointments.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.dock_appointments.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.dock_appointments.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.dock_appointments.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.dock_appointments.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.dock_appointments.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-dock_appointment_id" *ngIf="!fields.dock_appointment_id.hidden && !fields.dock_appointment_id.removed" 
                            class="field-container standard {{fields.dock_appointment_id.invalid ? 'invalid' : ''}} {{fields.dock_appointment_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dock_appointment_id.styles.style"
                            [ngClass]="fields.dock_appointment_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dock_appointment_id.label + (fields.dock_appointment_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dock_appointment_id.label}}<span *ngIf="fields.dock_appointment_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="dock_appointment_id"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.dock_appointment_id.control.readOnly || fields.dock_appointment_id.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.dock_appointment_id.control.placeholder}}"
                                [ngStyle]="fields.dock_appointment_id.control.styles.style"
                                [ngClass]="fields.dock_appointment_id.control.styles.classes"
                                [matTooltip]="fields.dock_appointment_id.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.dock_appointment_id.invalid">
                          <ng-container *ngFor="let error of fields.dock_appointment_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_custom_editor" *ngIf="!fields.open_custom_editor.hidden && !fields.open_custom_editor.removed" 
                            class="field-container standard {{fields.open_custom_editor.invalid ? 'invalid' : ''}} {{fields.open_custom_editor.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.open_custom_editor.styles.style"
                            [ngClass]="fields.open_custom_editor.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_custom_editor.label + (fields.open_custom_editor.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_custom_editor.label}}<span *ngIf="fields.open_custom_editor.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.open_custom_editor.control.readOnly || fields.open_custom_editor.control.disabled"
                                [ngStyle]="fields.open_custom_editor.control.styles.style"
                                [ngClass]="fields.open_custom_editor.control.styles.classes"
                                [matTooltip]="fields.open_custom_editor.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_open_custom_editor_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.open_custom_editor.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.open_custom_editor.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.open_custom_editor.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.open_custom_editor.invalid">
                          <ng-container *ngFor="let error of fields.open_custom_editor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-inventory_counts"
                     *ngIf="!fieldsets.inventory_counts.hidden && !fieldsets.inventory_counts.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.inventory_counts.collapsible }">
                    <div *ngIf="!fieldsets.inventory_counts.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.inventory_counts.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.inventory_counts.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.inventory_counts.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.inventory_counts.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.inventory_counts.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.inventory_counts.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-inventory_count_id" *ngIf="!fields.inventory_count_id.hidden && !fields.inventory_count_id.removed" 
                            class="field-container standard {{fields.inventory_count_id.invalid ? 'invalid' : ''}} {{fields.inventory_count_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.inventory_count_id.styles.style"
                            [ngClass]="fields.inventory_count_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.inventory_count_id.label + (fields.inventory_count_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.inventory_count_id.label}}<span *ngIf="fields.inventory_count_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="inventory_count_id"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.inventory_count_id.control.readOnly || fields.inventory_count_id.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.inventory_count_id.control.placeholder}}"
                                [ngStyle]="fields.inventory_count_id.control.styles.style"
                                [ngClass]="fields.inventory_count_id.control.styles.classes"
                                [matTooltip]="fields.inventory_count_id.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.inventory_count_id.invalid">
                          <ng-container *ngFor="let error of fields.inventory_count_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_custom_inventory_count_editor" *ngIf="!fields.open_custom_inventory_count_editor.hidden && !fields.open_custom_inventory_count_editor.removed" 
                            class="field-container standard {{fields.open_custom_inventory_count_editor.invalid ? 'invalid' : ''}} {{fields.open_custom_inventory_count_editor.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.open_custom_inventory_count_editor.styles.style"
                            [ngClass]="fields.open_custom_inventory_count_editor.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_custom_inventory_count_editor.label + (fields.open_custom_inventory_count_editor.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_custom_inventory_count_editor.label}}<span *ngIf="fields.open_custom_inventory_count_editor.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.open_custom_inventory_count_editor.control.readOnly || fields.open_custom_inventory_count_editor.control.disabled"
                                [ngStyle]="fields.open_custom_inventory_count_editor.control.styles.style"
                                [ngClass]="fields.open_custom_inventory_count_editor.control.styles.classes"
                                [matTooltip]="fields.open_custom_inventory_count_editor.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_open_custom_inventory_count_editor_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.open_custom_inventory_count_editor.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.open_custom_inventory_count_editor.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.open_custom_inventory_count_editor.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.open_custom_inventory_count_editor.invalid">
                          <ng-container *ngFor="let error of fields.open_custom_inventory_count_editor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-flows"
                     *ngIf="!fieldsets.flows.hidden && !fieldsets.flows.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.flows.collapsible }">
                    <div *ngIf="!fieldsets.flows.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.flows.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.flows.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.flows.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.flows.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.flows.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.flows.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-flow_input_1" *ngIf="!fields.flow_input_1.hidden && !fields.flow_input_1.removed" 
                            class="field-container standard {{fields.flow_input_1.invalid ? 'invalid' : ''}} {{fields.flow_input_1.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.flow_input_1.styles.style"
                            [ngClass]="fields.flow_input_1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.flow_input_1.label + (fields.flow_input_1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.flow_input_1.label}}<span *ngIf="fields.flow_input_1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="flow_input_1"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.flow_input_1.control.readOnly || fields.flow_input_1.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.flow_input_1.control.placeholder}}"
                                [ngStyle]="fields.flow_input_1.control.styles.style"
                                [ngClass]="fields.flow_input_1.control.styles.classes"
                                [matTooltip]="fields.flow_input_1.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.flow_input_1.invalid">
                          <ng-container *ngFor="let error of fields.flow_input_1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-flow_input_2" *ngIf="!fields.flow_input_2.hidden && !fields.flow_input_2.removed" 
                            class="field-container standard {{fields.flow_input_2.invalid ? 'invalid' : ''}} {{fields.flow_input_2.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.flow_input_2.styles.style"
                            [ngClass]="fields.flow_input_2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.flow_input_2.label + (fields.flow_input_2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.flow_input_2.label}}<span *ngIf="fields.flow_input_2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="flow_input_2"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.flow_input_2.control.readOnly || fields.flow_input_2.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.flow_input_2.control.placeholder}}"
                                [ngStyle]="fields.flow_input_2.control.styles.style"
                                [ngClass]="fields.flow_input_2.control.styles.classes"
                                [matTooltip]="fields.flow_input_2.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.flow_input_2.invalid">
                          <ng-container *ngFor="let error of fields.flow_input_2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-flow_input_3" *ngIf="!fields.flow_input_3.hidden && !fields.flow_input_3.removed" 
                            class="field-container standard {{fields.flow_input_3.invalid ? 'invalid' : ''}} {{fields.flow_input_3.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.flow_input_3.styles.style"
                            [ngClass]="fields.flow_input_3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.flow_input_3.label + (fields.flow_input_3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.flow_input_3.label}}<span *ngIf="fields.flow_input_3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="flow_input_3"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.flow_input_3.control.readOnly || fields.flow_input_3.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.flow_input_3.control.placeholder}}"
                                [ngStyle]="fields.flow_input_3.control.styles.style"
                                [ngClass]="fields.flow_input_3.control.styles.classes"
                                [matTooltip]="fields.flow_input_3.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.flow_input_3.invalid">
                          <ng-container *ngFor="let error of fields.flow_input_3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-execute_flow" *ngIf="!fields.execute_flow.hidden && !fields.execute_flow.removed" 
                            class="field-container standard {{fields.execute_flow.invalid ? 'invalid' : ''}} {{fields.execute_flow.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.execute_flow.styles.style"
                            [ngClass]="fields.execute_flow.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.execute_flow.label + (fields.execute_flow.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.execute_flow.label}}<span *ngIf="fields.execute_flow.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.execute_flow.control.readOnly || fields.execute_flow.control.disabled"
                                [ngStyle]="fields.execute_flow.control.styles.style"
                                [ngClass]="fields.execute_flow.control.styles.classes"
                                [matTooltip]="fields.execute_flow.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_execute_flow_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.execute_flow.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.execute_flow.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.execute_flow.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.execute_flow.invalid">
                          <ng-container *ngFor="let error of fields.execute_flow.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>