<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-Configuration"
                     *ngIf="!fieldsets.Configuration.hidden && !fieldsets.Configuration.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.Configuration.collapsible }">
                    <div *ngIf="!fieldsets.Configuration.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.Configuration.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.Configuration.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.Configuration.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.Configuration.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.Configuration.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.Configuration.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container double {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LocusRobotics-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </LocusRobotics-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-locus_user_name" *ngIf="!fields.locus_user_name.hidden && !fields.locus_user_name.removed" 
                            class="field-container full {{fields.locus_user_name.invalid ? 'invalid' : ''}} {{fields.locus_user_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.locus_user_name.styles.style"
                            [ngClass]="fields.locus_user_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.locus_user_name.label + (fields.locus_user_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.locus_user_name.label}}<span *ngIf="fields.locus_user_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="locus_user_name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.locus_user_name.control.readOnly || fields.locus_user_name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.locus_user_name.control.placeholder}}"
                                [ngStyle]="fields.locus_user_name.control.styles.style"
                                [ngClass]="fields.locus_user_name.control.styles.classes"
                                [matTooltip]="fields.locus_user_name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.locus_user_name.invalid">
                          <ng-container *ngFor="let error of fields.locus_user_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-locus_password" *ngIf="!fields.locus_password.hidden && !fields.locus_password.removed" 
                            class="field-container full {{fields.locus_password.invalid ? 'invalid' : ''}} {{fields.locus_password.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.locus_password.styles.style"
                            [ngClass]="fields.locus_password.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.locus_password.label + (fields.locus_password.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.locus_password.label}}<span *ngIf="fields.locus_password.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="locus_password"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.locus_password.control.readOnly || fields.locus_password.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.locus_password.control.placeholder}}"
                                [ngStyle]="fields.locus_password.control.styles.style"
                                [ngClass]="fields.locus_password.control.styles.classes"
                                [matTooltip]="fields.locus_password.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.locus_password.invalid">
                          <ng-container *ngFor="let error of fields.locus_password.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-endpoint" *ngIf="!fields.endpoint.hidden && !fields.endpoint.removed" 
                            class="field-container double {{fields.endpoint.invalid ? 'invalid' : ''}} {{fields.endpoint.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.endpoint.styles.style"
                            [ngClass]="fields.endpoint.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.endpoint.label + (fields.endpoint.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.endpoint.label}}<span *ngIf="fields.endpoint.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="endpoint"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.endpoint.control.readOnly || fields.endpoint.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.endpoint.control.placeholder}}"
                                [ngStyle]="fields.endpoint.control.styles.style"
                                [ngClass]="fields.endpoint.control.styles.classes"
                                [matTooltip]="fields.endpoint.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.endpoint.invalid">
                          <ng-container *ngFor="let error of fields.endpoint.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container double {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LocusRobotics-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                        >
                        </LocusRobotics-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dim_factor" *ngIf="!fields.dim_factor.hidden && !fields.dim_factor.removed" 
                            class="field-container double {{fields.dim_factor.invalid ? 'invalid' : ''}} {{fields.dim_factor.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dim_factor.styles.style"
                            [ngClass]="fields.dim_factor.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dim_factor.label + (fields.dim_factor.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dim_factor.label}}<span *ngIf="fields.dim_factor.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="dim_factor"
                                matInput
                                numberBox
                                [format]="fields.dim_factor.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.dim_factor.control.readOnly || fields.dim_factor.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.dim_factor.control.placeholder}}"
                                [ngStyle]="fields.dim_factor.control.styles.style"
                                [ngClass]="fields.dim_factor.control.styles.classes"
                                [matTooltip]="fields.dim_factor.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.dim_factor.invalid">
                          <ng-container *ngFor="let error of fields.dim_factor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bot_type" *ngIf="!fields.bot_type.hidden && !fields.bot_type.removed" 
                            class="field-container full {{fields.bot_type.invalid ? 'invalid' : ''}} {{fields.bot_type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.bot_type.styles.style"
                            [ngClass]="fields.bot_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.bot_type.label + (fields.bot_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.bot_type.label}}<span *ngIf="fields.bot_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LocusRobotics-bot_type_dd_single 
                            data-cy="selector"
                            [type]="fields.bot_type.control.type"
                            formControlName="bot_type"
                            (displayTextChange)="fields.bot_type.control.displayText=$event"
                            [placeholder]="fields.bot_type.control.placeholder"
                            [styles]="fields.bot_type.control.styles"
                            [tooltip]="fields.bot_type.control.tooltip"
                        >
                        </LocusRobotics-bot_type_dd_single>
                        <ng-container *ngIf="fields.bot_type.invalid">
                          <ng-container *ngFor="let error of fields.bot_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>