import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_count_tasks_gridComponent } from './FootPrintManager.count_tasks_grid.component';
import { InventoryCounts_count_tasks_progress_widgetComponent } from './InventoryCounts.count_tasks_progress_widget.component';
import { InventoryCounts_warehouses_dd_singleComponent } from './InventoryCounts.warehouses_dd_single.component'
import { app_custom_count_types_dd_singleComponent } from './app.custom_count_types_dd_single.component'
import { InventoryCounts_material_filter_options_dd_singleComponent } from './InventoryCounts.material_filter_options_dd_single.component'

type EntityType = { 
    Id?: number, CountType?: string, Description?: string, LocationFilter?: string, LookupCode?: string, MaterialFilter?: string, ScheduleId?: string, WarehouseId?: number, Status?: { Id?: number, Name?: string }, InventoryCountTaskProperties?: { TaskId?: number, Task?: { OperationCodeId?: number, StatusId?: number } }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_count_tasks_gridComponent),
    forwardRef(() => InventoryCounts_count_tasks_progress_widgetComponent),
    forwardRef(() => InventoryCounts_warehouses_dd_singleComponent),
    forwardRef(() => app_custom_count_types_dd_singleComponent),
    forwardRef(() => InventoryCounts_material_filter_options_dd_singleComponent),
  ],
  selector: 'app-custom_inventory_count_editor',
  templateUrl: './app.custom_inventory_count_editor.component.html'
})
export class app_custom_inventory_count_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { inventoryCountId: number } = { inventoryCountId: null };
  //#region Inputs
  @Input('inventoryCountId') set $inParams_inventoryCountId(v: number) {
    this.inParams.inventoryCountId = v;
  }
  get $inParams_inventoryCountId(): number {
    return this.inParams.inventoryCountId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { selectedLocationIds?: number[], selectedMaterialIds?: number[], selectedVendorLotIds?: number[], isInitializing?: boolean, allowEdit?: boolean, warehouseId?: number, isEnabled?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    event_result: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    count_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    use_location_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_only_empty_locations: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_skip_hard_allocation_locations: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_include_empty_locations: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    use_material_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    material_filter_option: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      release_count: new ToolModel(new ButtonModel('release_count', new ButtonStyles(null, null), false, false, false, 'Release', 'icon-ic_fluent_play_circle_20_regular', null)
    , false),
      complete_count: new ToolModel(new ButtonModel('complete_count', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      duplicate_count: new ToolModel(new ButtonModel('duplicate_count', new ButtonStyles(null, null), false, false, false, 'Duplicate', 'icon-ic_fluent_copy_20_regular', null)
    , false),
      hold_count: new ToolModel(new ButtonModel('hold_count', new ButtonStyles(null, null), false, false, false, 'Hold', 'ms-Icon ms-Icon--CirclePause', null)
    , false),
      cancel_count: new ToolModel(new ButtonModel('cancel_count', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      delete_count: new ToolModel(new ButtonModel('delete_count', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, false, 'Print', 'icon-ic_fluent_print_20_regular', null)
    , false)
  };

  fields = {
    status: new FieldModel(new TextBoxModel(this.formGroup.controls['status'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    event_result: new FieldModel(new TextBoxModel(this.formGroup.controls['event_result'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Release details', false, false),
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Code', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    count_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['count_type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', false, false),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false, false),
    use_location_filter: new FieldModel(new CheckBoxModel(this.formGroup.controls['use_location_filter'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Location filter', false, false),
    select_locations: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, false, false, 'Select Locations', '', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_only_empty_locations: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_only_empty_locations'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Only empty locations', false, false),
    is_skip_hard_allocation_locations: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_skip_hard_allocation_locations'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Skip locations with allocations', false, false),
    is_include_empty_locations: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_include_empty_locations'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Include empty locations', false, false),
    use_material_filter: new FieldModel(new CheckBoxModel(this.formGroup.controls['use_material_filter'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Material filter', false, false),
    material_filter_option: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_filter_option'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Filter by', false, false),
    select_materials: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, false, false, 'Select Materials', '', null)
, new ControlContainerStyles(null, null), '', false, false),
    select_vendorlots: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, false, false, 'Select Vendor Lots', '', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Header', false, true, true, false),
  newGroup2: new FieldsetModel('Locations', false, true, false, false),
  newGroup3: new FieldsetModel('Materials', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory_count_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Count Tasks', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_count_tasks_count_tasks_grid_inParams_inventoryCountId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.inventoryCountId;
      
      return expr;
    }
  
    get $tabs_inventory_count_tasks_count_tasks_grid_inParams_allowEdit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = ($editor.entity.Status.Id === 1 || $editor.entity.Status.Id === 2) && $editor.fields.status.control.value.trim().toUpperCase() !== "RELEASING...";
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory_count_tasks', { read: FootPrintManager_count_tasks_gridComponent }) $tabs_inventory_count_tasks: FootPrintManager_count_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      count_tasks_progress: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_count_tasks_progress_inParams_inventoryCountId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.inventoryCountId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_count_tasks_progress', { read:  InventoryCounts_count_tasks_progress_widgetComponent }) $widgets_count_tasks_progress: InventoryCounts_count_tasks_progress_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    status: this.fields.status.control.valueChanges
    ,
    event_result: this.fields.event_result.control.valueChanges
    ,
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    count_type: this.fields.count_type.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    use_location_filter: this.fields.use_location_filter.control.valueChanges
    ,
    is_only_empty_locations: this.fields.is_only_empty_locations.control.valueChanges
    ,
    is_skip_hard_allocation_locations: this.fields.is_skip_hard_allocation_locations.control.valueChanges
    ,
    is_include_empty_locations: this.fields.is_include_empty_locations.control.valueChanges
    ,
    use_material_filter: this.fields.use_material_filter.control.valueChanges
    ,
    material_filter_option: this.fields.material_filter_option.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory_count_tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventoryCountId)) {
        this.$missingRequiredInParams.push('inventoryCountId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Inventory Count';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      inventoryCountId:  $editor.inParams.inventoryCountId 
    };

    const data = await this.datasources.app.custom_ds_inventory_count_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.status.control as TextBoxModel).reset($editor.entity.Status.Name);
    
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    (this.fields.count_type.control as SelectBoxModel).reset($editor.entity.CountType);
    (this.fields.description.control as TextBoxModel).reset($editor.entity.Description);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_count_tasks_progress') {
      if (!isNil(this.$widgets_count_tasks_progress) && !this.widgets.count_tasks_progress.hidden && !this.widgets.count_tasks_progress.removed) {
        this.$widgets_count_tasks_progress.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_inventory_count_tasks') {
        if (!isNil(this.$tabs_inventory_count_tasks) && !this.tabs.inventory_count_tasks.hidden && !this.tabs.inventory_count_tasks.removed) {
          this.$tabs_inventory_count_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .event_result
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lookupcode_changed();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.$formGroupFieldValidationObservables
      .count_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_count_type_change();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_description_changed();
      });
    this.$formGroupFieldValidationObservables
      .use_location_filter
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_use_location_filter_value_change();
      });
    this.$formGroupFieldValidationObservables
      .is_only_empty_locations
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_filter_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_skip_hard_allocation_locations
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_filter_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_include_empty_locations
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_filter_changed();
      });
    this.$formGroupFieldValidationObservables
      .use_material_filter
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_use_material_filter_clicked();
      });
    this.$formGroupFieldValidationObservables
      .material_filter_option
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_filter_option_changed();
      });
  }

  //#region private flows
  on_cancel_count_clicked(event = null) {
    return this.on_cancel_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_cancel_count_clicked');
  let title = 'Cancel Count';
  
  let message = `You are about to cancel count ${$editor.fields.lookupcode.control.value}. 
      Once cancelled you will not be able to make any further changes. 
      
      Are you sure you want to continue?`;
  
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
          message = '';
          // Cancel InventoryCount
          let result = (await $flows.InventoryCounts.set_inventory_count_cancelled_flow({ inventoryCountId: $editor.inParams.inventoryCountId }));
  
          if ($utils.isDefined(result.errors)) {
              message += `Failed to cancel count ${$editor.fields.lookupcode.control.value}: ${result.errors.join(' & ')}. `;
          }
          else {
              message += `Successfully cancelled count ${$editor.fields.lookupcode.control.value}. `;
          }
      }
      catch {
  
      }
      await $editor.on_toggle_flow();
      await $shell.InventoryCounts.openInfoDialog(title, message);
  
      await $editor.refresh();
  }
  
  }
  on_complete_count_clicked(event = null) {
    return this.on_complete_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_complete_count_clicked');
  let title = 'Complete Count';
  
  let message = `You are about to complete count ${$editor.fields.lookupcode.control.value}. 
      Once completed you will not be able to make any further changes. 
      
      Are you sure you want to continue?`;
  
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  if (isConfirmed) {
      // Check if there our outstanding tasks
      let outstandingTasks = (await $datasources.InventoryCounts.ds_get_inventory_count_tasks.get({ inventoryCountIds: [$editor.entity.Id], statusIds: [1, 4] })).result;
  
      if ($utils.isDefined(outstandingTasks)) {
          isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog('Cancel Outstanding Tasks', 'There are count tasks remaining to be resolved. Would you like to cancel the remaining tasks?', 'Confirm', 'Cancel'));
      }
  }
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
          message = '';
          // Complete InventoryCount
          let result = (await $flows.InventoryCounts.set_inventory_count_completed_flow({ inventoryCountId: $editor.inParams.inventoryCountId, isCancelOutstanding: true }));
  
          if ($utils.isDefined(result.errors)) {
              message += `Failed to complete count ${$editor.fields.lookupcode.control.value}: ${result.errors.join(' & ')}. `;
          }
          else {
              message += `Successfully completed count ${$editor.fields.lookupcode.control.value}. `;
          }
      }
      catch {
  
      }
      
      await $editor.on_toggle_flow();
      await $shell.InventoryCounts.openInfoDialog(title, message);
      await $editor.refresh();
  }
  
  }
  on_delete_count_clicked(event = null) {
    return this.on_delete_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_delete_count_clicked');
  let title = 'Delete Count';
  
  let message = `You are about to delete count ${$editor.fields.lookupcode.control.value}. 
      Once deleted this count and its related data will no longer be accessible. 
      
      Are you sure you want to continue?`;
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
          message = '';
          // Release InventoryCount
          let result = (await $flows.InventoryCounts.delete_inventory_count_flow({ inventoryCountId: $editor.inParams.inventoryCountId }));
  
          if ($utils.isDefined(result.errors)) {
              message += `Failed to delete count ${$editor.fields.lookupcode.control.value}: ${result.errors.join(' & ')}. `;
          }
          else {
              message += `Successfully deleted count ${$editor.fields.lookupcode.control.value}. `;
          }
      }
      catch {
  
      }
  
      await $editor.on_toggle_flow();
      await $shell.InventoryCounts.openInfoDialog(title, message);
  
      $editor.close();
  }
  
  }
  on_duplicate_count_clicked(event = null) {
    return this.on_duplicate_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_duplicate_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_duplicate_count_clicked');
  let title = 'Duplicate Count';
  
  let message = `You are about to duplicate count ${$editor.fields.lookupcode.control.value}. 
      
      Are you sure you want to continue?`;
  
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
          message = '';
          // Duplicate InventoryCount
          var result = (await $flows.InventoryCounts.create_duplicate_inventory_count_flow({ inventoryCountId: $editor.inParams.inventoryCountId }));
  
          if ($utils.isDefined(result.errors)) {
              message += `Failed to duplicate count ${$editor.fields.lookupcode.control.value}: ${result.errors.join(' & ')}. `;
          }
          else {
              message += `Successfully duplicated count ${$editor.fields.lookupcode.control.value}. `;
          }
      } catch {
  
      }
      await $editor.on_toggle_flow();
      await $shell.InventoryCounts.openInfoDialog(title, message);
  
      // Open new count
      $shell.FootPrintManager.openinventory_count_editor({ inventoryCountId: result.duplicateCountIds[0] });
  }
  
  }
  on_hold_count_clicked(event = null) {
    return this.on_hold_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_hold_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_hold_count_clicked');
  let title = 'Place Count on Hold';
  
  let message = `You are about to place count ${$editor.fields.lookupcode.control.value} on hold. 
      
      Are you sure you want to continue?`;
  
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
          message = '';
          // Hold InventoryCount
          let result = (await $flows.InventoryCounts.set_inventory_count_on_hold_flow({ inventoryCountId: $editor.inParams.inventoryCountId }));
  
          if ($utils.isDefined(result.errors)) {
              message += `Failed to place count ${$editor.fields.lookupcode.control.value} on hold: ${result.errors.join(' & ')}. `;
          }
          else {
              message += `Successfully placed count ${$editor.fields.lookupcode.control.value} on hold. `;
          }
      }
      catch {
  
      }
      await $editor.on_toggle_flow();
      await $shell.InventoryCounts.openInfoDialog(title, message);
  
      await $editor.refresh();
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_init');
  $editor.vars.isInitializing = true;
  $editor.vars.selectedLocationIds = [];
  $editor.vars.selectedMaterialIds = [];
  $editor.vars.selectedVendorLotIds = [];
  
  try {
      
  
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Initializing editor', exception.message);
  }
  
  $editor.vars.isInitializing = false;
  }
  on_location_filter_changed(event = null) {
    return this.on_location_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_filter_changedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_location_filter_changed');
  if (!$editor.vars.isInitializing) {
      const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
      await $editor.on_toggle_flow();
      // LocationFilter changed
      try {
          let locationFilter = (await $flows.InventoryCounts.get_location_filter_xml_string_flow({
              locationIds: $editor.vars.selectedLocationIds,
              onlyEmptyLocations: $editor.fields.is_only_empty_locations.control.value,
              includeEmptyLocations: $editor.fields.is_include_empty_locations.control.value,
              skipHardAllocationLocations: $editor.fields.is_skip_hard_allocation_locations.control.value,
              skipLocationsWithReleasedCountTask: false
          })).locationFilterXmlString;
  
          await $flows.Utilities.crud_update_flow({ entitySet: "InventoryCounts", id: $editor.inParams.inventoryCountId, entity: { LocationFilter: locationFilter } });
          await sleep(1000); //add a delay to let the update finish. This avoid concurrency exceptions. 
      }
      catch (error) {
          await $shell.FootPrintManager.openErrorDialog(`Error updating location filter`, error.message)
      }
      await $editor.on_toggle_flow();
  }
  
  
  }
  on_lookupcode_changed(event = null) {
    return this.on_lookupcode_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_changedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_lookupcode_changed');
  if (!$editor.vars.isInitializing) {
      // LookupCode changed
      await $flows.Utilities.crud_update_flow({ entitySet: "InventoryCounts", id: $editor.inParams.inventoryCountId, entity: { LookupCode: $editor.fields.lookupcode.control.value } });
  }
  }
  on_material_filter_changed(event = null) {
    return this.on_material_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_filter_changedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_material_filter_changed');
  if (!$editor.vars.isInitializing) {
      const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
      await $editor.on_toggle_flow();
      try {
          let materialFilter = (await $flows.InventoryCounts.get_material_filter_xml_string_flow({
              materialIds: $editor.vars.selectedMaterialIds,
              vendorLotIds: $editor.vars.selectedVendorLotIds
          })).materialFilterXmlString;
  
          await $flows.Utilities.crud_update_flow({ entitySet: "InventoryCounts", id: $editor.inParams.inventoryCountId, entity: { MaterialFilter: materialFilter } });
          await sleep(1000); //add a delay to let the update finish. This avoid concurrency exceptions. 
      }
      catch (error) {
          await $shell.FootPrintManager.openErrorDialog(`Error updating location filter`, error.message)
      }
      await $editor.on_toggle_flow();
  }
  
  }
  on_material_filter_option_changed(event = null) {
    return this.on_material_filter_option_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_filter_option_changedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_material_filter_option_changed');
  if (!$editor.vars.isInitializing) {
      if ($editor.fields.material_filter_option.control.value === "Materials") {
  
          if ($editor.vars.selectedVendorLotIds.length > 0) {
              // Remove VendorLots
              if (await $shell.InventoryCounts.openConfirmationDialog(
                  'Change Filter Option',
                  `There are currently ${$editor.vars.selectedVendorLotIds.length} vendor lots selected. Are you sure you want to remove them?`,
                  'Confirm',
                  'Cancel'
              )) {
                  $editor.vars.selectedVendorLotIds = [];
                  $editor.fields.select_vendorlots.control.label = 'Select Vendor Lots';
              }
              else {
                  $editor.fields.material_filter_option.control.value = 'VendorLots';
                  return;
              }
  
          }
          $editor.fields.select_materials.hidden = false;
          $editor.fields.select_vendorlots.hidden = true;
      }
      else if ($editor.fields.material_filter_option.control.value === "VendorLots") {
  
  
          if ($editor.vars.selectedMaterialIds.length > 0) {
              // Remove Materials
              if (await $shell.InventoryCounts.openConfirmationDialog(
                  'Change Filter Option',
                  `There are currently ${$editor.vars.selectedMaterialIds.length} materials selected. Are you sure you want to remove them?`,
                  'Confirm',
                  'Cancel'
              )) {
                  $editor.vars.selectedMaterialIds = [];
                  $editor.fields.select_materials.control.label = 'Select Materials';
              }
              else {
                  $editor.fields.material_filter_option.control.value = 'Materials';
                  return;
              }
          }
  
          $editor.fields.select_materials.hidden = true;
          $editor.fields.select_vendorlots.hidden = false;
      }
  }
  }
  on_release_count_clicked(event = null) {
    return this.on_release_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_count_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_release_count_clicked');
  let title = 'Release Count';
  
  let message = `You are about to start count ${$editor.fields.lookupcode.control.value}. 
      Once started you will not be able to make any further changes. 
      
      Are you sure you want to continue?`;
  
  
  let isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel'));
  
  if (isConfirmed) {
      try {
          await $editor.on_toggle_flow();
  
  
          message = '';
          // Release InventoryCount
          let result1 = (await $flows.InventoryCounts.set_inventory_count_released_flow({ inventoryCountId: $editor.inParams.inventoryCountId }));
          if ($editor.fields.count_type.control.value === 'Arcos' && !$utils.isDefined($editor.entity.InventoryCountTaskProperties)) {
              var result2 = (await $flows.InventoryCounts.schedule_inventory_count_now({ inventoryCountId: $editor.inParams.inventoryCountId }));
          }
  
  
          if ($utils.isDefined(result1.errors) || $utils.isDefined(result2?.reasons)) {
              message += `Failed to release count ${$editor.fields.lookupcode.control.value}: ${result1.errors.join(' & ')} ${result2?.reasons?.join(' & ')}. `;
          }
          else {
              message += `Successfully released count ${$editor.fields.lookupcode.control.value}. `;
          }
  
          await $shell.InventoryCounts.openInfoDialog(title, message);
  
          await $editor.refresh();
          monitorReleaseEvent();
      }
      catch {
      //releasing count catch
  }
  await $editor.on_toggle_flow();
  }
  
  
  /*****************************
   * FUNCTIONS
  ******************************/
  async function monitorReleaseEvent() {
      let checkIntervalSeconds = 10;
      let checkMaxDurationSeconds = 300;
      let index = 0;
      let isFinished = false;
  
      while (index * (checkIntervalSeconds * 1000) < (checkMaxDurationSeconds * 1000) && !isFinished) {
          await new Promise(resolve => setTimeout(resolve, checkIntervalSeconds * 1000));
  
          let eventDetails = (await $datasources.InventoryCounts.ds_get_released_footprint_event_by_inventoryCountId.get({ inventoryCountIds: [$editor.entity.Id] })).result[0];
  
          if ($utils.isDefined(eventDetails)) {
              if (eventDetails.FootPrintEvent.FootPrintEventStatus.Id !== 1 && eventDetails.FootPrintEvent.FootPrintEventStatus.Id !== 5) {
                  isFinished = true;
                  await $editor.refresh();
              }
          }
  
          index++;
      }
  }
  }
  on_select_locations_clicked(event = null) {
    return this.on_select_locations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_locations_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_select_locations_clicked');
  // Open the location selector grid as a flyout
  let newLocationIds = (await $shell.FootPrintManager.openlocations_selector_gridDialog({ warehouseId: $editor.fields.warehouse.control.value, selectedLocationIds: $editor.vars.selectedLocationIds, allowEdit: $editor.vars.allowEdit })).selectedLocationIds;
  
  
  // Update InventoryCount
  if (newLocationIds != $editor.vars.selectedLocationIds) {
      $editor.vars.selectedLocationIds = newLocationIds;
  
      await $editor.on_location_filter_changed();
  }
  
  if ($editor.vars.selectedLocationIds.length > 0) {
      $editor.fields.select_locations.control.label = `${$editor.vars.selectedLocationIds.length} Locations Selected`;
  }
  else {
      $editor.fields.select_locations.control.label = `Select Locations`;
  }
  }
  on_select_materials_clicked(event = null) {
    return this.on_select_materials_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_materials_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_select_materials_clicked');
  // Open the materials selector grid as a flyout
  let newMaterialIds = (await $shell.opencustom_material_selector_gridDialog({ selectedMaterialIds: $editor.vars.selectedMaterialIds, allowEdit: $editor.vars.allowEdit })).selectedMaterialIds;
  
  // Update InventoryCount
  if (newMaterialIds != $editor.vars.selectedMaterialIds) {
      $editor.vars.selectedMaterialIds = newMaterialIds;
  
      await $editor.on_material_filter_changed();
  }
  
  if ($editor.vars.selectedMaterialIds.length > 0) {
      $editor.fields.select_materials.control.label = `${$editor.vars.selectedMaterialIds.length} Materials Selected`;
  
      $editor.vars.selectedVendorLotIds = [];
      $editor.fields.select_vendorlots.control.label = 'Select VendorLots';
  }
  else {
      $editor.fields.select_materials.control.label = `Select Materials`;
  }
  }
  on_select_vendorlots_clicked(event = null) {
    return this.on_select_vendorlots_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_vendorlots_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_select_vendorlots_clicked');
  // Open the vendorlots selector grid as a flyout
  let newVendorlotIds = (await $shell.FootPrintManager.openvendorlots_selector_gridDialog({ selectedVendorlotIds: $editor.vars.selectedVendorLotIds, allowEdit: $editor.vars.allowEdit })).selectedVendorlotIds;
  
  // Update InventoryCount
  if (newVendorlotIds != $editor.vars.selectedVendorLotIds) {
      $editor.vars.selectedVendorLotIds = newVendorlotIds;
  
      await $editor.on_material_filter_changed();
  }
  
  if ($editor.vars.selectedVendorLotIds.length > 0) {
      $editor.fields.select_vendorlots.control.label = `${$editor.vars.selectedVendorLotIds.length} VendorLots Selected`;
  
      $editor.vars.selectedMaterialIds = [];
      $editor.fields.select_materials.control.label = 'Select Materials';
  }
  else {
      $editor.fields.select_vendorlots.control.label = `Select VendorLots`;
  }
  }
  on_use_location_filter_value_change(event = null) {
    return this.on_use_location_filter_value_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_use_location_filter_value_changeInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_use_location_filter_value_change');
  if (!$editor.vars.isInitializing) {
      if ($utils.isDefined($editor.fields.use_location_filter.control.value) && $editor.fields.use_location_filter.control.value) {
          $editor.fields.is_include_empty_locations.hidden = false;
          $editor.fields.is_skip_hard_allocation_locations.hidden = false;
          $editor.fields.is_only_empty_locations.hidden = false;
          $editor.fields.select_locations.hidden = false;
      }
      else {
          let isConfirmed = true;
  
          if ($editor.vars.selectedLocationIds.length > 0) {
              isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog('Remove Location Filter', `There are currently ${$editor.vars.selectedLocationIds.length} locations selected. Are you sure you want to remove them?`, 'Confirm', 'Cancel'));
          }
  
          if (isConfirmed) {
              $editor.fields.is_include_empty_locations.control.value = false;
              $editor.fields.is_skip_hard_allocation_locations.control.value = false;
              $editor.fields.is_only_empty_locations.control.value = false;
              $editor.vars.selectedLocationIds = [];
  
              if ($utils.isDefined($editor.fields.warehouse.control.value)) {
                  $editor.fields.select_locations.control.label = 'Select Locations';
              }
              else {
                  $editor.fields.select_locations.control.label = 'Please Select a Warehouse';
              }
  
              $editor.fields.is_include_empty_locations.hidden = true;
              $editor.fields.is_skip_hard_allocation_locations.hidden = true;
              $editor.fields.is_only_empty_locations.hidden = true;
              $editor.fields.select_locations.hidden = true;
  
              await $editor.on_location_filter_changed();
          }
          else {
              $editor.fields.use_location_filter.control.value = true;
          }
      }
  }
  }
  on_use_material_filter_clicked(event = null) {
    return this.on_use_material_filter_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_use_material_filter_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_use_material_filter_clicked');
  // Set field visibility
  if ($utils.isDefined($editor.fields.use_material_filter.control.value) && $editor.fields.use_material_filter.control.value) {
      $editor.fields.material_filter_option.hidden = false;
  
      // If Materials are selected
      if ($editor.vars.selectedMaterialIds.length > 0) {
          $editor.fields.select_materials.hidden = false;
          $editor.fields.select_vendorlots.hidden = true;
  
      }
      // If VendorLots are selected
      else if ($editor.vars.selectedVendorLotIds.length > 0) {
          $editor.fields.select_materials.hidden = true;
          $editor.fields.select_vendorlots.hidden = false;
      }
      else { // Default to Materials
          $editor.fields.select_materials.hidden = false;
          $editor.fields.select_vendorlots.hidden = true;
      }
  }
  else {
      let isConfirmed = true;
  
      if ($editor.vars.selectedMaterialIds.length > 0) {
          isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog('Remove Material Filter', `There are currently ${$editor.vars.selectedMaterialIds.length} materials selected. Are you sure you want to remove them?`, 'Confirm', 'Cancel'));
      }
      else if ($editor.vars.selectedVendorLotIds.length > 0) {
          isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog('Remove Material Filter', `There are currently ${$editor.vars.selectedVendorLotIds.length} vendor lots selected. Are you sure you want to remove them?`, 'Confirm', 'Cancel'));
      }
  
      if (isConfirmed) {
          $editor.fields.select_materials.control.label = 'Select Materials';
          $editor.fields.select_vendorlots.control.label = 'Select VendorLots';
          $editor.fields.material_filter_option.control.value = 'Materials'
          $editor.vars.selectedMaterialIds = [];
          $editor.vars.selectedVendorLotIds = [];
  
          $editor.fields.select_materials.hidden = true;
          $editor.fields.select_vendorlots.hidden = true;
          $editor.fields.material_filter_option.hidden = true;
  
          await $editor.on_material_filter_changed();
      }
      else {
          $editor.fields.use_material_filter.control.value = true;
      }
  } 
  
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_warehouse_change');
  if (!$editor.vars.isInitializing) {
      // Warehouse changed
      let isConfirmed = true;
      await $editor.on_toggle_flow();
  
      if ($editor.vars.selectedLocationIds.length > 0) {
  
          if ($editor.vars.selectedLocationIds.length > 0) {
              isConfirmed = (await $shell.InventoryCounts.openConfirmationDialog('Remove Location Filter', `There are currently ${$editor.vars.selectedLocationIds.length} locations selected. Are you sure you want to remove them?`, 'Confirm', 'Cancel'));
          }
      }
  
      if (isConfirmed) {
          // Reset Selected Locations
          $editor.vars.selectedLocationIds = [];
  
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              $editor.fields.select_locations.control.label = 'Please Select a Warehouse';
              $editor.fields.select_locations.control.styles.setSecondaryClass();
          }
          else {
              $editor.fields.select_locations.control.label = 'Select Locations';
              $editor.fields.select_locations.control.styles.setPrimaryClass();
              $editor.vars.warehouseId = $editor.fields.warehouse.control.value;
  
              if ($utils.isDefined($editor.fields.warehouse.control.value)) {
                  await $flows.Utilities.crud_update_flow({ entitySet: "InventoryCounts", id: $editor.inParams.inventoryCountId, entity: { WarehouseId: $editor.fields.warehouse.control.value } });
              }
          }
  
      }
      else {
          $editor.fields.warehouse.control.value = $editor.vars.warehouseId;
      }
  
      await $editor.on_toggle_flow();
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_data_loaded');
  $editor.vars.isEnabled = true;
  $editor.vars.allowEdit = true;
  $editor.vars.selectedLocationIds = [];
  $editor.vars.selectedMaterialIds = [];
  $editor.vars.selectedVendorLotIds = [];
  
  await $editor.on_toggle_flow();
  
  $editor.vars.isInitializing = true;
  
  try {
      // Set title
      $editor.title = `Inventory Count: ${$editor.entity.LookupCode}`;
  
      let statusData = (await $flows.InventoryCounts.get_inventory_count_status_flow({
          inventoryCountIds: [$editor.entity.Id], 
          // optimalInputs: [{
          //     inventoryCountId: $editor.entity.Id,
          //     tasks: $editor.entity.InventoryCountTaskProperties.map(p => ({ operationCodeId: p.Task.OperationCodeId, statusId: p.Task.StatusId })),
          //     statusId: $editor.entity.Status.Id,
          //     statusName: $editor.entity.Status.Name
          // }]
      })).inventoryCounts[0];
  
      // Get event details 
      let eventDetails = (await $datasources.InventoryCounts.ds_get_released_footprint_event_by_inventoryCountId.get({ inventoryCountIds: [$editor.entity.Id] })).result;
  
      // Headers
      $editor.fields.status.control.value = statusData.descriptiveStatusName;
  
      // Event Result
      if ($utils.isDefined(eventDetails[0]?.FootPrintEvent)) {
          // If Failed, Aborted - show ErrorMessage
          if (eventDetails[0]?.FootPrintEvent?.FootPrintEventStatus?.Id === 3 || eventDetails[0]?.FootPrintEvent?.FootPrintEventStatus?.Id === 4) {
              $editor.fields.event_result.hidden = false;
              $editor.fields.event_result.control.value = eventDetails[0].FootPrintEvent.ErrorMessage;
          }
          // if Consumed, show Notes
          else if (eventDetails[0]?.FootPrintEvent?.FootPrintEventStatus?.Id === 2) {
              $editor.fields.event_result.hidden = true;
              $editor.fields.event_result.control.value = eventDetails[0].FootPrintEvent.Notes;
          }
          // Otherwise, hide field
          else {
              $editor.fields.event_result.hidden = true;
          }
      }
      else {
          $editor.fields.event_result.hidden = true;
      }
  
      // Lock down fields for edit  
      $editor.vars.allowEdit = statusData.actionValidations.allowEdit;
      if (!$editor.vars.allowEdit) {
  
          if (statusData.descriptiveStatusName.indexOf('...') >= 0) {
              $editor.widgets.count_tasks_progress.hidden = true;
          }
          else {
              $editor.widgets.count_tasks_progress.hidden = false;
          }
  
      }
      else {
          // Hide Count Tasks grid
          $editor.widgets.count_tasks_progress.hidden = true;
  
      }
  
      /*** Buttons ***/
      // Check if all can Release
      if ($utils.isDefined(statusData.actionValidations.failReleaseReasons)) {
          $editor.toolbar.release_count.hidden = true;
      }
      else {
          $editor.toolbar.release_count.hidden = false;
      }
  
      // Check if all can Hold
      if ($utils.isDefined(statusData.actionValidations.failHoldReasons)) {
          $editor.toolbar.hold_count.hidden = true;
      }
      else {
          $editor.toolbar.hold_count.hidden = false;
      }
  
      // Check if all can Duplicate
      if ($utils.isDefined(statusData.actionValidations.failDuplicateReasons)) {
          $editor.toolbar.duplicate_count.hidden = true;
      }
      else {
          $editor.toolbar.duplicate_count.hidden = false;
      }
  
      // Check if all can Complete
      if ($utils.isDefined(statusData.actionValidations.failCompleteReasons)) {
          $editor.toolbar.complete_count.hidden = true;
      }
      else {
          $editor.toolbar.complete_count.hidden = false;
      }
  
      // Check if all can Cancel
      if ($utils.isDefined(statusData.actionValidations.failCancelReasons)) {
          $editor.toolbar.cancel_count.hidden = true;
      }
      else {
          $editor.toolbar.cancel_count.hidden = false;
      }
  
      // Check if all can Delete
      if ($utils.isDefined(statusData.actionValidations.failDeleteReasons)) {
          $editor.toolbar.delete_count.hidden = true;
      }
      else {
          $editor.toolbar.delete_count.hidden = false;
      }
  
      // Load fields
      $editor.vars.warehouseId = $editor.entity.WarehouseId;
  
      // LocationsFilter
      let locationsFilterXmlString = $editor.entity.LocationFilter;
      let locationsFilterXmlDoc = new DOMParser().parseFromString(locationsFilterXmlString, 'text/xml');
  
      $editor.fields.is_include_empty_locations.control.value = (locationsFilterXmlDoc.getElementsByTagName('IncludeEmptyLocation')[0]?.childNodes[0]?.nodeValue?.toUpperCase() === "TRUE");
      $editor.fields.is_only_empty_locations.control.value = (locationsFilterXmlDoc.getElementsByTagName('EmptyLocationOnly')[0]?.childNodes[0]?.nodeValue?.toUpperCase() === "TRUE");
      $editor.fields.is_skip_hard_allocation_locations.control.value = (locationsFilterXmlDoc.getElementsByTagName('SkipHardAllocatedLocation')[0]?.childNodes[0]?.nodeValue?.toUpperCase() === "TRUE");
  
      let index = 0;
      while (locationsFilterXmlDoc.getElementsByTagName('int')[index] != null) {
          $editor.vars.selectedLocationIds.push(Number(locationsFilterXmlDoc.getElementsByTagName('int')[index].childNodes[0].nodeValue));
  
          index++;
      }
  
      if ($editor.vars.selectedLocationIds.length > 0) {
          $editor.fields.select_locations.control.label = `${$editor.vars.selectedLocationIds.length} Locations Selected`
      }
  
      if ($editor.vars.selectedLocationIds.length > 0 || $editor.fields.is_include_empty_locations.control.value ||
          $editor.fields.is_only_empty_locations.control.value || $editor.fields.is_skip_hard_allocation_locations.control.value) {
  
          // Show LocationFilter fields
          $editor.fields.use_location_filter.control.value = true;
          $editor.fields.is_include_empty_locations.hidden = false;
          $editor.fields.is_skip_hard_allocation_locations.hidden = false;
          $editor.fields.is_only_empty_locations.hidden = false;
          $editor.fields.select_locations.hidden = false;
      }
      else {
          // Hide LocationFilter fields
          $editor.fields.use_location_filter.control.value = false;
          $editor.fields.is_include_empty_locations.hidden = true;
          $editor.fields.is_skip_hard_allocation_locations.hidden = true;
          $editor.fields.is_only_empty_locations.hidden = true;
          $editor.fields.select_locations.hidden = true;
      }
  
      // MaterialsFilter
      let materialsFilterXmlString = $editor.entity.MaterialFilter;
      let materialsFilterXmlDoc = new DOMParser().parseFromString(materialsFilterXmlString, 'text/xml');
  
      $editor.vars.selectedMaterialIds = [];
      $editor.vars.selectedVendorLotIds = [];
      index = 0;
      while (materialsFilterXmlDoc.getElementsByTagName('int')[index] != null) {
          if (materialsFilterXmlDoc.getElementsByTagName('int')[index].parentElement.tagName === "MaterialIds") {
              $editor.vars.selectedMaterialIds.push(Number(materialsFilterXmlDoc.getElementsByTagName('int')[index].childNodes[0].nodeValue));
          }
          else if (materialsFilterXmlDoc.getElementsByTagName('int')[index].parentElement.tagName === "VendorLotIds") {
              $editor.vars.selectedVendorLotIds.push(Number(materialsFilterXmlDoc.getElementsByTagName('int')[index].childNodes[0].nodeValue));
          }
          else {
              throw new Error(`MaterialFilter error: Specifying IDs for entity '${materialsFilterXmlDoc.getElementsByTagName('int')[index].parentElement.tagName}' is not currently supported.`)
          }
  
          index++;
      }
  
      if ($editor.vars.selectedMaterialIds.length > 0) {
          $editor.fields.select_materials.control.label = `${$editor.vars.selectedMaterialIds.length} Materials Selected`;
          $editor.fields.use_material_filter.control.value = true;
          $editor.fields.select_materials.hidden = false;
          $editor.fields.select_vendorlots.hidden = true;
          $editor.fields.material_filter_option.hidden = false;
          $editor.fields.material_filter_option.control.value = 'Materials';
      }
      else if ($editor.vars.selectedVendorLotIds.length > 0) {
          $editor.fields.select_vendorlots.control.label = `${$editor.vars.selectedVendorLotIds.length} VendorLots Selected`;
          $editor.fields.use_material_filter.control.value = true;
          $editor.fields.select_materials.hidden = true;
          $editor.fields.select_vendorlots.hidden = false;
          $editor.fields.material_filter_option.hidden = false;
          $editor.fields.material_filter_option.control.value = 'VendorLots';
      }
      else {
          $editor.fields.use_material_filter.control.value = false;
          $editor.fields.select_materials.hidden = true;
          $editor.fields.select_vendorlots.hidden = true;
          $editor.fields.material_filter_option.hidden = true;
          $editor.fields.material_filter_option.control.value = 'Materials';
      }
  
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Initializing editor', exception.message);
  }
  
  await $editor.on_toggle_flow();
  $editor.vars.isInitializing = false;
  }
  on_description_changed(event = null) {
    return this.on_description_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_description_changedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_description_changed');
  if (!$editor.vars.isInitializing) {
      // Description changed
      await $flows.Utilities.crud_update_flow({ entitySet: "InventoryCounts", id: $editor.inParams.inventoryCountId, entity: { Description: $editor.fields.description.control.value } });
  }
  }
  on_count_type_change(event = null) {
    return this.on_count_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_count_type_changeInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_count_type_change');
  if (!$editor.vars.isInitializing) {
      // Update UDF
      await $flows.Utilities.crud_update_flow({
          entitySet: 'InventoryCounts',
          id: $editor.entity.Id,
          entity: {
              CountType: $utils.isDefined($editor.fields.count_type.control.value) ? $editor.fields.count_type.control.value : null
          }
      });
  }
  }
  on_toggle_flow(event = null) {
    return this.on_toggle_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_toggle_flowInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_toggle_flow');
  $editor.vars.isInitializing = true;
  
  $editor.fields.is_include_empty_locations.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.is_only_empty_locations.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.is_skip_hard_allocation_locations.control.readOnly =($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.use_location_filter.control.readOnly =($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.use_material_filter.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.warehouse.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.material_filter_option.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  $editor.fields.select_locations.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.release_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.delete_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.duplicate_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.cancel_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.hold_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.toolbar.complete_count.control.readOnly = ($editor.vars.isEnabled || !$utils.isDefined($editor.fields.warehouse.control.value))
  $editor.fields.description.control.readOnly = $editor.vars.isEnabled;
  $editor.fields.select_materials.control.readOnly = $editor.vars.isEnabled;
  $editor.fields.select_vendorlots.control.readOnly = $editor.vars.isEnabled;
  
  $editor.fields.count_type.control.readOnly = ($editor.vars.isEnabled || !$editor.vars.allowEdit);
  
  //change isEnabled toggle.
  $editor.vars.isEnabled = !$editor.vars.isEnabled;
  
  $editor.vars.isInitializing = false;
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: app_custom_inventory_count_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_count_editor.on_print_clicked');
  
  
  await $shell.InventoryCounts.opencycle_count_reportDialog({ cycle_count_id: $editor.inParams.inventoryCountId });
  }
  //#endregion private flows
  
}
