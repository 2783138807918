import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { BarcodeParsing_OperationService } from './BarcodeParsing.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';


@Injectable({ providedIn: 'root' })
export class LocusRobotics_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.InventoryCounts = this.injector.get(InventoryCounts_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
  }

    public BarcodeParsing: BarcodeParsing_OperationService;
    public Utilities: Utilities_OperationService;
    public InventoryCounts: InventoryCounts_OperationService;
    public PrintNode: PrintNode_OperationService;
  public LocusRobotics: LocusRobotics_OperationService = this;

  public Disable_LocusRobotics_Configurations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LocusRobotics_Configurations');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LocusRobotics_Configurations');
    }
  }
  public Disable_LocusRobotics_Hub = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LocusRobotics_Hub');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LocusRobotics_Hub');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/LocusRobotics/operations/${operationName}/isauthorized`, null);
  }
}
