import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { DockAppointments_dock_appointments_library_homeComponent } from './DockAppointments.dock_appointments_library_home.component';
import { DockAppointments_areas_dd_singleComponent } from './DockAppointments.areas_dd_single.component';
import { DockAppointments_dock_appointment_statuses_singleComponent } from './DockAppointments.dock_appointment_statuses_single.component';
import { DockAppointments_dock_appointment_type_options_dd_singleComponent } from './DockAppointments.dock_appointment_type_options_dd_single.component';
import { DockAppointments_dock_appointment_types_dd_singleComponent } from './DockAppointments.dock_appointment_types_dd_single.component';
import { DockAppointments_dock_doors_dd_singleComponent } from './DockAppointments.dock_doors_dd_single.component';
import { DockAppointments_orders_dd_singleComponent } from './DockAppointments.orders_dd_single.component';
import { DockAppointments_reasoncodes_dd_singleComponent } from './DockAppointments.reasoncodes_dd_single.component';
import { DockAppointments_areas_dd_multiComponent } from './DockAppointments.areas_dd_multi.component';
import { DockAppointments_dock_appointment_statuses_multiComponent } from './DockAppointments.dock_appointment_statuses_multi.component';
import { DockAppointments_dock_appointment_type_options_dd_multiComponent } from './DockAppointments.dock_appointment_type_options_dd_multi.component';
import { DockAppointments_dock_appointment_types_dd_multiComponent } from './DockAppointments.dock_appointment_types_dd_multi.component';
import { DockAppointments_dock_doors_dd_multiComponent } from './DockAppointments.dock_doors_dd_multi.component';
import { DockAppointments_orders_dd_multiComponent } from './DockAppointments.orders_dd_multi.component';
import { DockAppointments_reasoncodes_dd_multiComponent } from './DockAppointments.reasoncodes_dd_multi.component';
import { DockAppointments_total_appt_checked_in_by_date_widgetComponent } from './DockAppointments.total_appt_checked_in_by_date_widget.component';
import { DockAppointments_total_appt_not_checked_in_by_date_widgetComponent } from './DockAppointments.total_appt_not_checked_in_by_date_widget.component';
import { DockAppointments_wait_time_widgetComponent } from './DockAppointments.wait_time_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public DockAppointments: DockAppointments_ShellService = this;

  public opendock_appointments_library_home(replaceCurrentView?: boolean) {
    this.logger.log('DockAppointments', 'dock_appointments_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'DockAppointments_dock_appointments_library_home',
        component: DockAppointments_dock_appointments_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opendock_appointments_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('DockAppointments', 'dock_appointments_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      DockAppointments_dock_appointments_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'DockAppointments_dock_appointments_library_home') {
      this.logger.log('DockAppointments', 'dock_appointments_library_home');
      const title = 'Home';
      const component = DockAppointments_dock_appointments_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_areas_dd_single') {
      const title = 'Areas Dropdown';
      const component = DockAppointments_areas_dd_singleComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_statuses_single') {
      const title = 'Dock Appointment Statuses dropdown';
      const component = DockAppointments_dock_appointment_statuses_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_type_options_dd_single') {
      const title = 'Dock Appointment Type Options';
      const component = DockAppointments_dock_appointment_type_options_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_types_dd_single') {
      const title = 'dock_appointment_types_dd';
      const component = DockAppointments_dock_appointment_types_dd_singleComponent;
      const inParams:{ option?: string } = { option: null };
      if (!isNil(params.get('option'))) {
        const paramValueString = params.get('option');
        // TODO: date
        inParams.option = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_doors_dd_single') {
      const title = 'Dock Doors dropdown';
      const component = DockAppointments_dock_doors_dd_singleComponent;
      const inParams:{ warehouseIds?: number[], appointmentType?: string, parentIds?: number[] } = { warehouseIds: [], appointmentType: null, parentIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }
      if (!isNil(params.get('parentIds'))) {
        const paramValueString = params.get('parentIds');
        inParams.parentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_orders_dd_single') {
      const title = 'Orders Dropdown';
      const component = DockAppointments_orders_dd_singleComponent;
      const inParams:{ projectId?: number, warehouseId: number, typeId: number } = { projectId: null, warehouseId: null, typeId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_reasoncodes_dd_single') {
      const title = 'Reasoncodes dropdown';
      const component = DockAppointments_reasoncodes_dd_singleComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_areas_dd_multi') {
      const title = 'Areas Dropdown';
      const component = DockAppointments_areas_dd_multiComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_statuses_multi') {
      const title = 'Dock Appointment Statuses dropdown';
      const component = DockAppointments_dock_appointment_statuses_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_type_options_dd_multi') {
      const title = 'Dock Appointment Type Options';
      const component = DockAppointments_dock_appointment_type_options_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_appointment_types_dd_multi') {
      const title = 'dock_appointment_types_dd';
      const component = DockAppointments_dock_appointment_types_dd_multiComponent;
      const inParams:{ option?: string } = { option: null };
      if (!isNil(params.get('option'))) {
        const paramValueString = params.get('option');
        // TODO: date
        inParams.option = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_dock_doors_dd_multi') {
      const title = 'Dock Doors dropdown';
      const component = DockAppointments_dock_doors_dd_multiComponent;
      const inParams:{ warehouseIds?: number[], appointmentType?: string, parentIds?: number[] } = { warehouseIds: [], appointmentType: null, parentIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }
      if (!isNil(params.get('parentIds'))) {
        const paramValueString = params.get('parentIds');
        inParams.parentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_orders_dd_multi') {
      const title = 'Orders Dropdown';
      const component = DockAppointments_orders_dd_multiComponent;
      const inParams:{ projectId?: number, warehouseId: number, typeId: number } = { projectId: null, warehouseId: null, typeId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_reasoncodes_dd_multi') {
      const title = 'Reasoncodes dropdown';
      const component = DockAppointments_reasoncodes_dd_multiComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_total_appt_checked_in_by_date_widget') {
      const title = 'Checked in today';
      const component = DockAppointments_total_appt_checked_in_by_date_widgetComponent;
      const inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], timeZoneDates: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('timeZoneDates'))) {
        const paramValueString = params.get('timeZoneDates');
        inParams.timeZoneDates = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_total_appt_not_checked_in_by_date_widget') {
      const title = 'In transit today';
      const component = DockAppointments_total_appt_not_checked_in_by_date_widgetComponent;
      const inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], timeZoneDates: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('timeZoneDates'))) {
        const paramValueString = params.get('timeZoneDates');
        inParams.timeZoneDates = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'DockAppointments_wait_time_widget') {
      this.logger.log('DockAppointments', 'wait_time_widget');
      const title = 'Wait time';
      const component = DockAppointments_wait_time_widgetComponent;
      const inParams:{ waitTime: number } = { waitTime: null };
      if (!isNil(params.get('waitTime'))) {
        const paramValueString = params.get('waitTime');
        inParams.waitTime = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
