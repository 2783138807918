<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="manage_menu" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.cart_configuration.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.cart_configuration.readOnly || tool.control.buttons.cart_configuration.disabled"
                              [ngStyle]="tool.control.buttons.cart_configuration.styles.style"
                              [ngClass]="tool.control.buttons.cart_configuration.styles.classes"
                              [matTooltip]="tool.control.buttons.cart_configuration.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_cart_configurations_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.cart_configuration.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.cart_configuration.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.cart_configuration.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.settings.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.settings.readOnly || tool.control.buttons.settings.disabled"
                              [ngStyle]="tool.control.buttons.settings.styles.style"
                              [ngClass]="tool.control.buttons.settings.styles.classes"
                              [matTooltip]="tool.control.buttons.settings.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_locus_settings_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.settings.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.settings.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.settings.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.printer_settings.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.printer_settings.readOnly || tool.control.buttons.printer_settings.disabled"
                              [ngStyle]="tool.control.buttons.printer_settings.styles.style"
                              [ngClass]="tool.control.buttons.printer_settings.styles.classes"
                              [matTooltip]="tool.control.buttons.printer_settings.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_printers_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.printer_settings.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.printer_settings.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.printer_settings.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.message_trace.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.message_trace.readOnly || tool.control.buttons.message_trace.disabled"
                              [ngStyle]="tool.control.buttons.message_trace.styles.style"
                              [ngClass]="tool.control.buttons.message_trace.styles.classes"
                              [matTooltip]="tool.control.buttons.message_trace.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="message_trace_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.message_trace.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.message_trace.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.message_trace.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.logs.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.logs.readOnly || tool.control.buttons.logs.disabled"
                              [ngStyle]="tool.control.buttons.logs.styles.style"
                              [ngClass]="tool.control.buttons.logs.styles.classes"
                              [matTooltip]="tool.control.buttons.logs.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_logs_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.logs.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.logs.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.logs.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="pallet_building" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_build_pallet_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="pull_orders" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_pull_orders_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-customer" *ngIf="!filters.customer.hidden && !filters.customer.removed" 
                                  class="field-container standard {{filters.customer.invalid ? 'invalid' : ''}} {{filters.customer.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.customer.styles.style"
                                  [ngClass]="filters.customer.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.customer.label + (filters.customer.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.customer.label}}<span *ngIf="filters.customer.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LocusRobotics-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.customer.control.type"
                                  formControlName="customer"
                                  (displayTextChange)="filters.customer.control.displayText=$event"
                                  [placeholder]="filters.customer.control.placeholder"
                                  [styles]="filters.customer.control.styles"
                                  [tooltip]="filters.customer.control.tooltip"
                                [statusId]="$fields_customer_selector_inParams_statusId"
                                [projectId]="$fields_customer_selector_inParams_projectId"
                              >
                              </LocusRobotics-owners_dd_single>
                              <ng-container *ngIf="filters.customer.invalid">
                                <ng-container *ngFor="let error of filters.customer.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}} {{filters.project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LocusRobotics-projects_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </LocusRobotics-projects_dd_multi>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LocusRobotics-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                                [warehouseId]="$fields_warehouse_selector_inParams_warehouseId"
                              >
                              </LocusRobotics-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!filtersets.newGroup2.hidden && !filtersets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup2.collapsible }">
                    <div *ngIf="!filtersets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup2.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-cart_or_job_id" *ngIf="!filters.cart_or_job_id.hidden && !filters.cart_or_job_id.removed" 
                                  class="field-container double {{filters.cart_or_job_id.invalid ? 'invalid' : ''}} {{filters.cart_or_job_id.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.cart_or_job_id.styles.style"
                                  [ngClass]="filters.cart_or_job_id.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.cart_or_job_id.label + (filters.cart_or_job_id.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.cart_or_job_id.label}}<span *ngIf="filters.cart_or_job_id.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="cart_or_job_id"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{ (filters.cart_or_job_id.control.readOnly || filters.cart_or_job_id.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.cart_or_job_id.control.placeholder}}"
                                      [ngStyle]="filters.cart_or_job_id.control.styles.style"
                                      [ngClass]="filters.cart_or_job_id.control.styles.classes"
                                      [matTooltip]="filters.cart_or_job_id.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.cart_or_job_id.invalid">
                                <ng-container *ngFor="let error of filters.cart_or_job_id.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.open_orders_widget.hidden && !widgets.open_orders_widget.removed" class="widget-container">
            <LocusRobotics-open_jobs_widget
            #$widgets_open_orders_widget 
              [projects]="$widgets_open_orders_widget_inParams_projects"
              [warehouse]="$widgets_open_orders_widget_inParams_warehouse"
            ($refreshEvent)="refresh(false, false, '$widgets_open_orders_widget')"
            >
            </LocusRobotics-open_jobs_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.jobs.hasTabToShow" class="tab" data-cy="tab-jobs">
              <h2 [className]="tabs.jobs.active? 'active': ''" (click)="tabs.jobs.activate()">{{tabs.jobs.title}}</h2>
            </div>
          </div>
        
              <ng-container
                *ngIf="tabs.jobs.active">
                
                  <LocusRobotics-jobs_detail_by_order_grid
                   *ngIf="tabs.jobs.tabs.by_job.active"
                  #$tabs_jobs_by_job
                  [warehouseId]="$tabs_jobs_by_job_jobs_detail_by_order_grid_inParams_warehouseId"
                  [projectIds]="$tabs_jobs_by_job_jobs_detail_by_order_grid_inParams_projectIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_jobs_by_job')">
                  </LocusRobotics-jobs_detail_by_order_grid>
              </ng-container>
        </div>

      </ng-container>
    </div>
  </div>
</div>