import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_create_partial_manual_allocation_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { taskId: number, task_qty: number, transactionId: string, jobId: string }): Promise<{ manualAllocationTaskId?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (isNil(inParams.task_qty)) {
      missingRequiredInParams.push('\'task_qty\'');
    }
    if (isNil(inParams.transactionId)) {
      missingRequiredInParams.push('\'transactionId\'');
    }
    if (isNil(inParams.jobId)) {
      missingRequiredInParams.push('\'jobId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/functions/create_partial_manual_allocation_tasks`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

